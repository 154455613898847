import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterData',
  standalone: true
})
export class FilterDataPipe implements PipeTransform {
  transform(data: any[], searchText: string, fieldName: string): any[] {
    if (!data || !searchText || !fieldName) {
      return data;
    }

    return data.filter(item => {
      const fieldValue = item[fieldName];
      if (typeof fieldValue === 'string') {
        return fieldValue.toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    });
  }
}
