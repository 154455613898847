const ApiAuthUrl = 'https://auth.corepulse.fr/realms/ORKESTRIA/protocol/openid-connect';
const ApiUrl = 'https://ms.camapp.dev.fortest.store/projects/';
const ApiRecording = 'http://192.168.1.86:8000/';
const ApiLiveView = 'https://ms.camapp.dev.fortest.store/projects/';

export const environment = {
    production: true,
    ApiUrl,
    ApiRecording,
    ApiLiveView,
    ApiAuthUrl
};
