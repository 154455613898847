!
/**
* Highcharts JS v12.1.2 (2024-12-21)
* @module highcharts/modules/accessibility
* @requires highcharts
*
* Accessibility module
*
* (c) 2010-2024 Highsoft AS
* Author: Oystein Moseng
*
* License: www.highcharts.com/license
*/
function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t(e._Highcharts, e._Highcharts.Templating, e._Highcharts.AST, e._Highcharts.Legend, e._Highcharts.Axis, e._Highcharts.Color, e._Highcharts.SeriesRegistry, e._Highcharts.RendererRegistry, e._Highcharts.SVGRenderer, e._Highcharts.Point, e._Highcharts.Series) : "function" == typeof define && define.amd ? define("highcharts/modules/accessibility", ["highcharts/highcharts"], function (e) {
    return t(e, e.Templating, e.AST, e.Legend, e.Axis, e.Color, e.SeriesRegistry, e.RendererRegistry, e.SVGRenderer, e.Point, e.Series);
  }) : "object" == typeof exports ? exports["highcharts/modules/accessibility"] = t(e._Highcharts, e._Highcharts.Templating, e._Highcharts.AST, e._Highcharts.Legend, e._Highcharts.Axis, e._Highcharts.Color, e._Highcharts.SeriesRegistry, e._Highcharts.RendererRegistry, e._Highcharts.SVGRenderer, e._Highcharts.Point, e._Highcharts.Series) : e.Highcharts = t(e.Highcharts, e.Highcharts.Templating, e.Highcharts.AST, e.Highcharts.Legend, e.Highcharts.Axis, e.Highcharts.Color, e.Highcharts.SeriesRegistry, e.Highcharts.RendererRegistry, e.Highcharts.SVGRenderer, e.Highcharts.Point, e.Highcharts.Series);
}("undefined" == typeof window ? this : window, (e, t, i, s, n, r, o, a, l, h, c) => (() => {
  "use strict";

  let d;
  var u,
    p,
    g,
    m,
    b = {
      660: e => {
        e.exports = i;
      },
      532: e => {
        e.exports = n;
      },
      620: e => {
        e.exports = r;
      },
      632: e => {
        e.exports = s;
      },
      260: e => {
        e.exports = h;
      },
      608: e => {
        e.exports = a;
      },
      540: e => {
        e.exports = l;
      },
      820: e => {
        e.exports = c;
      },
      512: e => {
        e.exports = o;
      },
      984: e => {
        e.exports = t;
      },
      944: t => {
        t.exports = e;
      }
    },
    f = {};
  function x(e) {
    var t = f[e];
    if (void 0 !== t) return t.exports;
    var i = f[e] = {
      exports: {}
    };
    return b[e](i, i.exports, x), i.exports;
  }
  x.n = e => {
    var t = e && e.__esModule ? () => e.default : () => e;
    return x.d(t, {
      a: t
    }), t;
  }, x.d = (e, t) => {
    for (var i in t) x.o(t, i) && !x.o(e, i) && Object.defineProperty(e, i, {
      enumerable: !0,
      get: t[i]
    });
  }, x.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
  var y = {};
  x.d(y, {
    default: () => nx
  });
  var v = x(944),
    w = x.n(v);
  let {
      doc: E,
      win: A
    } = w(),
    {
      css: T
    } = w(),
    C = A.EventTarget && new A.EventTarget() || "none";
  function M(e) {
    if ("function" == typeof A.MouseEvent) return new A.MouseEvent(e.type, e);
    if (E.createEvent) {
      let t = E.createEvent("MouseEvent");
      if (t.initMouseEvent) return t.initMouseEvent(e.type, e.bubbles, e.cancelable, e.view || A, e.detail, e.screenX, e.screenY, e.clientX, e.clientY, e.ctrlKey, e.altKey, e.shiftKey, e.metaKey, e.button, e.relatedTarget), t;
    }
    return k(e.type);
  }
  function k(e, t, i) {
    let s = t || {
      x: 0,
      y: 0
    };
    if ("function" == typeof A.MouseEvent) return new A.MouseEvent(e, {
      bubbles: !0,
      cancelable: !0,
      composed: !0,
      button: 0,
      buttons: 1,
      relatedTarget: i || C,
      view: A,
      detail: "click" === e ? 1 : 0,
      screenX: s.x,
      screenY: s.y,
      clientX: s.x,
      clientY: s.y
    });
    if (E.createEvent) {
      let t = E.createEvent("MouseEvent");
      if (t.initMouseEvent) return t.initMouseEvent(e, !0, !0, A, "click" === e ? 1 : 0, s.x, s.y, s.x, s.y, !1, !1, !1, !1, 0, null), t;
    }
    return {
      type: e
    };
  }
  let S = {
    addClass: function (e, t) {
      e.classList ? e.classList.add(t) : 0 > e.className.indexOf(t) && (e.className += " " + t);
    },
    cloneMouseEvent: M,
    cloneTouchEvent: function (e) {
      let t = e => {
        let t = [];
        for (let i = 0; i < e.length; ++i) {
          let s = e.item(i);
          s && t.push(s);
        }
        return t;
      };
      if ("function" == typeof A.TouchEvent) {
        let i = new A.TouchEvent(e.type, {
          touches: t(e.touches),
          targetTouches: t(e.targetTouches),
          changedTouches: t(e.changedTouches),
          ctrlKey: e.ctrlKey,
          shiftKey: e.shiftKey,
          altKey: e.altKey,
          metaKey: e.metaKey,
          bubbles: e.bubbles,
          cancelable: e.cancelable,
          composed: e.composed,
          detail: e.detail,
          view: e.view
        });
        return e.defaultPrevented && i.preventDefault(), i;
      }
      let i = M(e);
      return i.touches = e.touches, i.changedTouches = e.changedTouches, i.targetTouches = e.targetTouches, i;
    },
    escapeStringForHTML: function (e) {
      return e.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#x27;").replace(/\//g, "&#x2F;");
    },
    getElement: function (e) {
      return E.getElementById(e);
    },
    getFakeMouseEvent: k,
    getHeadingTagNameForElement: function (e) {
      let t = e => "h" + Math.min(6, parseInt(e.slice(1), 10) + 1),
        i = e => /^H[1-6]$/i.test(e),
        s = e => {
          let t = e;
          for (; t = t.previousSibling;) {
            let e = t.tagName || "";
            if (i(e)) return e;
          }
          return "";
        },
        n = e => {
          let r = s(e);
          if (r) return t(r);
          let o = e.parentElement;
          if (!o) return "h6";
          let a = o.tagName;
          return i(a) ? t(a) : n(o);
        };
      return n(e);
    },
    removeChildNodes: function (e) {
      for (; e.lastChild;) e.removeChild(e.lastChild);
    },
    removeClass: function (e, t) {
      e.classList ? e.classList.remove(t) : e.className = e.className.replace(RegExp(t, "g"), "");
    },
    removeElement: function (e) {
      e && e.parentNode && e.parentNode.removeChild(e);
    },
    reverseChildNodes: function (e) {
      let t = e.childNodes.length;
      for (; t--;) e.appendChild(e.childNodes[t]);
    },
    simulatedEventTarget: C,
    stripHTMLTagsFromString: function (e, t = !1) {
      return "string" == typeof e ? t ? e.replace(/<\/?[^>]+(>|$)/g, "") : e.replace(/<\/?(?!\s)[^>]+(>|$)/g, "") : e;
    },
    visuallyHideElement: function (e) {
      T(e, {
        position: "absolute",
        width: "1px",
        height: "1px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        clip: "rect(1px, 1px, 1px, 1px)",
        marginTop: "-3px",
        "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)",
        filter: "alpha(opacity=1)",
        opacity: .01
      });
    }
  };
  var P = x(984),
    D = x.n(P);
  let {
      format: N
    } = D(),
    {
      getNestedProperty: B,
      pick: I
    } = w();
  !function (e) {
    function t(e, t, i) {
      let s = (e, t) => {
          let i = e.slice(t || 0),
            s = i.indexOf("{"),
            n = i.indexOf("}");
          if (s > -1 && n > s) return {
            statement: i.substring(s + 1, n),
            begin: t + s + 1,
            end: t + n
          };
        },
        n = [],
        r,
        o,
        a = 0;
      do r = s(e, a), (o = e.substring(a, r && r.begin - 1)).length && n.push({
        value: o,
        type: "constant"
      }), r && n.push({
        value: r.statement,
        type: "statement"
      }), a = r ? r.end + 1 : a + 1; while (r);
      return n.forEach(e => {
        "statement" === e.type && (e.value = function (e, t) {
          let i, s;
          let n = e.indexOf("#each("),
            r = e.indexOf("#plural("),
            o = e.indexOf("["),
            a = e.indexOf("]");
          if (n > -1) {
            let r = e.slice(n).indexOf(")") + n,
              o = e.substring(0, n),
              a = e.substring(r + 1),
              l = e.substring(n + 6, r).split(","),
              h = Number(l[1]),
              c;
            if (s = "", i = B(l[0], t)) {
              c = (h = isNaN(h) ? i.length : h) < 0 ? i.length + h : Math.min(h, i.length);
              for (let e = 0; e < c; ++e) s += o + i[e] + a;
            }
            return s.length ? s : "";
          }
          if (r > -1) {
            var l;
            let i = e.slice(r).indexOf(")") + r,
              n = e.substring(r + 8, i).split(",");
            switch (Number(B(n[0], t))) {
              case 0:
                s = I(n[4], n[1]);
                break;
              case 1:
                s = I(n[2], n[1]);
                break;
              case 2:
                s = I(n[3], n[1]);
                break;
              default:
                s = n[1];
            }
            return s ? (l = s).trim && l.trim() || l.replace(/^\s+|\s+$/g, "") : "";
          }
          if (o > -1) {
            let s;
            let n = e.substring(0, o),
              r = Number(e.substring(o + 1, a));
            return i = B(n, t), !isNaN(r) && i && (r < 0 ? void 0 === (s = i[i.length + r]) && (s = i[0]) : void 0 === (s = i[r]) && (s = i[i.length - 1])), void 0 !== s ? s : "";
          }
          return "{" + e + "}";
        }(e.value, t));
      }), N(n.reduce((e, t) => e + t.value, ""), t, i);
    }
    function i(e, i) {
      let s = e.split("."),
        n = this.options.lang,
        r = 0;
      for (; r < s.length; ++r) n = n && n[s[r]];
      return "string" == typeof n ? t(n, i, this) : "";
    }
    e.compose = function (e) {
      let t = e.prototype;
      t.langFormat || (t.langFormat = i);
    }, e.i18nFormat = t;
  }(u || (u = {}));
  let F = u,
    {
      doc: O
    } = w(),
    {
      stripHTMLTagsFromString: R
    } = S,
    {
      defined: L,
      find: H,
      fireEvent: z
    } = w();
  function q(e) {
    if (e.points && e.points.length) {
      let t = H(e.points, e => !!e.graphic);
      return t && t.graphic && t.graphic.element;
    }
  }
  function G(e) {
    let t = q(e);
    return t && t.parentNode || e.graph && e.graph.element || e.group && e.group.element;
  }
  let K = {
      fireEventOnWrappedOrUnwrappedElement: function e(t, i) {
        let s = i.type,
          n = t.hcEvents;
        O.createEvent && (t.dispatchEvent || t.fireEvent) ? t.dispatchEvent ? t.dispatchEvent(i) : t.fireEvent(s, i) : n && n[s] ? z(t, s, i) : t.element && e(t.element, i);
      },
      getChartTitle: function (e) {
        return R(e.options.title.text || e.langFormat("accessibility.defaultChartTitle", {
          chart: e
        }), e.renderer.forExport);
      },
      getAxisDescription: function (e) {
        return e && (e.options.accessibility?.description || e.axisTitle?.textStr || e.options.id || e.categories && "categories" || e.dateTime && "Time" || "values");
      },
      getAxisRangeDescription: function (e) {
        let t = e.options || {};
        return t.accessibility && void 0 !== t.accessibility.rangeDescription ? t.accessibility.rangeDescription : e.categories ? function (e) {
          let t = e.chart;
          return e.dataMax && e.dataMin ? t.langFormat("accessibility.axis.rangeCategories", {
            chart: t,
            axis: e,
            numCategories: e.dataMax - e.dataMin + 1
          }) : "";
        }(e) : e.dateTime && (0 === e.min || 0 === e.dataMin) ? function (e) {
          let t = e.chart,
            i = {},
            s = e.dataMin || e.min || 0,
            n = e.dataMax || e.max || 0,
            r = "Seconds";
          i.Seconds = (n - s) / 1e3, i.Minutes = i.Seconds / 60, i.Hours = i.Minutes / 60, i.Days = i.Hours / 24, ["Minutes", "Hours", "Days"].forEach(function (e) {
            i[e] > 2 && (r = e);
          });
          let o = i[r].toFixed("Seconds" !== r && "Minutes" !== r ? 1 : 0);
          return t.langFormat("accessibility.axis.timeRange" + r, {
            chart: t,
            axis: e,
            range: o.replace(".0", "")
          });
        }(e) : function (e) {
          let t = e.chart,
            i = t.options,
            s = i && i.accessibility && i.accessibility.screenReaderSection.axisRangeDateFormat || "",
            n = {
              min: e.dataMin || e.min || 0,
              max: e.dataMax || e.max || 0
            },
            r = function (i) {
              return e.dateTime ? t.time.dateFormat(s, n[i]) : n[i].toString();
            };
          return t.langFormat("accessibility.axis.rangeFromTo", {
            chart: t,
            axis: e,
            rangeFrom: r("min"),
            rangeTo: r("max")
          });
        }(e);
      },
      getPointFromXY: function (e, t, i) {
        let s = e.length,
          n;
        for (; s--;) if (n = H(e[s].points || [], function (e) {
          return e.x === t && e.y === i;
        })) return n;
      },
      getSeriesFirstPointElement: q,
      getSeriesFromName: function (e, t) {
        return t ? (e.series || []).filter(function (e) {
          return e.name === t;
        }) : e.series;
      },
      getSeriesA11yElement: G,
      unhideChartElementFromAT: function e(t, i) {
        i.setAttribute("aria-hidden", !1), i !== t.renderTo && i.parentNode && i.parentNode !== O.body && (Array.prototype.forEach.call(i.parentNode.childNodes, function (e) {
          e.hasAttribute("aria-hidden") || e.setAttribute("aria-hidden", !0);
        }), e(t, i.parentNode));
      },
      hideSeriesFromAT: function (e) {
        let t = G(e);
        t && t.setAttribute("aria-hidden", !0);
      },
      scrollAxisToPoint: function (e) {
        let t = e.series.xAxis,
          i = e.series.yAxis,
          s = t && t.scrollbar ? t : i,
          n = s && s.scrollbar;
        if (n && L(n.to) && L(n.from)) {
          let t = n.to - n.from,
            i = function (e, t) {
              if (!L(e.dataMin) || !L(e.dataMax)) return 0;
              let i = e.toPixels(e.dataMin),
                s = e.toPixels(e.dataMax),
                n = "xAxis" === e.coll ? "x" : "y";
              return (e.toPixels(t[n] || 0) - i) / (s - i);
            }(s, e);
          n.updatePosition(i - t / 2, i + t / 2), z(n, "changed", {
            from: n.from,
            to: n.to,
            trigger: "scrollbar",
            DOMEvent: null
          });
        }
      }
    },
    {
      doc: U
    } = w(),
    {
      removeElement: V
    } = S,
    W = class {
      constructor() {
        this.elements = [];
      }
      createElement() {
        let e = U.createElement.apply(U, arguments);
        return this.elements.push(e), e;
      }
      removeElement(e) {
        V(e), this.elements.splice(this.elements.indexOf(e), 1);
      }
      destroyCreatedElements() {
        this.elements.forEach(function (e) {
          V(e);
        }), this.elements = [];
      }
    },
    {
      addEvent: X
    } = w(),
    Y = class {
      constructor() {
        this.eventRemovers = [];
      }
      addEvent() {
        let e = X.apply(w(), arguments);
        return this.eventRemovers.push({
          element: arguments[0],
          remover: e
        }), e;
      }
      removeEvent(e) {
        let t = this.eventRemovers.map(e => e.remover).indexOf(e);
        this.eventRemovers[t].remover(), this.eventRemovers.splice(t, 1);
      }
      removeAddedEvents() {
        this.eventRemovers.map(e => e.remover).forEach(e => e()), this.eventRemovers = [];
      }
    },
    {
      fireEventOnWrappedOrUnwrappedElement: j
    } = K,
    {
      getFakeMouseEvent: _
    } = S,
    Z = class {
      destroy() {}
      getKeyboardNavigation() {
        return [];
      }
      init() {}
      onChartRender() {}
      onChartUpdate() {}
      initBase(e, t) {
        this.chart = e, this.eventProvider = new Y(), this.domElementProvider = new W(), this.proxyProvider = t, this.keyCodes = {
          left: 37,
          right: 39,
          up: 38,
          down: 40,
          enter: 13,
          space: 32,
          esc: 27,
          tab: 9,
          pageUp: 33,
          pageDown: 34,
          end: 35,
          home: 36
        };
      }
      addEvent(e, t, i, s) {
        return this.eventProvider.addEvent(e, t, i, s);
      }
      createElement(e, t) {
        return this.domElementProvider.createElement(e, t);
      }
      fakeClickEvent(e) {
        j(e, _("click"));
      }
      destroyBase() {
        this.domElementProvider.destroyCreatedElements(), this.eventProvider.removeAddedEvents();
      }
    },
    {
      find: $
    } = w(),
    Q = class {
      constructor(e, t) {
        this.chart = e, this.keyCodeMap = t.keyCodeMap || [], this.validate = t.validate, this.init = t.init, this.terminate = t.terminate, this.response = {
          success: 1,
          prev: 2,
          next: 3,
          noHandler: 4,
          fail: 5
        };
      }
      run(e) {
        let t = e.which || e.keyCode,
          i = this.response.noHandler,
          s = $(this.keyCodeMap, function (e) {
            return e[0].indexOf(t) > -1;
          });
        return s ? i = s[1].call(this, t, e) : 9 === t && (i = this.response[e.shiftKey ? "prev" : "next"]), i;
      }
    },
    {
      unhideChartElementFromAT: J,
      getChartTitle: ee
    } = K,
    {
      doc: et
    } = w(),
    {
      stripHTMLTagsFromString: ei
    } = S,
    es = class extends Z {
      onChartUpdate() {
        this.handleSVGTitleElement(), this.setSVGContainerLabel(), this.setGraphicContainerAttrs(), this.setRenderToAttrs(), this.makeCreditsAccessible();
      }
      handleSVGTitleElement() {
        let e = this.chart,
          t = "highcharts-title-" + e.index,
          i = ei(e.langFormat("accessibility.svgContainerTitle", {
            chartTitle: ee(e)
          }));
        if (i.length) {
          let s = this.svgTitleElement = this.svgTitleElement || et.createElementNS("http://www.w3.org/2000/svg", "title");
          s.textContent = i, s.id = t, e.renderTo.insertBefore(s, e.renderTo.firstChild);
        }
      }
      setSVGContainerLabel() {
        let e = this.chart,
          t = e.langFormat("accessibility.svgContainerLabel", {
            chartTitle: ee(e)
          });
        e.renderer.box && t.length && e.renderer.box.setAttribute("aria-label", t);
      }
      setGraphicContainerAttrs() {
        let e = this.chart,
          t = e.langFormat("accessibility.graphicContainerLabel", {
            chartTitle: ee(e)
          });
        t.length && e.container.setAttribute("aria-label", t);
      }
      setRenderToAttrs() {
        let e = this.chart,
          t = "disabled" !== e.options.accessibility.landmarkVerbosity,
          i = e.langFormat("accessibility.chartContainerLabel", {
            title: ee(e),
            chart: e
          });
        i && (e.renderTo.setAttribute("role", t ? "region" : "group"), e.renderTo.setAttribute("aria-label", i));
      }
      makeCreditsAccessible() {
        let e = this.chart,
          t = e.credits;
        t && (t.textStr && t.element.setAttribute("aria-label", e.langFormat("accessibility.credits", {
          creditsStr: ei(t.textStr, e.renderer.forExport)
        })), J(e, t.element));
      }
      getKeyboardNavigation() {
        let e = this.chart;
        return new Q(e, {
          keyCodeMap: [],
          validate: function () {
            return !0;
          },
          init: function () {
            let t = e.accessibility;
            t && t.keyboardNavigation.tabindexContainer.focus();
          }
        });
      }
      destroy() {
        this.chart.renderTo.setAttribute("aria-hidden", !0);
      }
    },
    {
      addEvent: en,
      pick: er
    } = w();
  !function (e) {
    let t = ["x", "y", "transform", "width", "height", "r", "d", "stroke-width"];
    function i() {
      let e = this.focusElement,
        t = this.options.accessibility.keyboardNavigation.focusBorder;
      e && (e.removeFocusBorder(), t.enabled && e.addFocusBorder(t.margin, {
        stroke: t.style.color,
        strokeWidth: t.style.lineWidth,
        r: t.style.borderRadius
      }));
    }
    function s(e, t) {
      let i = this.options.accessibility.keyboardNavigation.focusBorder,
        s = t || e.element;
      s && s.focus && (s.hcEvents && s.hcEvents.focusin || en(s, "focusin", function () {}), s.focus(), i.hideBrowserFocusOutline && (s.style.outline = "none")), this.focusElement && this.focusElement.removeFocusBorder(), this.focusElement = e, this.renderFocusBorder();
    }
    function n(e, i) {
      this.focusBorder && this.removeFocusBorder();
      let s = this.getBBox(),
        n = er(e, 3),
        r = this.parentGroup,
        o = this.scaleX || r && r.scaleX,
        a = this.scaleY || r && r.scaleY,
        l = (o ? !a : a) ? Math.abs(o || a || 1) : (Math.abs(o || 1) + Math.abs(a || 1)) / 2,
        h = this.renderer.fontMetrics(this).h;
      s.x += this.translateX ? this.translateX : 0, s.y += this.translateY ? this.translateY : 0;
      let c = s.x - n,
        d = s.y - n,
        u = s.width + 2 * n,
        p = s.height + 2 * n,
        g = !!this.text;
      if ("text" === this.element.nodeName || g) {
        let e, t;
        let i = !!this.rotation,
          r = g ? {
            x: i ? 1 : 0,
            y: 0
          } : (e = 0, t = 0, "middle" === this.attr("text-anchor") ? e = t = .5 : this.rotation ? e = .25 : t = .75, {
            x: e,
            y: t
          }),
          o = +this.attr("x"),
          a = +this.attr("y");
        if (isNaN(o) || (c = o - s.width * r.x - n), isNaN(a) || (d = a - ("start" === this.attr("text-anchor") ? h : s.height) * r.y - n), g && i) {
          let e = u;
          u = p, p = e, isNaN(o) || (c = o - s.height * r.x - n), isNaN(a) || (d = a - s.width * r.y - n);
        }
      }
      this.focusBorder = this.renderer.rect(c, d, u, p, parseInt((i && i.r || 0).toString(), 10) / l).addClass("highcharts-focus-border").attr({
        zIndex: 99
      }).add(r), this.renderer.styledMode || this.focusBorder.attr({
        stroke: i && i.stroke,
        "stroke-width": (i && i.strokeWidth || 0) / l
      }), function (e, ...i) {
        e.focusBorderUpdateHooks || (e.focusBorderUpdateHooks = {}, t.forEach(t => {
          let s = t + "Setter",
            n = e[s] || e._defaultSetter;
          e.focusBorderUpdateHooks[s] = n, e[s] = function () {
            let t = n.apply(e, arguments);
            return e.addFocusBorder.apply(e, i), t;
          };
        }));
      }(this, e, i), function (e) {
        if (e.focusBorderDestroyHook) return;
        let t = e.destroy;
        e.destroy = function () {
          return e.focusBorder && e.focusBorder.destroy && e.focusBorder.destroy(), t.apply(e, arguments);
        }, e.focusBorderDestroyHook = t;
      }(this);
    }
    function r() {
      var e;
      e = this, e.focusBorderUpdateHooks && (Object.keys(e.focusBorderUpdateHooks).forEach(t => {
        let i = e.focusBorderUpdateHooks[t];
        i === e._defaultSetter ? delete e[t] : e[t] = i;
      }), delete e.focusBorderUpdateHooks), this.focusBorderDestroyHook && (this.destroy = this.focusBorderDestroyHook, delete this.focusBorderDestroyHook), this.focusBorder && (this.focusBorder.destroy(), delete this.focusBorder);
    }
    e.compose = function (e, t) {
      let o = e.prototype,
        a = t.prototype;
      o.renderFocusBorder || (o.renderFocusBorder = i, o.setFocusToElement = s), a.addFocusBorder || (a.addFocusBorder = n, a.removeFocusBorder = r);
    };
  }(p || (p = {}));
  let eo = p;
  var ea = x(660),
    el = x.n(ea);
  let {
      doc: eh
    } = w(),
    {
      addClass: ec,
      visuallyHideElement: ed
    } = S,
    {
      attr: eu
    } = w(),
    ep = class {
      constructor(e, t) {
        this.chart = e, this.domElementProvider = new W(), this.announceRegion = this.addAnnounceRegion(t);
      }
      destroy() {
        this.domElementProvider.destroyCreatedElements();
      }
      announce(e) {
        el().setElementHTML(this.announceRegion, e), this.clearAnnouncementRegionTimer && clearTimeout(this.clearAnnouncementRegionTimer), this.clearAnnouncementRegionTimer = setTimeout(() => {
          this.announceRegion.innerHTML = el().emptyHTML, delete this.clearAnnouncementRegionTimer;
        }, 3e3);
      }
      addAnnounceRegion(e) {
        let t = this.chart.announcerContainer || this.createAnnouncerContainer(),
          i = this.domElementProvider.createElement("div");
        return eu(i, {
          "aria-hidden": !1,
          "aria-live": e,
          "aria-atomic": !0
        }), this.chart.styledMode ? ec(i, "highcharts-visually-hidden") : ed(i), t.appendChild(i), i;
      }
      createAnnouncerContainer() {
        let e = this.chart,
          t = eh.createElement("div");
        return eu(t, {
          "aria-hidden": !1,
          class: "highcharts-announcer-container"
        }), t.style.position = "relative", e.renderTo.insertBefore(t, e.renderTo.firstChild), e.announcerContainer = t, t;
      }
    },
    {
      escapeStringForHTML: eg,
      stripHTMLTagsFromString: em
    } = S;
  function eb(e) {
    return (e.annotations || []).reduce((e, t) => (t.options && !1 !== t.options.visible && (e = e.concat(t.labels)), e), []);
  }
  function ef(e) {
    return e.options && e.options.accessibility && e.options.accessibility.description || e.graphic && e.graphic.text && e.graphic.text.textStr || "";
  }
  function ex(e) {
    let t = e.options && e.options.accessibility && e.options.accessibility.description;
    if (t) return t;
    let i = e.chart,
      s = ef(e),
      n = e.points,
      r = e => e.graphic && e.graphic.element && e.graphic.element.getAttribute("aria-label") || "",
      o = n.filter(e => !!e.graphic).map(e => {
        let t = e.accessibility && e.accessibility.valueDescription || r(e),
          i = e && e.series.name || "";
        return (i ? i + ", " : "") + "data point " + t;
      }).filter(e => !!e),
      a = o.length,
      l = a > 1 ? "MultiplePoints" : a ? "SinglePoint" : "NoPoints",
      h = {
        annotationText: s,
        annotation: e,
        numPoints: a,
        annotationPoint: o[0],
        additionalAnnotationPoints: o.slice(1)
      };
    return i.langFormat("accessibility.screenReaderSection.annotations.description" + l, h);
  }
  function ey(e) {
    return eb(e).map(t => {
      let i = eg(em(ex(t), e.renderer.forExport));
      return i ? `<li>${i}</li>` : "";
    });
  }
  let ev = {
      getAnnotationsInfoHTML: function (e) {
        let t = e.annotations;
        if (!(t && t.length)) return "";
        let i = ey(e);
        return `<ul style="list-style-type: none">${i.join(" ")}</ul>`;
      },
      getAnnotationLabelDescription: ex,
      getAnnotationListItems: ey,
      getPointAnnotationTexts: function (e) {
        let t = eb(e.series.chart).filter(t => t.points.indexOf(e) > -1);
        return t.length ? t.map(e => `${ef(e)}`) : [];
      }
    },
    {
      getAnnotationsInfoHTML: ew
    } = ev,
    {
      getAxisDescription: eE,
      getAxisRangeDescription: eA,
      getChartTitle: eT,
      unhideChartElementFromAT: eC
    } = K,
    {
      format: eM
    } = D(),
    {
      doc: ek
    } = w(),
    {
      addClass: eS,
      getElement: eP,
      getHeadingTagNameForElement: eD,
      stripHTMLTagsFromString: eN,
      visuallyHideElement: eB
    } = S,
    {
      attr: eI,
      pick: eF,
      replaceNested: eO
    } = w();
  function eR(e) {
    return eO(e, [/<([\w\-.:!]+)\b[^<>]*>\s*<\/\1>/g, ""]);
  }
  let eL = class extends Z {
      constructor() {
        super(...arguments), this.screenReaderSections = {};
      }
      init() {
        let e = this.chart,
          t = this;
        this.initRegionsDefinitions(), this.addEvent(e, "aftergetTableAST", function (e) {
          t.onDataTableCreated(e);
        }), this.addEvent(e, "afterViewData", function (e) {
          e.wasHidden && (t.dataTableDiv = e.element, setTimeout(function () {
            t.focusDataTable();
          }, 300));
        }), this.addEvent(e, "afterHideData", function () {
          t.viewDataTableButton && t.viewDataTableButton.setAttribute("aria-expanded", "false");
        }), e.exporting && this.addEvent(e, "afterPrint", function () {
          t.updateAllScreenReaderSections();
        }), this.announcer = new ep(e, "assertive");
      }
      initRegionsDefinitions() {
        let e = this,
          t = this.chart.options.accessibility;
        this.screenReaderSections = {
          before: {
            element: null,
            buildContent: function (i) {
              let s = t.screenReaderSection.beforeChartFormatter;
              return s ? s(i) : e.defaultBeforeChartFormatter(i);
            },
            insertIntoDOM: function (e, t) {
              t.renderTo.insertBefore(e, t.renderTo.firstChild);
            },
            afterInserted: function () {
              void 0 !== e.sonifyButtonId && e.initSonifyButton(e.sonifyButtonId), void 0 !== e.dataTableButtonId && e.initDataTableButton(e.dataTableButtonId);
            }
          },
          after: {
            element: null,
            buildContent: function (i) {
              let s = t.screenReaderSection.afterChartFormatter;
              return s ? s(i) : e.defaultAfterChartFormatter();
            },
            insertIntoDOM: function (e, t) {
              t.renderTo.insertBefore(e, t.container.nextSibling);
            },
            afterInserted: function () {
              e.chart.accessibility && t.keyboardNavigation.enabled && e.chart.accessibility.keyboardNavigation.updateExitAnchor();
            }
          }
        };
      }
      onChartRender() {
        this.linkedDescriptionElement = this.getLinkedDescriptionElement(), this.setLinkedDescriptionAttrs(), this.updateAllScreenReaderSections();
      }
      updateAllScreenReaderSections() {
        let e = this;
        Object.keys(this.screenReaderSections).forEach(function (t) {
          e.updateScreenReaderSection(t);
        });
      }
      getLinkedDescriptionElement() {
        let e = this.chart.options.accessibility.linkedDescription;
        if (!e) return;
        if ("string" != typeof e) return e;
        let t = eM(e, this.chart),
          i = ek.querySelectorAll(t);
        if (1 === i.length) return i[0];
      }
      setLinkedDescriptionAttrs() {
        let e = this.linkedDescriptionElement;
        e && (e.setAttribute("aria-hidden", "true"), eS(e, "highcharts-linked-description"));
      }
      updateScreenReaderSection(e) {
        let t = this.chart,
          i = this.screenReaderSections[e],
          s = i.buildContent(t),
          n = i.element = i.element || this.createElement("div"),
          r = n.firstChild || this.createElement("div");
        s ? (this.setScreenReaderSectionAttribs(n, e), el().setElementHTML(r, s), n.appendChild(r), i.insertIntoDOM(n, t), t.styledMode ? eS(r, "highcharts-visually-hidden") : eB(r), eC(t, r), i.afterInserted && i.afterInserted()) : (n.parentNode && n.parentNode.removeChild(n), i.element = null);
      }
      setScreenReaderSectionAttribs(e, t) {
        let i = this.chart,
          s = i.langFormat("accessibility.screenReaderSection." + t + "RegionLabel", {
            chart: i,
            chartTitle: eT(i)
          });
        eI(e, {
          id: `highcharts-screen-reader-region-${t}-${i.index}`,
          "aria-label": s || void 0
        }), e.style.position = "relative", s && e.setAttribute("role", "all" === i.options.accessibility.landmarkVerbosity ? "region" : "group");
      }
      defaultBeforeChartFormatter() {
        let e = this.chart,
          t = e.options.accessibility.screenReaderSection.beforeChartFormat;
        if (!t) return "";
        let i = this.getAxesDescription(),
          s = e.sonify && e.options.sonification && e.options.sonification.enabled,
          n = "highcharts-a11y-sonify-data-btn-" + e.index,
          r = "hc-linkto-highcharts-data-table-" + e.index,
          o = ew(e),
          a = e.langFormat("accessibility.screenReaderSection.annotations.heading", {
            chart: e
          }),
          l = {
            headingTagName: eD(e.renderTo),
            chartTitle: eT(e),
            typeDescription: this.getTypeDescriptionText(),
            chartSubtitle: this.getSubtitleText(),
            chartLongdesc: this.getLongdescText(),
            xAxisDescription: i.xAxis,
            yAxisDescription: i.yAxis,
            playAsSoundButton: s ? this.getSonifyButtonText(n) : "",
            viewTableButton: e.getCSV ? this.getDataTableButtonText(r) : "",
            annotationsTitle: o ? a : "",
            annotationsList: o
          },
          h = F.i18nFormat(t, l, e);
        return this.dataTableButtonId = r, this.sonifyButtonId = n, eR(h);
      }
      defaultAfterChartFormatter() {
        let e = this.chart,
          t = e.options.accessibility.screenReaderSection.afterChartFormat;
        if (!t) return "";
        let i = {
          endOfChartMarker: this.getEndOfChartMarkerText()
        };
        return eR(F.i18nFormat(t, i, e));
      }
      getLinkedDescription() {
        let e = this.linkedDescriptionElement;
        return eN(e && e.innerHTML || "", this.chart.renderer.forExport);
      }
      getLongdescText() {
        let e = this.chart.options,
          t = e.caption,
          i = t && t.text,
          s = this.getLinkedDescription();
        return e.accessibility.description || s || i || "";
      }
      getTypeDescriptionText() {
        let e = this.chart;
        return e.types ? e.options.accessibility.typeDescription || function (e, t) {
          let i = t[0],
            s = e.series && e.series[0] || {},
            n = e.mapView && e.mapView.geoMap && e.mapView.geoMap.title,
            r = {
              numSeries: e.series.length,
              numPoints: s.points && s.points.length,
              chart: e,
              mapTitle: n
            };
          return i ? "map" === i || "tiledwebmap" === i ? r.mapTitle ? e.langFormat("accessibility.chartTypes.mapTypeDescription", r) : e.langFormat("accessibility.chartTypes.unknownMap", r) : e.types.length > 1 ? e.langFormat("accessibility.chartTypes.combinationChart", r) : function (e, t, i) {
            let s = t[0],
              n = e.langFormat("accessibility.seriesTypeDescriptions." + s, i),
              r = e.series && e.series.length < 2 ? "Single" : "Multiple";
            return (e.langFormat("accessibility.chartTypes." + s + r, i) || e.langFormat("accessibility.chartTypes.default" + r, i)) + (n ? " " + n : "");
          }(e, t, r) : e.langFormat("accessibility.chartTypes.emptyChart", r);
        }(e, e.types) : "";
      }
      getDataTableButtonText(e) {
        let t = this.chart;
        return '<button id="' + e + '">' + t.langFormat("accessibility.table.viewAsDataTableButtonText", {
          chart: t,
          chartTitle: eT(t)
        }) + "</button>";
      }
      getSonifyButtonText(e) {
        let t = this.chart;
        return t.options.sonification && !1 === t.options.sonification.enabled ? "" : '<button id="' + e + '">' + t.langFormat("accessibility.sonification.playAsSoundButtonText", {
          chart: t,
          chartTitle: eT(t)
        }) + "</button>";
      }
      getSubtitleText() {
        let e = this.chart.options.subtitle;
        return eN(e && e.text || "", this.chart.renderer.forExport);
      }
      getEndOfChartMarkerText() {
        let e = eP(`highcharts-end-of-chart-marker-${this.chart.index}`);
        if (e) return e.outerHTML;
        let t = this.chart,
          i = t.langFormat("accessibility.screenReaderSection.endOfChartMarker", {
            chart: t
          });
        return '<div id="highcharts-end-of-chart-marker-' + t.index + '">' + i + "</div>";
      }
      onDataTableCreated(e) {
        let t = this.chart;
        if (t.options.accessibility.enabled) {
          this.viewDataTableButton && this.viewDataTableButton.setAttribute("aria-expanded", "true");
          let i = e.tree.attributes || {};
          i.tabindex = -1, i.summary = t.langFormat("accessibility.table.tableSummary", {
            chart: t
          }), e.tree.attributes = i;
        }
      }
      focusDataTable() {
        let e = this.dataTableDiv,
          t = e && e.getElementsByTagName("table")[0];
        t && t.focus && t.focus();
      }
      initSonifyButton(e) {
        let t = this.sonifyButton = eP(e),
          i = this.chart,
          s = e => {
            t && (t.setAttribute("aria-hidden", "true"), t.setAttribute("aria-label", "")), e.preventDefault(), e.stopPropagation();
            let s = i.langFormat("accessibility.sonification.playAsSoundClickAnnouncement", {
              chart: i
            });
            this.announcer.announce(s), setTimeout(() => {
              t && (t.removeAttribute("aria-hidden"), t.removeAttribute("aria-label")), i.sonify && i.sonify();
            }, 1e3);
          };
        t && i && (t.setAttribute("tabindex", -1), t.onclick = function (e) {
          (i.options.accessibility && i.options.accessibility.screenReaderSection.onPlayAsSoundClick || s).call(this, e, i);
        });
      }
      initDataTableButton(e) {
        let t = this.viewDataTableButton = eP(e),
          i = this.chart,
          s = e.replace("hc-linkto-", "");
        t && (eI(t, {
          tabindex: -1,
          "aria-expanded": !!eP(s)
        }), t.onclick = i.options.accessibility.screenReaderSection.onViewDataTableClick || function () {
          i.viewData();
        });
      }
      getAxesDescription() {
        let e = this.chart,
          t = function (t, i) {
            let s = e[t];
            return s.length > 1 || s[0] && eF(s[0].options.accessibility && s[0].options.accessibility.enabled, i);
          },
          i = !!e.types && 0 > e.types.indexOf("map") && 0 > e.types.indexOf("treemap") && 0 > e.types.indexOf("tilemap"),
          s = !!e.hasCartesianSeries,
          n = t("xAxis", !e.angular && s && i),
          r = t("yAxis", s && i),
          o = {};
        return n && (o.xAxis = this.getAxisDescriptionText("xAxis")), r && (o.yAxis = this.getAxisDescriptionText("yAxis")), o;
      }
      getAxisDescriptionText(e) {
        let t = this.chart,
          i = t[e];
        return t.langFormat("accessibility.axis." + e + "Description" + (i.length > 1 ? "Plural" : "Singular"), {
          chart: t,
          names: i.map(function (e) {
            return eE(e);
          }),
          ranges: i.map(function (e) {
            return eA(e);
          }),
          numAxes: i.length
        });
      }
      destroy() {
        this.announcer && this.announcer.destroy();
      }
    },
    {
      attr: eH
    } = w(),
    {
      getChartTitle: ez,
      unhideChartElementFromAT: eq
    } = K,
    {
      getFakeMouseEvent: eG
    } = S;
  function eK(e) {
    return e.exportSVGElements && e.exportSVGElements[0];
  }
  class eU extends Z {
    init() {
      let e = this.chart,
        t = this;
      this.addEvent(e, "exportMenuShown", function () {
        t.onMenuShown();
      }), this.addEvent(e, "exportMenuHidden", function () {
        t.onMenuHidden();
      }), this.createProxyGroup();
    }
    onMenuHidden() {
      let e = this.chart.exportContextMenu;
      e && e.setAttribute("aria-hidden", "true"), this.setExportButtonExpandedState("false");
    }
    onMenuShown() {
      let e = this.chart,
        t = e.exportContextMenu;
      t && (this.addAccessibleContextMenuAttribs(), eq(e, t)), this.setExportButtonExpandedState("true");
    }
    setExportButtonExpandedState(e) {
      this.exportButtonProxy && this.exportButtonProxy.innerElement.setAttribute("aria-expanded", e);
    }
    onChartRender() {
      let e = this.chart,
        t = e.focusElement,
        i = e.accessibility;
      this.proxyProvider.clearGroup("chartMenu"), this.proxyMenuButton(), this.exportButtonProxy && t && t === e.exportingGroup && (t.focusBorder ? e.setFocusToElement(t, this.exportButtonProxy.innerElement) : i && i.keyboardNavigation.tabindexContainer.focus());
    }
    proxyMenuButton() {
      let e = this.chart,
        t = this.proxyProvider,
        i = eK(e);
      (function (e) {
        let t = e.options.exporting,
          i = eK(e);
        return !!(t && !1 !== t.enabled && t.accessibility && t.accessibility.enabled && i && i.element);
      })(e) && i && (this.exportButtonProxy = t.addProxyElement("chartMenu", {
        click: i
      }, "button", {
        "aria-label": e.langFormat("accessibility.exporting.menuButtonLabel", {
          chart: e,
          chartTitle: ez(e)
        }),
        "aria-expanded": !1,
        title: e.options.lang.contextButtonTitle || null
      }));
    }
    createProxyGroup() {
      this.chart && this.proxyProvider && this.proxyProvider.addGroup("chartMenu");
    }
    addAccessibleContextMenuAttribs() {
      let e = this.chart,
        t = e.exportDivElements;
      if (t && t.length) {
        t.forEach(e => {
          e && ("LI" !== e.tagName || e.children && e.children.length ? e.setAttribute("aria-hidden", "true") : e.setAttribute("tabindex", -1));
        });
        let i = t[0] && t[0].parentNode;
        i && eH(i, {
          "aria-hidden": void 0,
          "aria-label": e.langFormat("accessibility.exporting.chartMenuLabel", {
            chart: e
          }),
          role: "list"
        });
      }
    }
    getKeyboardNavigation() {
      let e = this.keyCodes,
        t = this.chart,
        i = this;
      return new Q(t, {
        keyCodeMap: [[[e.left, e.up], function () {
          return i.onKbdPrevious(this);
        }], [[e.right, e.down], function () {
          return i.onKbdNext(this);
        }], [[e.enter, e.space], function () {
          return i.onKbdClick(this);
        }]],
        validate: function () {
          return !!t.exporting && !1 !== t.options.exporting.enabled && !1 !== t.options.exporting.accessibility.enabled;
        },
        init: function () {
          let e = i.exportButtonProxy,
            s = i.chart.exportingGroup;
          e && s && t.setFocusToElement(s, e.innerElement);
        },
        terminate: function () {
          t.hideExportMenu();
        }
      });
    }
    onKbdPrevious(e) {
      let t = this.chart,
        i = t.options.accessibility,
        s = e.response,
        n = t.highlightedExportItemIx || 0;
      for (; n--;) if (t.highlightExportItem(n)) return s.success;
      return i.keyboardNavigation.wrapAround ? (t.highlightLastExportItem(), s.success) : s.prev;
    }
    onKbdNext(e) {
      let t = this.chart,
        i = t.options.accessibility,
        s = e.response;
      for (let e = (t.highlightedExportItemIx || 0) + 1; e < t.exportDivElements.length; ++e) if (t.highlightExportItem(e)) return s.success;
      return i.keyboardNavigation.wrapAround ? (t.highlightExportItem(0), s.success) : s.next;
    }
    onKbdClick(e) {
      let t = this.chart,
        i = t.exportDivElements[t.highlightedExportItemIx],
        s = eK(t).element;
      return t.openMenu ? this.fakeClickEvent(i) : (this.fakeClickEvent(s), t.highlightExportItem(0)), e.response.success;
    }
  }
  !function (e) {
    function t() {
      let e = eK(this);
      if (e) {
        let t = e.element;
        t.onclick && t.onclick(eG("click"));
      }
    }
    function i() {
      let e = this.exportDivElements;
      e && this.exportContextMenu && this.openMenu && (e.forEach(e => {
        e && "highcharts-menu-item" === e.className && e.onmouseout && e.onmouseout(eG("mouseout"));
      }), this.highlightedExportItemIx = 0, this.exportContextMenu.hideMenu(), this.container.focus());
    }
    function s(e) {
      let t = this.exportDivElements && this.exportDivElements[e],
        i = this.exportDivElements && this.exportDivElements[this.highlightedExportItemIx];
      if (t && "LI" === t.tagName && !(t.children && t.children.length)) {
        let s = !!(this.renderTo.getElementsByTagName("g")[0] || {}).focus;
        return t.focus && s && t.focus(), i && i.onmouseout && i.onmouseout(eG("mouseout")), t.onmouseover && t.onmouseover(eG("mouseover")), this.highlightedExportItemIx = e, !0;
      }
      return !1;
    }
    function n() {
      if (this.exportDivElements) {
        let e = this.exportDivElements.length;
        for (; e--;) if (this.highlightExportItem(e)) return !0;
      }
      return !1;
    }
    e.compose = function (e) {
      let r = e.prototype;
      r.hideExportMenu || (r.hideExportMenu = i, r.highlightExportItem = s, r.highlightLastExportItem = n, r.showExportMenu = t);
    };
  }(eU || (eU = {}));
  let eV = eU,
    {
      doc: eW,
      win: eX
    } = w(),
    {
      addEvent: eY,
      defined: ej,
      fireEvent: e_
    } = w(),
    {
      getElement: eZ,
      simulatedEventTarget: e$
    } = S;
  class eQ {
    constructor(e, t) {
      this.currentModuleIx = NaN, this.modules = [], this.init(e, t);
    }
    init(e, t) {
      let i = this.eventProvider = new Y();
      this.chart = e, this.components = t, this.modules = [], this.currentModuleIx = 0, this.update(), i.addEvent(this.tabindexContainer, "keydown", e => this.onKeydown(e)), i.addEvent(this.tabindexContainer, "focus", e => this.onFocus(e)), ["mouseup", "touchend"].forEach(e => i.addEvent(eW, e, e => this.onMouseUp(e))), ["mousedown", "touchstart"].forEach(t => i.addEvent(e.renderTo, t, () => {
        this.isClickingChart = !0;
      }));
    }
    update(e) {
      let t = this.chart.options.accessibility,
        i = t && t.keyboardNavigation,
        s = this.components;
      this.updateContainerTabindex(), i && i.enabled && e && e.length ? (this.modules = e.reduce(function (e, t) {
        let i = s[t].getKeyboardNavigation();
        return e.concat(i);
      }, []), this.updateExitAnchor()) : (this.modules = [], this.currentModuleIx = 0, this.removeExitAnchor());
    }
    updateExitAnchor() {
      let e = eZ(`highcharts-end-of-chart-marker-${this.chart.index}`);
      this.removeExitAnchor(), e ? (this.makeElementAnExitAnchor(e), this.exitAnchor = e) : this.createExitAnchor();
    }
    move(e) {
      let t = this.modules && this.modules[this.currentModuleIx];
      t && t.terminate && t.terminate(e), this.chart.focusElement && this.chart.focusElement.removeFocusBorder(), this.currentModuleIx += e;
      let i = this.modules && this.modules[this.currentModuleIx];
      if (i) {
        if (i.validate && !i.validate()) return this.move(e);
        if (i.init) return i.init(e), !0;
      }
      return this.currentModuleIx = 0, this.exiting = !0, e > 0 ? this.exitAnchor && this.exitAnchor.focus() : this.tabindexContainer.focus(), !1;
    }
    onFocus(e) {
      let t = this.chart,
        i = e.relatedTarget && t.container.contains(e.relatedTarget),
        s = t.options.accessibility,
        n = s && s.keyboardNavigation;
      if (n && n.enabled && !this.exiting && !this.tabbingInBackwards && !this.isClickingChart && !i) {
        let e = this.getFirstValidModuleIx();
        null !== e && (this.currentModuleIx = e, this.modules[e].init(1));
      }
      this.keyboardReset = !1, this.exiting = !1;
    }
    onMouseUp(e) {
      if (delete this.isClickingChart, !this.keyboardReset && e.relatedTarget !== e$) {
        let t = this.chart;
        if (!e.target || !t.container.contains(e.target)) {
          let e = this.modules && this.modules[this.currentModuleIx || 0];
          e && e.terminate && e.terminate(), this.currentModuleIx = 0;
        }
        t.focusElement && (t.focusElement.removeFocusBorder(), delete t.focusElement), this.keyboardReset = !0;
      }
    }
    onKeydown(e) {
      let t;
      let i = e || eX.event,
        s = this.modules && this.modules.length && this.modules[this.currentModuleIx],
        n = i.target;
      if ((!n || "INPUT" !== n.nodeName || n.classList.contains("highcharts-a11y-proxy-element")) && (this.keyboardReset = !1, this.exiting = !1, s)) {
        let e = s.run(i);
        e === s.response.success ? t = !0 : e === s.response.prev ? t = this.move(-1) : e === s.response.next && (t = this.move(1)), t && (i.preventDefault(), i.stopPropagation());
      }
    }
    updateContainerTabindex() {
      let e;
      let t = this.chart.options.accessibility,
        i = t && t.keyboardNavigation,
        s = !(i && !1 === i.enabled),
        n = this.chart,
        r = n.container;
      n.renderTo.hasAttribute("tabindex") ? (r.removeAttribute("tabindex"), e = n.renderTo) : e = r, this.tabindexContainer = e;
      let o = e.getAttribute("tabindex");
      s && !o ? e.setAttribute("tabindex", "0") : s || n.container.removeAttribute("tabindex");
    }
    createExitAnchor() {
      let e = this.chart,
        t = this.exitAnchor = eW.createElement("div");
      e.renderTo.appendChild(t), this.makeElementAnExitAnchor(t);
    }
    makeElementAnExitAnchor(e) {
      let t = this.tabindexContainer.getAttribute("tabindex") || 0;
      e.setAttribute("class", "highcharts-exit-anchor"), e.setAttribute("tabindex", t), e.setAttribute("aria-hidden", !1), this.addExitAnchorEventsToEl(e);
    }
    removeExitAnchor() {
      if (this.exitAnchor) {
        let e = this.eventProvider.eventRemovers.find(e => e.element === this.exitAnchor);
        e && ej(e.remover) && this.eventProvider.removeEvent(e.remover), this.exitAnchor.parentNode && this.exitAnchor.parentNode.removeChild(this.exitAnchor), delete this.exitAnchor;
      }
    }
    addExitAnchorEventsToEl(e) {
      let t = this.chart,
        i = this;
      this.eventProvider.addEvent(e, "focus", function (e) {
        let s = e || eX.event,
          n = !(s.relatedTarget && t.container.contains(s.relatedTarget) || i.exiting);
        if (t.focusElement && delete t.focusElement, n) {
          if (i.tabbingInBackwards = !0, i.tabindexContainer.focus(), delete i.tabbingInBackwards, s.preventDefault(), i.modules && i.modules.length) {
            i.currentModuleIx = i.modules.length - 1;
            let e = i.modules[i.currentModuleIx];
            e && e.validate && !e.validate() ? i.move(-1) : e && e.init(-1);
          }
        } else i.exiting = !1;
      });
    }
    getFirstValidModuleIx() {
      let e = this.modules.length;
      for (let t = 0; t < e; ++t) {
        let e = this.modules[t];
        if (!e.validate || e.validate()) return t;
      }
      return null;
    }
    destroy() {
      this.removeExitAnchor(), this.eventProvider.removeAddedEvents(), this.chart.container.removeAttribute("tabindex");
    }
  }
  !function (e) {
    function t() {
      let e = this;
      e_(this, "dismissPopupContent", {}, function () {
        e.tooltip && e.tooltip.hide(0), e.hideExportMenu();
      });
    }
    function i(e) {
      27 === (e.which || e.keyCode) && w().charts && w().charts.forEach(e => {
        e && e.dismissPopupContent && e.dismissPopupContent();
      });
    }
    e.compose = function (e) {
      eV.compose(e);
      let s = e.prototype;
      return s.dismissPopupContent || (s.dismissPopupContent = t, eY(eW, "keydown", i)), e;
    };
  }(eQ || (eQ = {}));
  let eJ = eQ;
  var e0 = x(632),
    e1 = x.n(e0);
  let {
      animObject: e2
    } = w(),
    {
      doc: e3
    } = w(),
    {
      addEvent: e5,
      fireEvent: e4,
      isNumber: e6,
      pick: e9,
      syncTimeout: e8
    } = w(),
    {
      getChartTitle: e7
    } = K,
    {
      stripHTMLTagsFromString: te,
      addClass: tt,
      removeClass: ti
    } = S;
  function ts(e) {
    let t = e.legend && e.legend.allItems,
      i = e.options.legend.accessibility || {},
      s = e.colorAxis && e.colorAxis.some(e => !e.dataClasses || !e.dataClasses.length);
    return !!(t && t.length && !s && !1 !== i.enabled);
  }
  function tn(e, t) {
    let i = t.legendItem || {};
    for (let s of (t.setState(e ? "hover" : "", !0), ["group", "label", "symbol"])) {
      let t = i[s],
        n = t && t.element || t;
      n && e4(n, e ? "mouseover" : "mouseout");
    }
  }
  class tr extends Z {
    constructor() {
      super(...arguments), this.highlightedLegendItemIx = NaN, this.proxyGroup = null;
    }
    init() {
      let e = this;
      this.recreateProxies(), this.addEvent(e1(), "afterScroll", function () {
        this.chart === e.chart && (e.proxyProvider.updateGroupProxyElementPositions("legend"), e.updateLegendItemProxyVisibility(), e.highlightedLegendItemIx > -1 && this.chart.highlightLegendItem(e.highlightedLegendItemIx));
      }), this.addEvent(e1(), "afterPositionItem", function (t) {
        this.chart === e.chart && this.chart.renderer && e.updateProxyPositionForItem(t.item);
      }), this.addEvent(e1(), "afterRender", function () {
        this.chart === e.chart && this.chart.renderer && e.recreateProxies() && e8(() => e.proxyProvider.updateGroupProxyElementPositions("legend"), e2(e9(this.chart.renderer.globalAnimation, !0)).duration);
      });
    }
    updateLegendItemProxyVisibility() {
      let e;
      let t = this.chart,
        i = t.legend,
        s = i.allItems || [],
        n = i.currentPage || 1,
        r = i.clipHeight || 0;
      s.forEach(s => {
        if (s.a11yProxyElement) {
          let o = i.pages && i.pages.length,
            a = s.a11yProxyElement.element,
            l = !1;
          if (e = s.legendItem || {}, o) {
            let t = e.pageIx || 0;
            l = (e.y || 0) + (e.label ? Math.round(e.label.getBBox().height) : 0) - i.pages[t] > r || t !== n - 1;
          }
          l ? t.styledMode ? tt(a, "highcharts-a11y-invisible") : a.style.visibility = "hidden" : (ti(a, "highcharts-a11y-invisible"), a.style.visibility = "");
        }
      });
    }
    onChartRender() {
      ts(this.chart) || this.removeProxies();
    }
    highlightAdjacentLegendPage(e) {
      let t = this.chart,
        i = t.legend,
        s = (i.currentPage || 1) + e,
        n = i.pages || [];
      if (s > 0 && s <= n.length) {
        let e = 0;
        for (let n of i.allItems) ((n.legendItem || {}).pageIx || 0) + 1 === s && t.highlightLegendItem(e) && (this.highlightedLegendItemIx = e), ++e;
      }
    }
    updateProxyPositionForItem(e) {
      e.a11yProxyElement && e.a11yProxyElement.refreshPosition();
    }
    recreateProxies() {
      let e = e3.activeElement,
        t = this.proxyGroup,
        i = e && t && t.contains(e);
      return this.removeProxies(), !!ts(this.chart) && (this.addLegendProxyGroup(), this.proxyLegendItems(), this.updateLegendItemProxyVisibility(), this.updateLegendTitle(), i && this.chart.highlightLegendItem(this.highlightedLegendItemIx), !0);
    }
    removeProxies() {
      this.proxyProvider.removeGroup("legend");
    }
    updateLegendTitle() {
      let e = this.chart,
        t = te((e.legend && e.legend.options.title && e.legend.options.title.text || "").replace(/<br ?\/?>/g, " "), e.renderer.forExport),
        i = e.langFormat("accessibility.legend.legendLabel" + (t ? "" : "NoTitle"), {
          chart: e,
          legendTitle: t,
          chartTitle: e7(e)
        });
      this.proxyProvider.updateGroupAttrs("legend", {
        "aria-label": i
      });
    }
    addLegendProxyGroup() {
      let e = "all" === this.chart.options.accessibility.landmarkVerbosity ? "region" : null;
      this.proxyGroup = this.proxyProvider.addGroup("legend", "ul", {
        "aria-label": "_placeholder_",
        role: e
      });
    }
    proxyLegendItems() {
      let e;
      let t = this;
      ((this.chart.legend || {}).allItems || []).forEach(i => {
        (e = i.legendItem || {}).label && e.label.element && t.proxyLegendItem(i);
      });
    }
    proxyLegendItem(e) {
      let t = e.legendItem || {};
      if (!t.label || !t.group) return;
      let i = this.chart.langFormat("accessibility.legend.legendItem", {
          chart: this.chart,
          itemName: te(e.name, this.chart.renderer.forExport),
          item: e
        }),
        s = {
          tabindex: -1,
          "aria-pressed": e.visible,
          "aria-label": i
        },
        n = t.group.div ? t.label : t.group;
      e.a11yProxyElement = this.proxyProvider.addProxyElement("legend", {
        click: t.label,
        visual: n.element
      }, "button", s);
    }
    getKeyboardNavigation() {
      let e = this.keyCodes,
        t = this,
        i = this.chart;
      return new Q(i, {
        keyCodeMap: [[[e.left, e.right, e.up, e.down], function (e) {
          return t.onKbdArrowKey(this, e);
        }], [[e.enter, e.space], function () {
          return t.onKbdClick(this);
        }], [[e.pageDown, e.pageUp], function (i) {
          let s = i === e.pageDown ? 1 : -1;
          return t.highlightAdjacentLegendPage(s), this.response.success;
        }]],
        validate: function () {
          return t.shouldHaveLegendNavigation();
        },
        init: function () {
          i.highlightLegendItem(0), t.highlightedLegendItemIx = 0;
        },
        terminate: function () {
          t.highlightedLegendItemIx = -1, i.legend.allItems.forEach(e => tn(!1, e));
        }
      });
    }
    onKbdArrowKey(e, t) {
      let {
          keyCodes: {
            left: i,
            up: s
          },
          highlightedLegendItemIx: n,
          chart: r
        } = this,
        o = r.legend.allItems.length,
        a = r.options.accessibility.keyboardNavigation.wrapAround,
        l = t === i || t === s ? -1 : 1;
      return r.highlightLegendItem(n + l) ? this.highlightedLegendItemIx += l : a && o > 1 && (this.highlightedLegendItemIx = l > 0 ? 0 : o - 1, r.highlightLegendItem(this.highlightedLegendItemIx)), e.response.success;
    }
    onKbdClick(e) {
      let t = this.chart.legend.allItems[this.highlightedLegendItemIx];
      return t && t.a11yProxyElement && t.a11yProxyElement.click(), e.response.success;
    }
    shouldHaveLegendNavigation() {
      if (!ts(this.chart)) return !1;
      let e = this.chart,
        t = (e.options.legend || {}).accessibility || {};
      return !!(e.legend.display && t.keyboardNavigation && t.keyboardNavigation.enabled);
    }
    destroy() {
      this.removeProxies();
    }
  }
  !function (e) {
    function t(e) {
      let t = this.legend.allItems,
        i = this.accessibility && this.accessibility.components.legend.highlightedLegendItemIx,
        s = t[e],
        n = s?.legendItem || {};
      if (s) {
        e6(i) && t[i] && tn(!1, t[i]), function (e, t) {
          let i = (e.allItems[t].legendItem || {}).pageIx,
            s = e.currentPage;
          void 0 !== i && i + 1 !== s && e.scroll(1 + i - s);
        }(this.legend, e);
        let r = n.label,
          o = s.a11yProxyElement && s.a11yProxyElement.innerElement;
        return r && r.element && o && this.setFocusToElement(r, o), tn(!0, s), !0;
      }
      return !1;
    }
    function i(e) {
      let t = this.chart.options.accessibility,
        i = e.item;
      t.enabled && i && i.a11yProxyElement && i.a11yProxyElement.innerElement.setAttribute("aria-pressed", e.visible ? "true" : "false");
    }
    e.compose = function (e, s) {
      let n = e.prototype;
      n.highlightLegendItem || (n.highlightLegendItem = t, e5(s, "afterColorizeItem", i));
    };
  }(tr || (tr = {}));
  let to = tr;
  var ta = x(532),
    tl = x.n(ta);
  let {
      isTouchDevice: th
    } = w(),
    {
      addEvent: tc,
      merge: td,
      pick: tu
    } = w(),
    tp = [];
  function tg() {
    this.navigator && this.navigator.setBaseSeries(null, !1);
  }
  function tm() {
    let e, t, i;
    let s = this.legend,
      n = this.navigator;
    if (n) {
      e = s && s.options, t = n.xAxis, i = n.yAxis;
      let {
        scrollbarHeight: r,
        scrollButtonSize: o
      } = n;
      this.inverted ? (n.left = n.opposite ? this.chartWidth - r - n.height : this.spacing[3] + r, n.top = this.plotTop + o) : (n.left = tu(t.left, this.plotLeft + o), n.top = n.navigatorOptions.top || this.chartHeight - n.height - r - (this.scrollbar?.options.margin || 0) - this.spacing[2] - (this.rangeSelector && this.extraBottomMargin ? this.rangeSelector.getHeight() : 0) - (e && "bottom" === e.verticalAlign && "proximate" !== e.layout && e.enabled && !e.floating ? s.legendHeight + tu(e.margin, 10) : 0) - (this.titleOffset ? this.titleOffset[2] : 0)), t && i && (this.inverted ? t.options.left = i.options.left = n.left : t.options.top = i.options.top = n.top, t.setAxisSize(), i.setAxisSize());
    }
  }
  function tb(e) {
    !this.navigator && !this.scroller && (this.options.navigator.enabled || this.options.scrollbar.enabled) && (this.scroller = this.navigator = new d(this), tu(e.redraw, !0) && this.redraw(e.animation));
  }
  function tf() {
    let e = this.options;
    (e.navigator.enabled || e.scrollbar.enabled) && (this.scroller = this.navigator = new d(this));
  }
  function tx() {
    let e = this.options,
      t = e.navigator,
      i = e.rangeSelector;
    if ((t && t.enabled || i && i.enabled) && (!th && "x" === this.zooming.type || th && "x" === this.zooming.pinchType)) return !1;
  }
  function ty(e) {
    let t = e.navigator;
    if (t && e.xAxis[0]) {
      let i = e.xAxis[0].getExtremes();
      t.render(i.min, i.max);
    }
  }
  function tv(e) {
    let t = e.options.navigator || {},
      i = e.options.scrollbar || {};
    !this.navigator && !this.scroller && (t.enabled || i.enabled) && (td(!0, this.options.navigator, t), td(!0, this.options.scrollbar, i), delete e.options.navigator, delete e.options.scrollbar);
  }
  let tw = {
      compose: function (e, t) {
        if (w().pushUnique(tp, e)) {
          let i = e.prototype;
          d = t, i.callbacks.push(ty), tc(e, "afterAddSeries", tg), tc(e, "afterSetChartSize", tm), tc(e, "afterUpdate", tb), tc(e, "beforeRender", tf), tc(e, "beforeShowResetZoom", tx), tc(e, "update", tv);
        }
      }
    },
    {
      isTouchDevice: tE
    } = w(),
    {
      addEvent: tA,
      correctFloat: tT,
      defined: tC,
      isNumber: tM,
      pick: tk
    } = w();
  function tS() {
    this.navigatorAxis || (this.navigatorAxis = new tD(this));
  }
  function tP(e) {
    let t;
    let i = this.chart,
      s = i.options,
      n = s.navigator,
      r = this.navigatorAxis,
      o = i.zooming.pinchType,
      a = s.rangeSelector,
      l = i.zooming.type;
    if (this.isXAxis && (n?.enabled || a?.enabled)) {
      if ("y" === l && "zoom" === e.trigger) t = !1;else if (("zoom" === e.trigger && "xy" === l || tE && "xy" === o) && this.options.range) {
        let t = r.previousZoom;
        tC(e.min) ? r.previousZoom = [this.min, this.max] : t && (e.min = t[0], e.max = t[1], r.previousZoom = void 0);
      }
    }
    void 0 !== t && e.preventDefault();
  }
  class tD {
    static compose(e) {
      e.keepProps.includes("navigatorAxis") || (e.keepProps.push("navigatorAxis"), tA(e, "init", tS), tA(e, "setExtremes", tP));
    }
    constructor(e) {
      this.axis = e;
    }
    destroy() {
      this.axis = void 0;
    }
    toFixedRange(e, t, i, s) {
      let n = this.axis,
        r = (n.pointRange || 0) / 2,
        o = tk(i, n.translate(e, !0, !n.horiz)),
        a = tk(s, n.translate(t, !0, !n.horiz));
      return tC(i) || (o = tT(o + r)), tC(s) || (a = tT(a - r)), tM(o) && tM(a) || (o = a = void 0), {
        min: o,
        max: a
      };
    }
  }
  var tN = x(620),
    tB = x.n(tN),
    tI = x(512),
    tF = x.n(tI);
  let {
      parse: tO
    } = tB(),
    {
      seriesTypes: tR
    } = tF(),
    tL = {
      height: 40,
      margin: 25,
      maskInside: !0,
      handles: {
        width: 7,
        borderRadius: 0,
        height: 15,
        symbols: ["navigator-handle", "navigator-handle"],
        enabled: !0,
        lineWidth: 1,
        backgroundColor: "#f2f2f2",
        borderColor: "#999999"
      },
      maskFill: tO("#667aff").setOpacity(.3).get(),
      outlineColor: "#999999",
      outlineWidth: 1,
      series: {
        type: void 0 === tR.areaspline ? "line" : "areaspline",
        fillOpacity: .05,
        lineWidth: 1,
        compare: null,
        sonification: {
          enabled: !1
        },
        dataGrouping: {
          approximation: "average",
          enabled: !0,
          groupPixelWidth: 2,
          firstAnchor: "firstPoint",
          anchor: "middle",
          lastAnchor: "lastPoint",
          units: [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2, 3, 4]], ["week", [1, 2, 3]], ["month", [1, 3, 6]], ["year", null]]
        },
        dataLabels: {
          enabled: !1,
          zIndex: 2
        },
        id: "highcharts-navigator-series",
        className: "highcharts-navigator-series",
        lineColor: null,
        marker: {
          enabled: !1
        },
        threshold: null
      },
      xAxis: {
        className: "highcharts-navigator-xaxis",
        tickLength: 0,
        lineWidth: 0,
        gridLineColor: "#e6e6e6",
        id: "navigator-x-axis",
        gridLineWidth: 1,
        tickPixelInterval: 200,
        labels: {
          align: "left",
          style: {
            color: "#000000",
            fontSize: "0.7em",
            opacity: .6,
            textOutline: "2px contrast"
          },
          x: 3,
          y: -4
        },
        crosshair: !1
      },
      yAxis: {
        className: "highcharts-navigator-yaxis",
        gridLineWidth: 0,
        startOnTick: !1,
        endOnTick: !1,
        minPadding: .1,
        id: "navigator-y-axis",
        maxPadding: .1,
        labels: {
          enabled: !1
        },
        crosshair: !1,
        title: {
          text: null
        },
        tickLength: 0,
        tickWidth: 0
      }
    },
    {
      defined: tH,
      isNumber: tz,
      pick: tq
    } = w(),
    tG = {
      rect: function (e, t, i, s, n) {
        return n && n.r ? function (e, t, i, s, n) {
          let r = n?.r || 0;
          return [["M", e + r, t], ["L", e + i - r, t], ["A", r, r, 0, 0, 1, e + i, t + r], ["L", e + i, t + s - r], ["A", r, r, 0, 0, 1, e + i - r, t + s], ["L", e + r, t + s], ["A", r, r, 0, 0, 1, e, t + s - r], ["L", e, t + r], ["A", r, r, 0, 0, 1, e + r, t], ["Z"]];
        }(e, t, i, s, n) : [["M", e, t], ["L", e + i, t], ["L", e + i, t + s], ["L", e, t + s], ["Z"]];
      }
    },
    {
      relativeLength: tK
    } = w(),
    tU = {
      "navigator-handle": function (e, t, i, s, n = {}) {
        let r = n.width ? n.width / 2 : i,
          o = tK(n.borderRadius || 0, Math.min(2 * r, s));
        return [["M", -1.5, (s = n.height || s) / 2 - 3.5], ["L", -1.5, s / 2 + 4.5], ["M", .5, s / 2 - 3.5], ["L", .5, s / 2 + 4.5], ...tG.rect(-r - 1, .5, 2 * r + 1, s, {
          r: o
        })];
      }
    };
  var tV = x(608),
    tW = x.n(tV);
  let {
      defined: tX
    } = w(),
    {
      setOptions: tY
    } = w(),
    {
      composed: tj
    } = w(),
    {
      getRendererType: t_
    } = tW(),
    {
      setFixedRange: tZ
    } = {
      setFixedRange: function (e) {
        let t = this.xAxis[0];
        tX(t.dataMax) && tX(t.dataMin) && e ? this.fixedRange = Math.min(e, t.dataMax - t.dataMin) : this.fixedRange = e;
      }
    },
    {
      addEvent: t$,
      extend: tQ,
      pushUnique: tJ
    } = w();
  function t0() {
    this.chart.navigator && !this.options.isInternal && this.chart.navigator.setBaseSeries(null, !1);
  }
  let t1 = {
      compose: function (e, t, i) {
        tD.compose(t), tJ(tj, "Navigator") && (e.prototype.setFixedRange = tZ, tQ(t_().prototype.symbols, tU), t$(i, "afterUpdate", t0), tY({
          navigator: tL
        }));
      }
    },
    {
      composed: t2
    } = w(),
    {
      addEvent: t3,
      defined: t5,
      pick: t4,
      pushUnique: t6
    } = w();
  !function (e) {
    let t;
    function i(e) {
      let t = t4(e.options && e.options.min, e.min),
        i = t4(e.options && e.options.max, e.max);
      return {
        axisMin: t,
        axisMax: i,
        scrollMin: t5(e.dataMin) ? Math.min(t, e.min, e.dataMin, t4(e.threshold, 1 / 0)) : t,
        scrollMax: t5(e.dataMax) ? Math.max(i, e.max, e.dataMax, t4(e.threshold, -1 / 0)) : i
      };
    }
    function s() {
      let e = this.scrollbar,
        t = e && !e.options.opposite,
        i = this.horiz ? 2 : t ? 3 : 1;
      e && (this.chart.scrollbarsOffsets = [0, 0], this.chart.axisOffset[i] += e.size + (e.options.margin || 0));
    }
    function n() {
      let e = this;
      e.options && e.options.scrollbar && e.options.scrollbar.enabled && (e.options.scrollbar.vertical = !e.horiz, e.options.startOnTick = e.options.endOnTick = !1, e.scrollbar = new t(e.chart.renderer, e.options.scrollbar, e.chart), t3(e.scrollbar, "changed", function (t) {
        let s, n;
        let {
            axisMin: r,
            axisMax: o,
            scrollMin: a,
            scrollMax: l
          } = i(e),
          h = l - a;
        if (t5(r) && t5(o)) {
          if (e.horiz && !e.reversed || !e.horiz && e.reversed ? (s = a + h * this.to, n = a + h * this.from) : (s = a + h * (1 - this.from), n = a + h * (1 - this.to)), this.shouldUpdateExtremes(t.DOMType)) {
            let i = "mousemove" !== t.DOMType && "touchmove" !== t.DOMType && void 0;
            e.setExtremes(n, s, !0, i, t);
          } else this.setRange(this.from, this.to);
        }
      }));
    }
    function r() {
      let e, t, s;
      let {
          scrollMin: n,
          scrollMax: r
        } = i(this),
        o = this.scrollbar,
        a = this.axisTitleMargin + (this.titleOffset || 0),
        l = this.chart.scrollbarsOffsets,
        h = this.options.margin || 0;
      if (o && l) {
        if (this.horiz) this.opposite || (l[1] += a), o.position(this.left, this.top + this.height + 2 + l[1] - (this.opposite ? h : 0), this.width, this.height), this.opposite || (l[1] += h), e = 1;else {
          let t;
          this.opposite && (l[0] += a), t = o.options.opposite ? this.left + this.width + 2 + l[0] - (this.opposite ? 0 : h) : this.opposite ? 0 : h, o.position(t, this.top, this.width, this.height), this.opposite && (l[0] += h), e = 0;
        }
        if (l[e] += o.size + (o.options.margin || 0), isNaN(n) || isNaN(r) || !t5(this.min) || !t5(this.max) || this.dataMin === this.dataMax) o.setRange(0, 1);else if (this.min === this.max) {
          let e = this.pointRange / (this.dataMax + 1);
          t = e * this.min, s = e * (this.max + 1), o.setRange(t, s);
        } else t = (this.min - n) / (r - n), s = (this.max - n) / (r - n), this.horiz && !this.reversed || !this.horiz && this.reversed ? o.setRange(t, s) : o.setRange(1 - s, 1 - t);
      }
    }
    e.compose = function (e, i) {
      t6(t2, "Axis.Scrollbar") && (t = i, t3(e, "afterGetOffset", s), t3(e, "afterInit", n), t3(e, "afterRender", r));
    };
  }(g || (g = {}));
  let t9 = g,
    t8 = {
      height: 10,
      barBorderRadius: 5,
      buttonBorderRadius: 0,
      buttonsEnabled: !1,
      liveRedraw: void 0,
      margin: void 0,
      minWidth: 6,
      opposite: !0,
      step: .2,
      zIndex: 3,
      barBackgroundColor: "#cccccc",
      barBorderWidth: 0,
      barBorderColor: "#cccccc",
      buttonArrowColor: "#333333",
      buttonBackgroundColor: "#e6e6e6",
      buttonBorderColor: "#cccccc",
      buttonBorderWidth: 1,
      rifleColor: "none",
      trackBackgroundColor: "rgba(255, 255, 255, 0.001)",
      trackBorderColor: "#cccccc",
      trackBorderRadius: 5,
      trackBorderWidth: 1
    },
    {
      defaultOptions: t7
    } = w(),
    {
      addEvent: ie,
      correctFloat: it,
      crisp: ii,
      defined: is,
      destroyObjectProperties: ir,
      fireEvent: io,
      merge: ia,
      pick: il,
      removeEvent: ih
    } = w();
  class ic {
    static compose(e) {
      t9.compose(e, ic);
    }
    static swapXY(e, t) {
      return t && e.forEach(e => {
        let t;
        let i = e.length;
        for (let s = 0; s < i; s += 2) "number" == typeof (t = e[s + 1]) && (e[s + 1] = e[s + 2], e[s + 2] = t);
      }), e;
    }
    constructor(e, t, i) {
      this._events = [], this.chartX = 0, this.chartY = 0, this.from = 0, this.scrollbarButtons = [], this.scrollbarLeft = 0, this.scrollbarStrokeWidth = 1, this.scrollbarTop = 0, this.size = 0, this.to = 0, this.trackBorderWidth = 1, this.x = 0, this.y = 0, this.init(e, t, i);
    }
    addEvents() {
      let e = this.options.inverted ? [1, 0] : [0, 1],
        t = this.scrollbarButtons,
        i = this.scrollbarGroup.element,
        s = this.track.element,
        n = this.mouseDownHandler.bind(this),
        r = this.mouseMoveHandler.bind(this),
        o = this.mouseUpHandler.bind(this),
        a = [[t[e[0]].element, "click", this.buttonToMinClick.bind(this)], [t[e[1]].element, "click", this.buttonToMaxClick.bind(this)], [s, "click", this.trackClick.bind(this)], [i, "mousedown", n], [i.ownerDocument, "mousemove", r], [i.ownerDocument, "mouseup", o], [i, "touchstart", n], [i.ownerDocument, "touchmove", r], [i.ownerDocument, "touchend", o]];
      a.forEach(function (e) {
        ie.apply(null, e);
      }), this._events = a;
    }
    buttonToMaxClick(e) {
      let t = (this.to - this.from) * il(this.options.step, .2);
      this.updatePosition(this.from + t, this.to + t), io(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: e
      });
    }
    buttonToMinClick(e) {
      let t = it(this.to - this.from) * il(this.options.step, .2);
      this.updatePosition(it(this.from - t), it(this.to - t)), io(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: e
      });
    }
    cursorToScrollbarPosition(e) {
      let t = this.options,
        i = t.minWidth > this.calculatedWidth ? t.minWidth : 0;
      return {
        chartX: (e.chartX - this.x - this.xOffset) / (this.barWidth - i),
        chartY: (e.chartY - this.y - this.yOffset) / (this.barWidth - i)
      };
    }
    destroy() {
      let e = this,
        t = e.chart.scroller;
      e.removeEvents(), ["track", "scrollbarRifles", "scrollbar", "scrollbarGroup", "group"].forEach(function (t) {
        e[t] && e[t].destroy && (e[t] = e[t].destroy());
      }), t && e === t.scrollbar && (t.scrollbar = null, ir(t.scrollbarButtons));
    }
    drawScrollbarButton(e) {
      let t = this.renderer,
        i = this.scrollbarButtons,
        s = this.options,
        n = this.size,
        r = t.g().add(this.group);
      if (i.push(r), s.buttonsEnabled) {
        let o = t.rect().addClass("highcharts-scrollbar-button").add(r);
        this.chart.styledMode || o.attr({
          stroke: s.buttonBorderColor,
          "stroke-width": s.buttonBorderWidth,
          fill: s.buttonBackgroundColor
        }), o.attr(o.crisp({
          x: -.5,
          y: -.5,
          width: n,
          height: n,
          r: s.buttonBorderRadius
        }, o.strokeWidth()));
        let a = t.path(ic.swapXY([["M", n / 2 + (e ? -1 : 1), n / 2 - 3], ["L", n / 2 + (e ? -1 : 1), n / 2 + 3], ["L", n / 2 + (e ? 2 : -2), n / 2]], s.vertical)).addClass("highcharts-scrollbar-arrow").add(i[e]);
        this.chart.styledMode || a.attr({
          fill: s.buttonArrowColor
        });
      }
    }
    init(e, t, i) {
      this.scrollbarButtons = [], this.renderer = e, this.userOptions = t, this.options = ia(t8, t7.scrollbar, t), this.options.margin = il(this.options.margin, 10), this.chart = i, this.size = il(this.options.size, this.options.height), t.enabled && (this.render(), this.addEvents());
    }
    mouseDownHandler(e) {
      let t = this.chart.pointer?.normalize(e) || e,
        i = this.cursorToScrollbarPosition(t);
      this.chartX = i.chartX, this.chartY = i.chartY, this.initPositions = [this.from, this.to], this.grabbedCenter = !0;
    }
    mouseMoveHandler(e) {
      let t;
      let i = this.chart.pointer?.normalize(e) || e,
        s = this.options.vertical ? "chartY" : "chartX",
        n = this.initPositions || [];
      this.grabbedCenter && (!e.touches || 0 !== e.touches[0][s]) && (t = this.cursorToScrollbarPosition(i)[s] - this[s], this.hasDragged = !0, this.updatePosition(n[0] + t, n[1] + t), this.hasDragged && io(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMType: e.type,
        DOMEvent: e
      }));
    }
    mouseUpHandler(e) {
      this.hasDragged && io(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMType: e.type,
        DOMEvent: e
      }), this.grabbedCenter = this.hasDragged = this.chartX = this.chartY = null;
    }
    position(e, t, i, s) {
      let {
          buttonsEnabled: n,
          margin: r = 0,
          vertical: o
        } = this.options,
        a = this.rendered ? "animate" : "attr",
        l = s,
        h = 0;
      this.group.show(), this.x = e, this.y = t + this.trackBorderWidth, this.width = i, this.height = s, this.xOffset = l, this.yOffset = h, o ? (this.width = this.yOffset = i = h = this.size, this.xOffset = l = 0, this.yOffset = h = n ? this.size : 0, this.barWidth = s - (n ? 2 * i : 0), this.x = e += r) : (this.height = s = this.size, this.xOffset = l = n ? this.size : 0, this.barWidth = i - (n ? 2 * s : 0), this.y = this.y + r), this.group[a]({
        translateX: e,
        translateY: this.y
      }), this.track[a]({
        width: i,
        height: s
      }), this.scrollbarButtons[1][a]({
        translateX: o ? 0 : i - l,
        translateY: o ? s - h : 0
      });
    }
    removeEvents() {
      this._events.forEach(function (e) {
        ih.apply(null, e);
      }), this._events.length = 0;
    }
    render() {
      let e = this.renderer,
        t = this.options,
        i = this.size,
        s = this.chart.styledMode,
        n = e.g("scrollbar").attr({
          zIndex: t.zIndex
        }).hide().add();
      this.group = n, this.track = e.rect().addClass("highcharts-scrollbar-track").attr({
        r: t.trackBorderRadius || 0,
        height: i,
        width: i
      }).add(n), s || this.track.attr({
        fill: t.trackBackgroundColor,
        stroke: t.trackBorderColor,
        "stroke-width": t.trackBorderWidth
      });
      let r = this.trackBorderWidth = this.track.strokeWidth();
      this.track.attr({
        x: -ii(0, r),
        y: -ii(0, r)
      }), this.scrollbarGroup = e.g().add(n), this.scrollbar = e.rect().addClass("highcharts-scrollbar-thumb").attr({
        height: i - r,
        width: i - r,
        r: t.barBorderRadius || 0
      }).add(this.scrollbarGroup), this.scrollbarRifles = e.path(ic.swapXY([["M", -3, i / 4], ["L", -3, 2 * i / 3], ["M", 0, i / 4], ["L", 0, 2 * i / 3], ["M", 3, i / 4], ["L", 3, 2 * i / 3]], t.vertical)).addClass("highcharts-scrollbar-rifles").add(this.scrollbarGroup), s || (this.scrollbar.attr({
        fill: t.barBackgroundColor,
        stroke: t.barBorderColor,
        "stroke-width": t.barBorderWidth
      }), this.scrollbarRifles.attr({
        stroke: t.rifleColor,
        "stroke-width": 1
      })), this.scrollbarStrokeWidth = this.scrollbar.strokeWidth(), this.scrollbarGroup.translate(-ii(0, this.scrollbarStrokeWidth), -ii(0, this.scrollbarStrokeWidth)), this.drawScrollbarButton(0), this.drawScrollbarButton(1);
    }
    setRange(e, t) {
      let i, s;
      let n = this.options,
        r = n.vertical,
        o = n.minWidth,
        a = this.barWidth,
        l = !this.rendered || this.hasDragged || this.chart.navigator && this.chart.navigator.hasDragged ? "attr" : "animate";
      if (!is(a)) return;
      let h = a * Math.min(t, 1);
      i = Math.ceil(a * (e = Math.max(e, 0))), this.calculatedWidth = s = it(h - i), s < o && (i = (a - o + s) * e, s = o);
      let c = Math.floor(i + this.xOffset + this.yOffset),
        d = s / 2 - .5;
      this.from = e, this.to = t, r ? (this.scrollbarGroup[l]({
        translateY: c
      }), this.scrollbar[l]({
        height: s
      }), this.scrollbarRifles[l]({
        translateY: d
      }), this.scrollbarTop = c, this.scrollbarLeft = 0) : (this.scrollbarGroup[l]({
        translateX: c
      }), this.scrollbar[l]({
        width: s
      }), this.scrollbarRifles[l]({
        translateX: d
      }), this.scrollbarLeft = c, this.scrollbarTop = 0), s <= 12 ? this.scrollbarRifles.hide() : this.scrollbarRifles.show(), !1 === n.showFull && (e <= 0 && t >= 1 ? this.group.hide() : this.group.show()), this.rendered = !0;
    }
    shouldUpdateExtremes(e) {
      return il(this.options.liveRedraw, w().svg && !w().isTouchDevice && !this.chart.boosted) || "mouseup" === e || "touchend" === e || !is(e);
    }
    trackClick(e) {
      let t = this.chart.pointer?.normalize(e) || e,
        i = this.to - this.from,
        s = this.y + this.scrollbarTop,
        n = this.x + this.scrollbarLeft;
      this.options.vertical && t.chartY > s || !this.options.vertical && t.chartX > n ? this.updatePosition(this.from + i, this.to + i) : this.updatePosition(this.from - i, this.to - i), io(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: e
      });
    }
    update(e) {
      this.destroy(), this.init(this.chart.renderer, ia(!0, this.options, e), this.chart);
    }
    updatePosition(e, t) {
      t > 1 && (e = it(1 - it(t - e)), t = 1), e < 0 && (t = it(t - e), e = 0), this.from = e, this.to = t;
    }
  }
  ic.defaultOptions = t8, t7.scrollbar = ia(!0, ic.defaultOptions, t7.scrollbar);
  var id = x(540),
    iu = x.n(id);
  let {
      defaultOptions: ip
    } = w(),
    {
      isTouchDevice: ig
    } = w(),
    {
      prototype: {
        symbols: im
      }
    } = iu(),
    {
      addEvent: ib,
      clamp: ix,
      correctFloat: iy,
      defined: iv,
      destroyObjectProperties: iw,
      erase: iE,
      extend: iA,
      find: iT,
      fireEvent: iC,
      isArray: iM,
      isNumber: ik,
      merge: iS,
      pick: iP,
      removeEvent: iD,
      splat: iN
    } = w();
  function iB(e, ...t) {
    let i = [].filter.call(t, ik);
    if (i.length) return Math[e].apply(0, i);
  }
  class iI {
    static compose(e, t, i) {
      tw.compose(e, iI), t1.compose(e, t, i);
    }
    constructor(e) {
      this.isDirty = !1, this.scrollbarHeight = 0, this.init(e);
    }
    drawHandle(e, t, i, s) {
      let n = this.navigatorOptions.handles.height;
      this.handles[t][s](i ? {
        translateX: Math.round(this.left + this.height / 2),
        translateY: Math.round(this.top + parseInt(e, 10) + .5 - n)
      } : {
        translateX: Math.round(this.left + parseInt(e, 10)),
        translateY: Math.round(this.top + this.height / 2 - n / 2 - 1)
      });
    }
    drawOutline(e, t, i, s) {
      let n = this.navigatorOptions.maskInside,
        r = this.outline.strokeWidth(),
        o = r / 2,
        a = r % 2 / 2,
        l = this.scrollButtonSize,
        h = this.size,
        c = this.top,
        d = this.height,
        u = c - o,
        p = c + d,
        g = this.left,
        m,
        b;
      i ? (m = c + t + a, t = c + e + a, b = [["M", g + d, c - l - a], ["L", g + d, m], ["L", g, m], ["M", g, t], ["L", g + d, t], ["L", g + d, c + h + l]], n && b.push(["M", g + d, m - o], ["L", g + d, t + o])) : (g -= l, e += g + l - a, t += g + l - a, b = [["M", g, u], ["L", e, u], ["L", e, p], ["M", t, p], ["L", t, u], ["L", g + h + 2 * l, u]], n && b.push(["M", e - o, u], ["L", t + o, u])), this.outline[s]({
        d: b
      });
    }
    drawMasks(e, t, i, s) {
      let n, r, o, a;
      let l = this.left,
        h = this.top,
        c = this.height;
      i ? (o = [l, l, l], a = [h, h + e, h + t], r = [c, c, c], n = [e, t - e, this.size - t]) : (o = [l, l + e, l + t], a = [h, h, h], r = [e, t - e, this.size - t], n = [c, c, c]), this.shades.forEach((e, t) => {
        e[s]({
          x: o[t],
          y: a[t],
          width: r[t],
          height: n[t]
        });
      });
    }
    renderElements() {
      let e = this,
        t = e.navigatorOptions,
        i = t.maskInside,
        s = e.chart,
        n = s.inverted,
        r = s.renderer,
        o = {
          cursor: n ? "ns-resize" : "ew-resize"
        },
        a = e.navigatorGroup ?? (e.navigatorGroup = r.g("navigator").attr({
          zIndex: 8,
          visibility: "hidden"
        }).add());
      if ([!i, i, !i].forEach((i, n) => {
        let l = e.shades[n] ?? (e.shades[n] = r.rect().addClass("highcharts-navigator-mask" + (1 === n ? "-inside" : "-outside")).add(a));
        s.styledMode || (l.attr({
          fill: i ? t.maskFill : "rgba(0,0,0,0)"
        }), 1 === n && l.css(o));
      }), e.outline || (e.outline = r.path().addClass("highcharts-navigator-outline").add(a)), s.styledMode || e.outline.attr({
        "stroke-width": t.outlineWidth,
        stroke: t.outlineColor
      }), t.handles?.enabled) {
        let i = t.handles,
          {
            height: n,
            width: l
          } = i;
        [0, 1].forEach(t => {
          let h = i.symbols[t];
          if (e.handles[t] && e.handles[t].symbolUrl === h) {
            if (!e.handles[t].isImg && e.handles[t].symbolName !== h) {
              let i = im[h].call(im, -l / 2 - 1, 0, l, n);
              e.handles[t].attr({
                d: i
              }), e.handles[t].symbolName = h;
            }
          } else e.handles[t]?.destroy(), e.handles[t] = r.symbol(h, -l / 2 - 1, 0, l, n, i), e.handles[t].attr({
            zIndex: 7 - t
          }).addClass("highcharts-navigator-handle highcharts-navigator-handle-" + ["left", "right"][t]).add(a), e.addMouseEvents();
          s.inverted && e.handles[t].attr({
            rotation: 90,
            rotationOriginX: Math.floor(-l / 2),
            rotationOriginY: (n + l) / 2
          }), s.styledMode || e.handles[t].attr({
            fill: i.backgroundColor,
            stroke: i.borderColor,
            "stroke-width": i.lineWidth,
            width: i.width,
            height: i.height,
            x: -l / 2 - 1,
            y: 0
          }).css(o);
        });
      }
    }
    update(e, t = !1) {
      let i = this.chart,
        s = i.options.chart.inverted !== i.scrollbar?.options.vertical;
      if (iS(!0, i.options.navigator, e), this.navigatorOptions = i.options.navigator || {}, this.setOpposite(), iv(e.enabled) || s) return this.destroy(), this.navigatorEnabled = e.enabled || this.navigatorEnabled, this.init(i);
      if (this.navigatorEnabled && (this.isDirty = !0, !1 === e.adaptToUpdatedData && this.baseSeries.forEach(e => {
        iD(e, "updatedData", this.updatedDataHandler);
      }, this), e.adaptToUpdatedData && this.baseSeries.forEach(e => {
        e.eventsToUnbind.push(ib(e, "updatedData", this.updatedDataHandler));
      }, this), (e.series || e.baseSeries) && this.setBaseSeries(void 0, !1), e.height || e.xAxis || e.yAxis)) {
        this.height = e.height ?? this.height;
        let t = this.getXAxisOffsets();
        this.xAxis.update({
          ...e.xAxis,
          offsets: t,
          [i.inverted ? "width" : "height"]: this.height,
          [i.inverted ? "height" : "width"]: void 0
        }, !1), this.yAxis.update({
          ...e.yAxis,
          [i.inverted ? "width" : "height"]: this.height
        }, !1);
      }
      t && i.redraw();
    }
    render(e, t, i, s) {
      let n = this.chart,
        r = this.xAxis,
        o = r.pointRange || 0,
        a = r.navigatorAxis.fake ? n.xAxis[0] : r,
        l = this.navigatorEnabled,
        h = this.rendered,
        c = n.inverted,
        d = n.xAxis[0].minRange,
        u = n.xAxis[0].options.maxRange,
        p = this.scrollButtonSize,
        g,
        m,
        b,
        f = this.scrollbarHeight,
        x,
        y;
      if (this.hasDragged && !iv(i)) return;
      if (this.isDirty && this.renderElements(), e = iy(e - o / 2), t = iy(t + o / 2), !ik(e) || !ik(t)) {
        if (!h) return;
        i = 0, s = iP(r.width, a.width);
      }
      this.left = iP(r.left, n.plotLeft + p + (c ? n.plotWidth : 0));
      let v = this.size = x = iP(r.len, (c ? n.plotHeight : n.plotWidth) - 2 * p);
      g = c ? f : x + 2 * p, i = iP(i, r.toPixels(e, !0)), s = iP(s, r.toPixels(t, !0)), ik(i) && Math.abs(i) !== 1 / 0 || (i = 0, s = g);
      let w = r.toValue(i, !0),
        E = r.toValue(s, !0),
        A = Math.abs(iy(E - w));
      A < d ? this.grabbedLeft ? i = r.toPixels(E - d - o, !0) : this.grabbedRight && (s = r.toPixels(w + d + o, !0)) : iv(u) && iy(A - o) > u && (this.grabbedLeft ? i = r.toPixels(E - u - o, !0) : this.grabbedRight && (s = r.toPixels(w + u + o, !0))), this.zoomedMax = ix(Math.max(i, s), 0, v), this.zoomedMin = ix(this.fixedWidth ? this.zoomedMax - this.fixedWidth : Math.min(i, s), 0, v), this.range = this.zoomedMax - this.zoomedMin, v = Math.round(this.zoomedMax);
      let T = Math.round(this.zoomedMin);
      l && (this.navigatorGroup.attr({
        visibility: "inherit"
      }), y = h && !this.hasDragged ? "animate" : "attr", this.drawMasks(T, v, c, y), this.drawOutline(T, v, c, y), this.navigatorOptions.handles.enabled && (this.drawHandle(T, 0, c, y), this.drawHandle(v, 1, c, y))), this.scrollbar && (c ? (b = this.top - p, m = this.left - f + (l || !a.opposite ? 0 : (a.titleOffset || 0) + a.axisTitleMargin), f = x + 2 * p) : (b = this.top + (l ? this.height : -f), m = this.left - p), this.scrollbar.position(m, b, g, f), this.scrollbar.setRange(this.zoomedMin / (x || 1), this.zoomedMax / (x || 1))), this.rendered = !0, this.isDirty = !1, iC(this, "afterRender");
    }
    addMouseEvents() {
      let e = this,
        t = e.chart,
        i = t.container,
        s = [],
        n,
        r;
      e.mouseMoveHandler = n = function (t) {
        e.onMouseMove(t);
      }, e.mouseUpHandler = r = function (t) {
        e.onMouseUp(t);
      }, (s = e.getPartsEvents("mousedown")).push(ib(t.renderTo, "mousemove", n), ib(i.ownerDocument, "mouseup", r), ib(t.renderTo, "touchmove", n), ib(i.ownerDocument, "touchend", r)), s.concat(e.getPartsEvents("touchstart")), e.eventsToUnbind = s, e.series && e.series[0] && s.push(ib(e.series[0].xAxis, "foundExtremes", function () {
        t.navigator.modifyNavigatorAxisExtremes();
      }));
    }
    getPartsEvents(e) {
      let t = this,
        i = [];
      return ["shades", "handles"].forEach(function (s) {
        t[s].forEach(function (n, r) {
          i.push(ib(n.element, e, function (e) {
            t[s + "Mousedown"](e, r);
          }));
        });
      }), i;
    }
    shadesMousedown(e, t) {
      e = this.chart.pointer?.normalize(e) || e;
      let i = this.chart,
        s = this.xAxis,
        n = this.zoomedMin,
        r = this.size,
        o = this.range,
        a = this.left,
        l = e.chartX,
        h,
        c,
        d,
        u;
      i.inverted && (l = e.chartY, a = this.top), 1 === t ? (this.grabbedCenter = l, this.fixedWidth = o, this.dragOffset = l - n) : (u = l - a - o / 2, 0 === t ? u = Math.max(0, u) : 2 === t && u + o >= r && (u = r - o, this.reversedExtremes ? (u -= o, c = this.getUnionExtremes().dataMin) : h = this.getUnionExtremes().dataMax), u !== n && (this.fixedWidth = o, iv((d = s.navigatorAxis.toFixedRange(u, u + o, c, h)).min) && iC(this, "setRange", {
        min: Math.min(d.min, d.max),
        max: Math.max(d.min, d.max),
        redraw: !0,
        eventArguments: {
          trigger: "navigator"
        }
      })));
    }
    handlesMousedown(e, t) {
      e = this.chart.pointer?.normalize(e) || e;
      let i = this.chart,
        s = i.xAxis[0],
        n = this.reversedExtremes;
      0 === t ? (this.grabbedLeft = !0, this.otherHandlePos = this.zoomedMax, this.fixedExtreme = n ? s.min : s.max) : (this.grabbedRight = !0, this.otherHandlePos = this.zoomedMin, this.fixedExtreme = n ? s.max : s.min), i.setFixedRange(void 0);
    }
    onMouseMove(e) {
      let t = this,
        i = t.chart,
        s = t.navigatorSize,
        n = t.range,
        r = t.dragOffset,
        o = i.inverted,
        a = t.left,
        l;
      (!e.touches || 0 !== e.touches[0].pageX) && (l = (e = i.pointer?.normalize(e) || e).chartX, o && (a = t.top, l = e.chartY), t.grabbedLeft ? (t.hasDragged = !0, t.render(0, 0, l - a, t.otherHandlePos)) : t.grabbedRight ? (t.hasDragged = !0, t.render(0, 0, t.otherHandlePos, l - a)) : t.grabbedCenter && (t.hasDragged = !0, l < r ? l = r : l > s + r - n && (l = s + r - n), t.render(0, 0, l - r, l - r + n)), t.hasDragged && t.scrollbar && iP(t.scrollbar.options.liveRedraw, !ig && !this.chart.boosted) && (e.DOMType = e.type, setTimeout(function () {
        t.onMouseUp(e);
      }, 0)));
    }
    onMouseUp(e) {
      let t, i, s, n, r, o;
      let a = this.chart,
        l = this.xAxis,
        h = this.scrollbar,
        c = e.DOMEvent || e,
        d = a.inverted,
        u = this.rendered && !this.hasDragged ? "animate" : "attr";
      (this.hasDragged && (!h || !h.hasDragged) || "scrollbar" === e.trigger) && (s = this.getUnionExtremes(), this.zoomedMin === this.otherHandlePos ? n = this.fixedExtreme : this.zoomedMax === this.otherHandlePos && (r = this.fixedExtreme), this.zoomedMax === this.size && (r = this.reversedExtremes ? s.dataMin : s.dataMax), 0 === this.zoomedMin && (n = this.reversedExtremes ? s.dataMax : s.dataMin), iv((o = l.navigatorAxis.toFixedRange(this.zoomedMin, this.zoomedMax, n, r)).min) && iC(this, "setRange", {
        min: Math.min(o.min, o.max),
        max: Math.max(o.min, o.max),
        redraw: !0,
        animation: !this.hasDragged && null,
        eventArguments: {
          trigger: "navigator",
          triggerOp: "navigator-drag",
          DOMEvent: c
        }
      })), "mousemove" !== e.DOMType && "touchmove" !== e.DOMType && (this.grabbedLeft = this.grabbedRight = this.grabbedCenter = this.fixedWidth = this.fixedExtreme = this.otherHandlePos = this.hasDragged = this.dragOffset = null), this.navigatorEnabled && ik(this.zoomedMin) && ik(this.zoomedMax) && (i = Math.round(this.zoomedMin), t = Math.round(this.zoomedMax), this.shades && this.drawMasks(i, t, d, u), this.outline && this.drawOutline(i, t, d, u), this.navigatorOptions.handles.enabled && Object.keys(this.handles).length === this.handles.length && (this.drawHandle(i, 0, d, u), this.drawHandle(t, 1, d, u)));
    }
    removeEvents() {
      this.eventsToUnbind && (this.eventsToUnbind.forEach(function (e) {
        e();
      }), this.eventsToUnbind = void 0), this.removeBaseSeriesEvents();
    }
    removeBaseSeriesEvents() {
      let e = this.baseSeries || [];
      this.navigatorEnabled && e[0] && (!1 !== this.navigatorOptions.adaptToUpdatedData && e.forEach(function (e) {
        iD(e, "updatedData", this.updatedDataHandler);
      }, this), e[0].xAxis && iD(e[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes));
    }
    getXAxisOffsets() {
      return this.chart.inverted ? [this.scrollButtonSize, 0, -this.scrollButtonSize, 0] : [0, -this.scrollButtonSize, 0, this.scrollButtonSize];
    }
    init(e) {
      let t = e.options,
        i = t.navigator || {},
        s = i.enabled,
        n = t.scrollbar || {},
        r = n.enabled,
        o = s && i.height || 0,
        a = r && n.height || 0,
        l = n.buttonsEnabled && a || 0;
      this.handles = [], this.shades = [], this.chart = e, this.setBaseSeries(), this.height = o, this.scrollbarHeight = a, this.scrollButtonSize = l, this.scrollbarEnabled = r, this.navigatorEnabled = s, this.navigatorOptions = i, this.scrollbarOptions = n, this.setOpposite();
      let h = this,
        c = h.baseSeries,
        d = e.xAxis.length,
        u = e.yAxis.length,
        p = c && c[0] && c[0].xAxis || e.xAxis[0] || {
          options: {}
        };
      if (e.isDirtyBox = !0, h.navigatorEnabled) {
        let t = this.getXAxisOffsets();
        h.xAxis = new (tl())(e, iS({
          breaks: p.options.breaks,
          ordinal: p.options.ordinal,
          overscroll: p.options.overscroll
        }, i.xAxis, {
          type: "datetime",
          yAxis: i.yAxis?.id,
          index: d,
          isInternal: !0,
          offset: 0,
          keepOrdinalPadding: !0,
          startOnTick: !1,
          endOnTick: !1,
          minPadding: p.options.ordinal ? 0 : p.options.minPadding,
          maxPadding: p.options.ordinal ? 0 : p.options.maxPadding,
          zoomEnabled: !1
        }, e.inverted ? {
          offsets: t,
          width: o
        } : {
          offsets: t,
          height: o
        }), "xAxis"), h.yAxis = new (tl())(e, iS(i.yAxis, {
          alignTicks: !1,
          offset: 0,
          index: u,
          isInternal: !0,
          reversed: iP(i.yAxis && i.yAxis.reversed, e.yAxis[0] && e.yAxis[0].reversed, !1),
          zoomEnabled: !1
        }, e.inverted ? {
          width: o
        } : {
          height: o
        }), "yAxis"), c || i.series.data ? h.updateNavigatorSeries(!1) : 0 === e.series.length && (h.unbindRedraw = ib(e, "beforeRedraw", function () {
          e.series.length > 0 && !h.series && (h.setBaseSeries(), h.unbindRedraw());
        })), h.reversedExtremes = e.inverted && !h.xAxis.reversed || !e.inverted && h.xAxis.reversed, h.renderElements(), h.addMouseEvents();
      } else h.xAxis = {
        chart: e,
        navigatorAxis: {
          fake: !0
        },
        translate: function (t, i) {
          let s = e.xAxis[0],
            n = s.getExtremes(),
            r = s.len - 2 * l,
            o = iB("min", s.options.min, n.dataMin),
            a = iB("max", s.options.max, n.dataMax) - o;
          return i ? t * a / r + o : r * (t - o) / a;
        },
        toPixels: function (e) {
          return this.translate(e);
        },
        toValue: function (e) {
          return this.translate(e, !0);
        }
      }, h.xAxis.navigatorAxis.axis = h.xAxis, h.xAxis.navigatorAxis.toFixedRange = tD.prototype.toFixedRange.bind(h.xAxis.navigatorAxis);
      if (e.options.scrollbar.enabled) {
        let t = iS(e.options.scrollbar, {
          vertical: e.inverted
        });
        !ik(t.margin) && h.navigatorEnabled && (t.margin = e.inverted ? -3 : 3), e.scrollbar = h.scrollbar = new ic(e.renderer, t, e), ib(h.scrollbar, "changed", function (e) {
          let t = h.size,
            i = t * this.to,
            s = t * this.from;
          h.hasDragged = h.scrollbar.hasDragged, h.render(0, 0, s, i), this.shouldUpdateExtremes(e.DOMType) && setTimeout(function () {
            h.onMouseUp(e);
          });
        });
      }
      h.addBaseSeriesEvents(), h.addChartEvents();
    }
    setOpposite() {
      let e = this.navigatorOptions,
        t = this.navigatorEnabled,
        i = this.chart;
      this.opposite = iP(e.opposite, !!(!t && i.inverted));
    }
    getUnionExtremes(e) {
      let t;
      let i = this.chart.xAxis[0],
        s = this.chart.time,
        n = this.xAxis,
        r = n.options,
        o = i.options;
      return e && null === i.dataMin || (t = {
        dataMin: iP(s.parse(r?.min), iB("min", s.parse(o.min), i.dataMin, n.dataMin, n.min)),
        dataMax: iP(s.parse(r?.max), iB("max", s.parse(o.max), i.dataMax, n.dataMax, n.max))
      }), t;
    }
    setBaseSeries(e, t) {
      let i = this.chart,
        s = this.baseSeries = [];
      e = e || i.options && i.options.navigator.baseSeries || (i.series.length ? iT(i.series, e => !e.options.isInternal).index : 0), (i.series || []).forEach((t, i) => {
        !t.options.isInternal && (t.options.showInNavigator || (i === e || t.options.id === e) && !1 !== t.options.showInNavigator) && s.push(t);
      }), this.xAxis && !this.xAxis.navigatorAxis.fake && this.updateNavigatorSeries(!0, t);
    }
    updateNavigatorSeries(e, t) {
      let i = this,
        s = i.chart,
        n = i.baseSeries,
        r = {
          enableMouseTracking: !1,
          index: null,
          linkedTo: null,
          group: "nav",
          padXAxis: !1,
          xAxis: this.navigatorOptions.xAxis?.id,
          yAxis: this.navigatorOptions.yAxis?.id,
          showInLegend: !1,
          stacking: void 0,
          isInternal: !0,
          states: {
            inactive: {
              opacity: 1
            }
          }
        },
        o = i.series = (i.series || []).filter(e => {
          let t = e.baseSeries;
          return !(0 > n.indexOf(t)) || (t && (iD(t, "updatedData", i.updatedDataHandler), delete t.navigatorSeries), e.chart && e.destroy(), !1);
        }),
        a,
        l,
        h = i.navigatorOptions.series,
        c;
      n && n.length && n.forEach(e => {
        let d = e.navigatorSeries,
          u = iA({
            color: e.color,
            visible: e.visible
          }, iM(h) ? ip.navigator.series : h);
        if (d && !1 === i.navigatorOptions.adaptToUpdatedData) return;
        r.name = "Navigator " + n.length, c = (a = e.options || {}).navigatorOptions || {}, u.dataLabels = iN(u.dataLabels), (l = iS(a, r, u, c)).pointRange = iP(u.pointRange, c.pointRange, ip.plotOptions[l.type || "line"].pointRange);
        let p = c.data || u.data;
        i.hasNavigatorData = i.hasNavigatorData || !!p, l.data = p || a.data?.slice(0), d && d.options ? d.update(l, t) : (e.navigatorSeries = s.initSeries(l), s.setSortedData(), e.navigatorSeries.baseSeries = e, o.push(e.navigatorSeries));
      }), (h.data && !(n && n.length) || iM(h)) && (i.hasNavigatorData = !1, (h = iN(h)).forEach((e, t) => {
        r.name = "Navigator " + (o.length + 1), (l = iS(ip.navigator.series, {
          color: s.series[t] && !s.series[t].options.isInternal && s.series[t].color || s.options.colors[t] || s.options.colors[0]
        }, r, e)).data = e.data, l.data && (i.hasNavigatorData = !0, o.push(s.initSeries(l)));
      })), e && this.addBaseSeriesEvents();
    }
    addBaseSeriesEvents() {
      let e = this,
        t = e.baseSeries || [];
      t[0] && t[0].xAxis && t[0].eventsToUnbind.push(ib(t[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes)), t.forEach(i => {
        i.eventsToUnbind.push(ib(i, "show", function () {
          this.navigatorSeries && this.navigatorSeries.setVisible(!0, !1);
        })), i.eventsToUnbind.push(ib(i, "hide", function () {
          this.navigatorSeries && this.navigatorSeries.setVisible(!1, !1);
        })), !1 !== this.navigatorOptions.adaptToUpdatedData && i.xAxis && i.eventsToUnbind.push(ib(i, "updatedData", this.updatedDataHandler)), i.eventsToUnbind.push(ib(i, "remove", function () {
          t && iE(t, i), this.navigatorSeries && e.series && (iE(e.series, this.navigatorSeries), iv(this.navigatorSeries.options) && this.navigatorSeries.remove(!1), delete this.navigatorSeries);
        }));
      });
    }
    getBaseSeriesMin(e) {
      return this.baseSeries.reduce(function (e, t) {
        return Math.min(e, t.getColumn("x")[0] ?? e);
      }, e);
    }
    modifyNavigatorAxisExtremes() {
      let e = this.xAxis;
      if (void 0 !== e.getExtremes) {
        let t = this.getUnionExtremes(!0);
        t && (t.dataMin !== e.min || t.dataMax !== e.max) && (e.min = t.dataMin, e.max = t.dataMax);
      }
    }
    modifyBaseAxisExtremes() {
      let e, t;
      let i = this.chart.navigator,
        s = this.getExtremes(),
        n = s.min,
        r = s.max,
        o = s.dataMin,
        a = s.dataMax,
        l = r - n,
        h = i.stickToMin,
        c = i.stickToMax,
        d = iP(this.ordinal?.convertOverscroll(this.options.overscroll), 0),
        u = i.series && i.series[0],
        p = !!this.setExtremes;
      !(this.eventArgs && "rangeSelectorButton" === this.eventArgs.trigger) && (h && (e = (t = o) + l), c && (e = a + d, h || (t = Math.max(o, e - l, i.getBaseSeriesMin(u && u.xData ? u.xData[0] : -Number.MAX_VALUE)))), p && (h || c) && ik(t) && (this.min = this.userMin = t, this.max = this.userMax = e)), i.stickToMin = i.stickToMax = null;
    }
    updatedDataHandler() {
      let e = this.chart.navigator,
        t = this.navigatorSeries,
        i = e.reversedExtremes ? 0 === Math.round(e.zoomedMin) : Math.round(e.zoomedMax) >= Math.round(e.size);
      e.stickToMax = iP(this.chart.options.navigator && this.chart.options.navigator.stickToMax, i), e.stickToMin = e.shouldStickToMin(this, e), t && !e.hasNavigatorData && (t.options.pointStart = this.getColumn("x")[0], t.setData(this.options.data, !1, null, !1));
    }
    shouldStickToMin(e, t) {
      let i = t.getBaseSeriesMin(e.getColumn("x")[0]),
        s = e.xAxis,
        n = s.max,
        r = s.min,
        o = s.options.range;
      return !!(ik(n) && ik(r)) && (o && n - i > 0 ? n - i < o : r <= i);
    }
    addChartEvents() {
      this.eventsToUnbind || (this.eventsToUnbind = []), this.eventsToUnbind.push(ib(this.chart, "redraw", function () {
        let e = this.navigator,
          t = e && (e.baseSeries && e.baseSeries[0] && e.baseSeries[0].xAxis || this.xAxis[0]);
        t && e.render(t.min, t.max);
      }), ib(this.chart, "getMargins", function () {
        let e = this.navigator,
          t = e.opposite ? "plotTop" : "marginBottom";
        this.inverted && (t = e.opposite ? "marginRight" : "plotLeft"), this[t] = (this[t] || 0) + (e.navigatorEnabled || !this.inverted ? e.height + e.scrollbarHeight : 0) + e.navigatorOptions.margin;
      }), ib(iI, "setRange", function (e) {
        this.chart.xAxis[0].setExtremes(e.min, e.max, e.redraw, e.animation, e.eventArguments);
      }));
    }
    destroy() {
      this.removeEvents(), this.xAxis && (iE(this.chart.xAxis, this.xAxis), iE(this.chart.axes, this.xAxis)), this.yAxis && (iE(this.chart.yAxis, this.yAxis), iE(this.chart.axes, this.yAxis)), (this.series || []).forEach(e => {
        e.destroy && e.destroy();
      }), ["series", "xAxis", "yAxis", "shades", "outline", "scrollbarTrack", "scrollbarRifles", "scrollbarGroup", "scrollbar", "navigatorGroup", "rendered"].forEach(e => {
        this[e] && this[e].destroy && this[e].destroy(), this[e] = null;
      }), [this.handles].forEach(e => {
        iw(e);
      }), this.navigatorEnabled = !1;
    }
  }
  let {
      animObject: iF
    } = w(),
    {
      format: iO
    } = D(),
    {
      clamp: iR,
      pick: iL,
      syncTimeout: iH
    } = w(),
    {
      getFakeMouseEvent: iz
    } = S,
    {
      getAxisRangeDescription: iq,
      fireEventOnWrappedOrUnwrappedElement: iG
    } = K,
    iK = class extends Z {
      init() {
        let e = this.chart,
          t = this;
        this.announcer = new ep(e, "polite"), this.addEvent(iI, "afterRender", function () {
          this.chart === t.chart && this.chart.renderer && iH(() => {
            t.proxyProvider.updateGroupProxyElementPositions("navigator"), t.updateHandleValues();
          }, iF(iL(this.chart.renderer.globalAnimation, !0)).duration);
        });
      }
      onChartUpdate() {
        let e = this.chart,
          t = e.options,
          i = t.navigator;
        if (i.enabled && i.accessibility?.enabled) {
          let i = t.accessibility.landmarkVerbosity,
            s = t.lang.accessibility?.navigator.groupLabel;
          this.proxyProvider.removeGroup("navigator"), this.proxyProvider.addGroup("navigator", "div", {
            role: "all" === i ? "region" : "group",
            "aria-label": iO(s, {
              chart: e
            }, e)
          });
          let n = t.lang.accessibility?.navigator.handleLabel;
          [0, 1].forEach(t => {
            let i = this.getHandleByIx(t);
            if (i) {
              let s = this.proxyProvider.addProxyElement("navigator", {
                click: i
              }, "input", {
                type: "range",
                "aria-label": iO(n, {
                  handleIx: t,
                  chart: e
                }, e)
              });
              this[t ? "maxHandleProxy" : "minHandleProxy"] = s.innerElement, s.innerElement.style.pointerEvents = "none", s.innerElement.oninput = () => this.updateNavigator();
            }
          }), this.updateHandleValues();
        } else this.proxyProvider.removeGroup("navigator");
      }
      getNavigatorHandleNavigation(e) {
        let t = this,
          i = this.chart,
          s = e ? this.maxHandleProxy : this.minHandleProxy,
          n = this.keyCodes;
        return new Q(i, {
          keyCodeMap: [[[n.left, n.right, n.up, n.down], function (r) {
            if (s) {
              let o = r === n.left || r === n.up ? -1 : 1;
              s.value = "" + iR(parseFloat(s.value) + o, 0, 100), t.updateNavigator(() => {
                let n = t.getHandleByIx(e);
                n && i.setFocusToElement(n, s);
              });
            }
            return this.response.success;
          }]],
          init: () => {
            i.setFocusToElement(this.getHandleByIx(e), s);
          },
          validate: () => !!(this.getHandleByIx(e) && s && i.options.navigator.accessibility?.enabled)
        });
      }
      getKeyboardNavigation() {
        return [this.getNavigatorHandleNavigation(0), this.getNavigatorHandleNavigation(1)];
      }
      destroy() {
        this.updateNavigatorThrottleTimer && clearTimeout(this.updateNavigatorThrottleTimer), this.proxyProvider.removeGroup("navigator"), this.announcer && this.announcer.destroy();
      }
      updateHandleValues() {
        let e = this.chart.navigator;
        if (e && this.minHandleProxy && this.maxHandleProxy) {
          let t = e.size;
          this.minHandleProxy.value = "" + Math.round(e.zoomedMin / t * 100), this.maxHandleProxy.value = "" + Math.round(e.zoomedMax / t * 100);
        }
      }
      getHandleByIx(e) {
        let t = this.chart.navigator;
        return t && t.handles && t.handles[e];
      }
      updateNavigator(e) {
        this.updateNavigatorThrottleTimer && clearTimeout(this.updateNavigatorThrottleTimer), this.updateNavigatorThrottleTimer = setTimeout((e => {
          let t = this.chart,
            {
              navigator: i,
              pointer: s
            } = t;
          if (i && s && this.minHandleProxy && this.maxHandleProxy) {
            let n = s.getChartPosition(),
              r = parseFloat(this.minHandleProxy.value) / 100 * i.size,
              o = parseFloat(this.maxHandleProxy.value) / 100 * i.size;
            [[0, "mousedown", i.zoomedMin], [0, "mousemove", r], [0, "mouseup", r], [1, "mousedown", i.zoomedMax], [1, "mousemove", o], [1, "mouseup", o]].forEach(([e, t, s]) => {
              let r = this.getHandleByIx(e)?.element;
              r && iG(r, iz(t, {
                x: n.left + i.left + s,
                y: n.top + i.top
              }, r));
            }), e && e();
            let a = t.options.lang.accessibility?.navigator.changeAnnouncement,
              l = iq(t.xAxis[0]);
            this.announcer.announce(iO(a, {
              axisRangeDescription: l,
              chart: t
            }, t));
          }
        }).bind(this, e), 20);
      }
    },
    {
      getPointAnnotationTexts: iU
    } = ev,
    {
      getAxisDescription: iV,
      getSeriesFirstPointElement: iW,
      getSeriesA11yElement: iX,
      unhideChartElementFromAT: iY
    } = K,
    {
      format: ij,
      numberFormat: i_
    } = D(),
    {
      reverseChildNodes: iZ,
      stripHTMLTagsFromString: i$
    } = S,
    {
      find: iQ,
      isNumber: iJ,
      isString: i0,
      pick: i1,
      defined: i2
    } = w();
  function i3(e) {
    let t = e.chart.options.accessibility.series.pointDescriptionEnabledThreshold;
    return !!(!1 !== t && e.points && e.points.length >= +t);
  }
  function i5(e, t) {
    let i = e.series,
      s = i.chart,
      n = s.options.accessibility.point || {},
      r = i.options.accessibility && i.options.accessibility.point || {},
      o = i.tooltipOptions || {},
      a = s.options.lang;
    return iJ(t) ? i_(t, r.valueDecimals || n.valueDecimals || o.valueDecimals || -1, a.decimalPoint, a.accessibility.thousandsSep || a.thousandsSep) : t;
  }
  function i4(e, t) {
    let i = e[t];
    return e.chart.langFormat("accessibility.series." + t + "Description", {
      name: iV(i),
      series: e
    });
  }
  function i6(e) {
    let t = e.series,
      i = t.chart.series.length > 1 || t.options.name,
      s = function (e) {
        let t = e.series,
          i = t.chart,
          s = t.options.accessibility,
          n = s && s.point && s.point.valueDescriptionFormat || i.options.accessibility.point.valueDescriptionFormat,
          r = i1(t.xAxis && t.xAxis.options.accessibility && t.xAxis.options.accessibility.enabled, !i.angular && "flowmap" !== t.type),
          o = r ? function (e) {
            let t = function (e) {
                let t = e.series,
                  i = t.chart,
                  s = t.options.accessibility && t.options.accessibility.point || {},
                  n = i.options.accessibility.point || {},
                  r = t.xAxis && t.xAxis.dateTime;
                if (r) {
                  let t = r.getXDateFormat(e.x || 0, i.options.tooltip.dateTimeLabelFormats),
                    o = s.dateFormatter && s.dateFormatter(e) || n.dateFormatter && n.dateFormatter(e) || s.dateFormat || n.dateFormat || t;
                  return i.time.dateFormat(o, e.x || 0, void 0);
                }
              }(e),
              i = (e.series.xAxis || {}).categories && i2(e.category) && ("" + e.category).replace("<br/>", " "),
              s = i2(e.id) && 0 > ("" + e.id).indexOf("highcharts-"),
              n = "x, " + e.x;
            return e.name || t || i || (s ? e.id : n);
          }(e) : "";
        return ij(n, {
          point: e,
          index: i2(e.index) ? e.index + 1 : "",
          xDescription: o,
          value: function (e) {
            let t = e.series,
              i = t.chart.options.accessibility.point || {},
              s = t.chart.options.accessibility && t.chart.options.accessibility.point || {},
              n = t.tooltipOptions || {},
              r = s.valuePrefix || i.valuePrefix || n.valuePrefix || "",
              o = s.valueSuffix || i.valueSuffix || n.valueSuffix || "",
              a = void 0 !== e.value ? "value" : "y",
              l = i5(e, e[a]);
            return e.isNull ? t.chart.langFormat("accessibility.series.nullPointValue", {
              point: e
            }) : t.pointArrayMap ? function (e, t, i) {
              let s = t || "",
                n = i || "",
                r = function (t) {
                  let i = i5(e, i1(e[t], e.options[t]));
                  return void 0 !== i ? t + ": " + s + i + n : i;
                };
              return e.series.pointArrayMap.reduce(function (e, t) {
                let i = r(t);
                return i ? e + (e.length ? ", " : "") + i : e;
              }, "");
            }(e, r, o) : r + l + o;
          }(e),
          separator: r ? ", " : ""
        }, i);
      }(e),
      n = e.options && e.options.accessibility && e.options.accessibility.description,
      r = i ? " " + t.name + "." : "",
      o = function (e) {
        let t = e.series.chart,
          i = iU(e);
        return i.length ? t.langFormat("accessibility.series.pointAnnotationsDescription", {
          point: e,
          annotations: i
        }) : "";
      }(e);
    return e.accessibility = e.accessibility || {}, e.accessibility.valueDescription = s, s + (n ? " " + n : "") + r + (o ? " " + o : "");
  }
  function i9(e) {
    let t = e.chart,
      i = t.types || [],
      s = function (e) {
        let t = (e.options.accessibility || {}).description;
        return t && e.chart.langFormat("accessibility.series.description", {
          description: t,
          series: e
        }) || "";
      }(e),
      n = function (i) {
        return t[i] && t[i].length > 1 && e[i];
      },
      r = e.index + 1,
      o = i4(e, "xAxis"),
      a = i4(e, "yAxis"),
      l = {
        seriesNumber: r,
        series: e,
        chart: t
      },
      h = i.length > 1 ? "Combination" : "",
      c = t.langFormat("accessibility.series.summary." + e.type + h, l) || t.langFormat("accessibility.series.summary.default" + h, l),
      d = (n("yAxis") ? " " + a + "." : "") + (n("xAxis") ? " " + o + "." : "");
    return ij(i1(e.options.accessibility && e.options.accessibility.descriptionFormat, t.options.accessibility.series.descriptionFormat, ""), {
      seriesDescription: c,
      authorDescription: s ? " " + s : "",
      axisDescription: d,
      series: e,
      chart: t,
      seriesNumber: r
    }, void 0);
  }
  let i8 = {
      defaultPointDescriptionFormatter: i6,
      defaultSeriesDescriptionFormatter: i9,
      describeSeries: function (e) {
        let t = e.chart,
          i = iW(e),
          s = iX(e),
          n = t.is3d && t.is3d();
        s && (s.lastChild !== i || n || iZ(s), function (e) {
          let t = function (e) {
              let t = e.options.accessibility || {};
              return !i3(e) && !t.exposeAsGroupOnly;
            }(e),
            i = function (e) {
              let t = e.chart.options.accessibility.keyboardNavigation.seriesNavigation;
              return !!(e.points && (e.points.length < +t.pointNavigationEnabledThreshold || !1 === t.pointNavigationEnabledThreshold));
            }(e),
            s = e.chart.options.accessibility.point.describeNull;
          (t || i) && e.points.forEach(i => {
            let n = i.graphic && i.graphic.element || function (e) {
                let t = e.series,
                  i = t && t.chart,
                  s = t && t.is("sunburst"),
                  n = e.isNull,
                  r = i && i.options.accessibility.point.describeNull;
                return n && !s && r;
              }(i) && function (e) {
                let t = e.series,
                  i = function (e) {
                    let t = e.index;
                    return e.series && e.series.data && i2(t) && iQ(e.series.data, function (e) {
                      return !!(e && void 0 !== e.index && e.index > t && e.graphic && e.graphic.element);
                    }) || null;
                  }(e),
                  s = i && i.graphic,
                  n = s ? s.parentGroup : t.graph || t.group,
                  r = i ? {
                    x: i1(e.plotX, i.plotX, 0),
                    y: i1(e.plotY, i.plotY, 0)
                  } : {
                    x: i1(e.plotX, 0),
                    y: i1(e.plotY, 0)
                  },
                  o = function (e, t) {
                    let i = e.series.chart.renderer.rect(t.x, t.y, 1, 1);
                    return i.attr({
                      class: "highcharts-a11y-mock-point",
                      fill: "none",
                      opacity: 0,
                      "fill-opacity": 0,
                      "stroke-opacity": 0
                    }), i;
                  }(e, r);
                if (n && n.element) return e.graphic = o, e.hasMockGraphic = !0, o.add(n), n.element.insertBefore(o.element, s ? s.element : null), o.element;
              }(i),
              r = i.options && i.options.accessibility && !1 === i.options.accessibility.enabled;
            if (n) {
              if (i.isNull && !s) {
                n.setAttribute("aria-hidden", !0);
                return;
              }
              n.setAttribute("tabindex", "-1"), e.chart.styledMode || (n.style.outline = "none"), t && !r ? function (e, t) {
                let i = e.series,
                  s = i.options.accessibility?.point || {},
                  n = i.chart.options.accessibility.point || {},
                  r = i$(i0(s.descriptionFormat) && ij(s.descriptionFormat, e, i.chart) || s.descriptionFormatter?.(e) || i0(n.descriptionFormat) && ij(n.descriptionFormat, e, i.chart) || n.descriptionFormatter?.(e) || i6(e), i.chart.renderer.forExport);
                t.setAttribute("role", "img"), t.setAttribute("aria-label", r);
              }(i, n) : n.setAttribute("aria-hidden", !0);
            }
          });
        }(e), iY(t, s), function (e) {
          let t = e.chart,
            i = t.options.chart,
            s = i.options3d && i.options3d.enabled,
            n = t.series.length > 1,
            r = t.options.accessibility.series.describeSingleSeries,
            o = (e.options.accessibility || {}).exposeAsGroupOnly;
          return !(s && n) && (n || r || o || i3(e));
        }(e) ? function (e, t) {
          let i = e.options.accessibility || {},
            s = e.chart.options.accessibility,
            n = s.landmarkVerbosity;
          i.exposeAsGroupOnly ? t.setAttribute("role", "img") : "all" === n ? t.setAttribute("role", "region") : t.setAttribute("role", "group"), t.setAttribute("tabindex", "-1"), e.chart.styledMode || (t.style.outline = "none"), t.setAttribute("aria-label", i$(s.series.descriptionFormatter && s.series.descriptionFormatter(e) || i9(e), e.chart.renderer.forExport));
        }(e, s) : s.removeAttribute("aria-label"));
      }
    },
    {
      composed: i7
    } = w(),
    {
      addEvent: se,
      defined: st,
      pushUnique: si
    } = w(),
    {
      getChartTitle: ss
    } = K,
    {
      defaultPointDescriptionFormatter: sn,
      defaultSeriesDescriptionFormatter: sr
    } = i8;
  function so(e) {
    return !!e.options.accessibility.announceNewData.enabled;
  }
  class sa {
    constructor(e) {
      this.dirty = {
        allSeries: {}
      }, this.lastAnnouncementTime = 0, this.chart = e;
    }
    init() {
      let e = this.chart,
        t = e.options.accessibility.announceNewData.interruptUser ? "assertive" : "polite";
      this.lastAnnouncementTime = 0, this.dirty = {
        allSeries: {}
      }, this.eventProvider = new Y(), this.announcer = new ep(e, t), this.addEventListeners();
    }
    destroy() {
      this.eventProvider.removeAddedEvents(), this.announcer.destroy();
    }
    addEventListeners() {
      let e = this,
        t = this.chart,
        i = this.eventProvider;
      i.addEvent(t, "afterApplyDrilldown", function () {
        e.lastAnnouncementTime = 0;
      }), i.addEvent(t, "afterAddSeries", function (t) {
        e.onSeriesAdded(t.series);
      }), i.addEvent(t, "redraw", function () {
        e.announceDirtyData();
      });
    }
    onSeriesAdded(e) {
      so(this.chart) && (this.dirty.hasDirty = !0, this.dirty.allSeries[e.name + e.index] = e, this.dirty.newSeries = st(this.dirty.newSeries) ? void 0 : e);
    }
    announceDirtyData() {
      let e = this.chart,
        t = this;
      if (e.options.accessibility.announceNewData && this.dirty.hasDirty) {
        let e = this.dirty.newPoint;
        e && (e = function (e) {
          let t = e.series.data.filter(t => e.x === t.x && e.y === t.y);
          return 1 === t.length ? t[0] : e;
        }(e)), this.queueAnnouncement(Object.keys(this.dirty.allSeries).map(e => t.dirty.allSeries[e]), this.dirty.newSeries, e), this.dirty = {
          allSeries: {}
        };
      }
    }
    queueAnnouncement(e, t, i) {
      let s = this.chart.options.accessibility.announceNewData;
      if (s.enabled) {
        let n = +new Date(),
          r = n - this.lastAnnouncementTime,
          o = Math.max(0, s.minAnnounceInterval - r),
          a = function (e, t) {
            let i = (e || []).concat(t || []).reduce((e, t) => (e[t.name + t.index] = t, e), {});
            return Object.keys(i).map(e => i[e]);
          }(this.queuedAnnouncement && this.queuedAnnouncement.series, e),
          l = this.buildAnnouncementMessage(a, t, i);
        l && (this.queuedAnnouncement && clearTimeout(this.queuedAnnouncementTimer), this.queuedAnnouncement = {
          time: n,
          message: l,
          series: a
        }, this.queuedAnnouncementTimer = setTimeout(() => {
          this && this.announcer && (this.lastAnnouncementTime = +new Date(), this.announcer.announce(this.queuedAnnouncement.message), delete this.queuedAnnouncement, delete this.queuedAnnouncementTimer);
        }, o));
      }
    }
    buildAnnouncementMessage(e, t, i) {
      let s = this.chart,
        n = s.options.accessibility.announceNewData;
      if (n.announcementFormatter) {
        let s = n.announcementFormatter(e, t, i);
        if (!1 !== s) return s.length ? s : null;
      }
      let r = w().charts && w().charts.length > 1 ? "Multiple" : "Single",
        o = t ? "newSeriesAnnounce" + r : i ? "newPointAnnounce" + r : "newDataAnnounce",
        a = ss(s);
      return s.langFormat("accessibility.announceNewData." + o, {
        chartTitle: a,
        seriesDesc: t ? sr(t) : null,
        pointDesc: i ? sn(i) : null,
        point: i,
        series: t
      });
    }
  }
  !function (e) {
    function t(e) {
      let t = this.chart,
        i = t.accessibility?.components.series.newDataAnnouncer;
      i && i.chart === t && so(t) && (i.dirty.newPoint = st(i.dirty.newPoint) ? void 0 : e.point);
    }
    function i() {
      let e = this.chart,
        t = e.accessibility?.components.series.newDataAnnouncer;
      t && t.chart === e && so(e) && (t.dirty.hasDirty = !0, t.dirty.allSeries[this.name + this.index] = this);
    }
    e.compose = function (e) {
      si(i7, "A11y.NDA") && (se(e, "addPoint", t), se(e, "updatedData", i));
    };
  }(sa || (sa = {}));
  let sl = sa,
    {
      doc: sh
    } = w(),
    {
      attr: sc,
      css: sd,
      merge: su
    } = w(),
    {
      fireEventOnWrappedOrUnwrappedElement: sp
    } = K,
    {
      cloneMouseEvent: sg,
      cloneTouchEvent: sm,
      getFakeMouseEvent: sb,
      removeElement: sf
    } = S,
    sx = class {
      constructor(e, t, i = "button", s, n) {
        this.chart = e, this.target = t, this.eventProvider = new Y();
        let r = this.innerElement = sh.createElement(i),
          o = this.element = s ? sh.createElement(s) : r;
        e.styledMode || this.hideElementVisually(r), s && ("li" !== s || e.styledMode || (o.style.listStyle = "none"), o.appendChild(r), this.element = o), this.updateTarget(t, n);
      }
      click() {
        let e = this.getTargetPosition();
        e.x += e.width / 2, e.y += e.height / 2;
        let t = sb("click", e);
        sp(this.target.click, t);
      }
      updateTarget(e, t) {
        this.target = e, this.updateCSSClassName();
        let i = t || {};
        Object.keys(i).forEach(e => {
          null === i[e] && delete i[e];
        });
        let s = this.getTargetAttr(e.click, "aria-label");
        sc(this.innerElement, su(s ? {
          "aria-label": s
        } : {}, i)), this.eventProvider.removeAddedEvents(), this.addProxyEventsToElement(this.innerElement, e.click), this.refreshPosition();
      }
      refreshPosition() {
        let e = this.getTargetPosition();
        sd(this.innerElement, {
          width: (e.width || 1) + "px",
          height: (e.height || 1) + "px",
          left: (Math.round(e.x) || 0) + "px",
          top: (Math.round(e.y) || 0) + "px"
        });
      }
      remove() {
        this.eventProvider.removeAddedEvents(), sf(this.element);
      }
      updateCSSClassName() {
        let e = e => e.indexOf("highcharts-no-tooltip") > -1,
          t = this.chart.legend,
          i = t.group && t.group.div,
          s = e(i && i.className || ""),
          n = e(this.getTargetAttr(this.target.click, "class") || "");
        this.innerElement.className = s || n ? "highcharts-a11y-proxy-element highcharts-no-tooltip" : "highcharts-a11y-proxy-element";
      }
      addProxyEventsToElement(e, t) {
        ["click", "touchstart", "touchend", "touchcancel", "touchmove", "mouseover", "mouseenter", "mouseleave", "mouseout"].forEach(i => {
          let s = 0 === i.indexOf("touch");
          this.eventProvider.addEvent(e, i, e => {
            let i = s ? sm(e) : sg(e);
            t && sp(t, i), e.stopPropagation(), s || e.preventDefault();
          }, {
            passive: !1
          });
        });
      }
      hideElementVisually(e) {
        sd(e, {
          borderWidth: 0,
          backgroundColor: "transparent",
          cursor: "pointer",
          outline: "none",
          opacity: .001,
          filter: "alpha(opacity=1)",
          zIndex: 999,
          overflow: "hidden",
          padding: 0,
          margin: 0,
          display: "block",
          position: "absolute",
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)"
        });
      }
      getTargetPosition() {
        let e = this.target.click,
          t = e.element ? e.element : e,
          i = this.target.visual || t,
          s = this.chart.renderTo,
          n = this.chart.pointer;
        if (s && i?.getBoundingClientRect && n) {
          let e = i.getBoundingClientRect(),
            t = n.getChartPosition();
          return {
            x: (e.left - t.left) / t.scaleX,
            y: (e.top - t.top) / t.scaleY,
            width: e.right / t.scaleX - e.left / t.scaleX,
            height: e.bottom / t.scaleY - e.top / t.scaleY
          };
        }
        return {
          x: 0,
          y: 0,
          width: 1,
          height: 1
        };
      }
      getTargetAttr(e, t) {
        return e.element ? e.element.getAttribute(t) : e.getAttribute(t);
      }
    },
    {
      doc: sy
    } = w(),
    {
      attr: sv,
      css: sw
    } = w(),
    {
      unhideChartElementFromAT: sE
    } = K,
    {
      removeChildNodes: sA
    } = S,
    sT = class {
      constructor(e) {
        this.chart = e, this.domElementProvider = new W(), this.groups = {}, this.groupOrder = [], this.beforeChartProxyPosContainer = this.createProxyPosContainer("before"), this.afterChartProxyPosContainer = this.createProxyPosContainer("after"), this.update();
      }
      addProxyElement(e, t, i = "button", s) {
        let n = this.groups[e];
        if (!n) throw Error("ProxyProvider.addProxyElement: Invalid group key " + e);
        let r = "ul" === n.type || "ol" === n.type ? "li" : void 0,
          o = new sx(this.chart, t, i, r, s);
        return n.proxyContainerElement.appendChild(o.element), n.proxyElements.push(o), o;
      }
      addGroup(e, t = "div", i) {
        let s;
        let n = this.groups[e];
        if (n) return n.groupElement;
        let r = this.domElementProvider.createElement(t);
        return i && i.role && "div" !== t ? (s = this.domElementProvider.createElement("div")).appendChild(r) : s = r, s.className = "highcharts-a11y-proxy-group highcharts-a11y-proxy-group-" + e.replace(/\W/g, "-"), this.groups[e] = {
          proxyContainerElement: r,
          groupElement: s,
          type: t,
          proxyElements: []
        }, sv(s, i || {}), "ul" === t && r.setAttribute("role", "list"), this.afterChartProxyPosContainer.appendChild(s), this.updateGroupOrder(this.groupOrder), s;
      }
      updateGroupAttrs(e, t) {
        let i = this.groups[e];
        if (!i) throw Error("ProxyProvider.updateGroupAttrs: Invalid group key " + e);
        sv(i.groupElement, t);
      }
      updateGroupOrder(e) {
        if (this.groupOrder = e.slice(), this.isDOMOrderGroupOrder()) return;
        let t = e.indexOf("series"),
          i = t > -1 ? e.slice(0, t) : e,
          s = t > -1 ? e.slice(t + 1) : [],
          n = sy.activeElement;
        ["before", "after"].forEach(e => {
          let t = this["before" === e ? "beforeChartProxyPosContainer" : "afterChartProxyPosContainer"];
          sA(t), ("before" === e ? i : s).forEach(e => {
            let i = this.groups[e];
            i && t.appendChild(i.groupElement);
          });
        }), (this.beforeChartProxyPosContainer.contains(n) || this.afterChartProxyPosContainer.contains(n)) && n && n.focus && n.focus();
      }
      clearGroup(e) {
        let t = this.groups[e];
        if (!t) throw Error("ProxyProvider.clearGroup: Invalid group key " + e);
        sA(t.proxyContainerElement);
      }
      removeGroup(e) {
        let t = this.groups[e];
        t && (this.domElementProvider.removeElement(t.groupElement), t.groupElement !== t.proxyContainerElement && this.domElementProvider.removeElement(t.proxyContainerElement), delete this.groups[e]);
      }
      update() {
        this.updatePosContainerPositions(), this.updateGroupOrder(this.groupOrder), this.updateProxyElementPositions();
      }
      updateProxyElementPositions() {
        Object.keys(this.groups).forEach(this.updateGroupProxyElementPositions.bind(this));
      }
      updateGroupProxyElementPositions(e) {
        let t = this.groups[e];
        t && t.proxyElements.forEach(e => e.refreshPosition());
      }
      destroy() {
        this.domElementProvider.destroyCreatedElements();
      }
      createProxyPosContainer(e) {
        let t = this.domElementProvider.createElement("div");
        return t.setAttribute("aria-hidden", "false"), t.className = "highcharts-a11y-proxy-container" + (e ? "-" + e : ""), sw(t, {
          top: "0",
          left: "0"
        }), this.chart.styledMode || (t.style.whiteSpace = "nowrap", t.style.position = "absolute"), t;
      }
      getCurrentGroupOrderInDOM() {
        let e = e => {
            let t = Object.keys(this.groups),
              i = t.length;
            for (; i--;) {
              let s = t[i],
                n = this.groups[s];
              if (n && e === n.groupElement) return s;
            }
          },
          t = t => {
            let i = [],
              s = t.children;
            for (let t = 0; t < s.length; ++t) {
              let n = e(s[t]);
              n && i.push(n);
            }
            return i;
          },
          i = t(this.beforeChartProxyPosContainer),
          s = t(this.afterChartProxyPosContainer);
        return i.push("series"), i.concat(s);
      }
      isDOMOrderGroupOrder() {
        let e = this.getCurrentGroupOrderInDOM(),
          t = this.groupOrder.filter(e => "series" === e || !!this.groups[e]),
          i = e.length;
        if (i !== t.length) return !1;
        for (; i--;) if (e[i] !== t[i]) return !1;
        return !0;
      }
      updatePosContainerPositions() {
        let e = this.chart;
        if (e.renderer.forExport) return;
        let t = e.renderer.box;
        e.container.insertBefore(this.afterChartProxyPosContainer, t.nextSibling), e.container.insertBefore(this.beforeChartProxyPosContainer, t), sE(this.chart, this.afterChartProxyPosContainer), sE(this.chart, this.beforeChartProxyPosContainer);
      }
    },
    {
      unhideChartElementFromAT: sC,
      getAxisRangeDescription: sM
    } = K,
    {
      addEvent: sk,
      attr: sS
    } = w();
  class sP extends Z {
    init() {
      let e = this.chart;
      this.announcer = new ep(e, "polite");
    }
    onChartUpdate() {
      let e = this.chart,
        t = this,
        i = e.rangeSelector;
      i && (this.updateSelectorVisibility(), this.setDropdownAttrs(), i.buttons && i.buttons.length && i.buttons.forEach(e => {
        t.setRangeButtonAttrs(e);
      }), i.maxInput && i.minInput && ["minInput", "maxInput"].forEach(function (s, n) {
        let r = i[s];
        r && (sC(e, r), t.setRangeInputAttrs(r, "accessibility.rangeSelector." + (n ? "max" : "min") + "InputLabel"));
      }));
    }
    updateSelectorVisibility() {
      let e = this.chart,
        t = e.rangeSelector,
        i = t && t.dropdown,
        s = t && t.buttons || [],
        n = e => e.setAttribute("aria-hidden", !0);
      t && t.hasVisibleDropdown && i ? (sC(e, i), s.forEach(e => n(e.element))) : (i && n(i), s.forEach(t => sC(e, t.element)));
    }
    setDropdownAttrs() {
      let e = this.chart,
        t = e.rangeSelector && e.rangeSelector.dropdown;
      if (t) {
        let i = e.langFormat("accessibility.rangeSelector.dropdownLabel", {
          rangeTitle: e.options.lang.rangeSelectorZoom
        });
        t.setAttribute("aria-label", i), t.setAttribute("tabindex", -1);
      }
    }
    setRangeButtonAttrs(e) {
      sS(e.element, {
        tabindex: -1,
        role: "button"
      });
    }
    setRangeInputAttrs(e, t) {
      let i = this.chart;
      sS(e, {
        tabindex: -1,
        "aria-label": i.langFormat(t, {
          chart: i
        })
      });
    }
    onButtonNavKbdArrowKey(e, t) {
      let i = e.response,
        s = this.keyCodes,
        n = this.chart,
        r = n.options.accessibility.keyboardNavigation.wrapAround,
        o = t === s.left || t === s.up ? -1 : 1;
      return n.highlightRangeSelectorButton(n.highlightedRangeSelectorItemIx + o) ? i.success : r ? (e.init(o), i.success) : i[o > 0 ? "next" : "prev"];
    }
    onButtonNavKbdClick(e) {
      let t = e.response,
        i = this.chart;
      return 3 !== i.oldRangeSelectorItemState && this.fakeClickEvent(i.rangeSelector.buttons[i.highlightedRangeSelectorItemIx].element), t.success;
    }
    onAfterBtnClick() {
      let e = this.chart,
        t = sM(e.xAxis[0]),
        i = e.langFormat("accessibility.rangeSelector.clickButtonAnnouncement", {
          chart: e,
          axisRangeDescription: t
        });
      i && this.announcer.announce(i);
    }
    onInputKbdMove(e) {
      let t = this.chart,
        i = t.rangeSelector,
        s = t.highlightedInputRangeIx = (t.highlightedInputRangeIx || 0) + e;
      if (s > 1 || s < 0) {
        if (t.accessibility) return t.accessibility.keyboardNavigation.exiting = !0, t.accessibility.keyboardNavigation.tabindexContainer.focus(), t.accessibility.keyboardNavigation.move(e);
      } else if (i) {
        let e = i[s ? "maxDateBox" : "minDateBox"],
          n = i[s ? "maxInput" : "minInput"];
        e && n && t.setFocusToElement(e, n);
      }
      return !0;
    }
    onInputNavInit(e) {
      let t = this,
        i = this.chart,
        s = e > 0 ? 0 : 1,
        n = i.rangeSelector,
        r = n && n[s ? "maxDateBox" : "minDateBox"],
        o = n && n.minInput,
        a = n && n.maxInput;
      if (i.highlightedInputRangeIx = s, r && o && a) {
        i.setFocusToElement(r, s ? a : o), this.removeInputKeydownHandler && this.removeInputKeydownHandler();
        let e = e => {
            (e.which || e.keyCode) === this.keyCodes.tab && t.onInputKbdMove(e.shiftKey ? -1 : 1) && (e.preventDefault(), e.stopPropagation());
          },
          n = sk(o, "keydown", e),
          l = sk(a, "keydown", e);
        this.removeInputKeydownHandler = () => {
          n(), l();
        };
      }
    }
    onInputNavTerminate() {
      let e = this.chart.rangeSelector || {};
      e.maxInput && e.hideInput("max"), e.minInput && e.hideInput("min"), this.removeInputKeydownHandler && (this.removeInputKeydownHandler(), delete this.removeInputKeydownHandler);
    }
    initDropdownNav() {
      let e = this.chart,
        t = e.rangeSelector,
        i = t && t.dropdown;
      t && i && (e.setFocusToElement(t.buttonGroup, i), this.removeDropdownKeydownHandler && this.removeDropdownKeydownHandler(), this.removeDropdownKeydownHandler = sk(i, "keydown", t => {
        let i = (t.which || t.keyCode) === this.keyCodes.tab,
          s = e.accessibility;
        i && (t.preventDefault(), t.stopPropagation(), s && s.keyboardNavigation.move(t.shiftKey ? -1 : 1));
      }));
    }
    getRangeSelectorButtonNavigation() {
      let e = this.chart,
        t = this.keyCodes,
        i = this;
      return new Q(e, {
        keyCodeMap: [[[t.left, t.right, t.up, t.down], function (e) {
          return i.onButtonNavKbdArrowKey(this, e);
        }], [[t.enter, t.space], function () {
          return i.onButtonNavKbdClick(this);
        }]],
        validate: function () {
          return !!(e.rangeSelector && e.rangeSelector.buttons && e.rangeSelector.buttons.length);
        },
        init: function (t) {
          let s = e.rangeSelector;
          if (s && s.hasVisibleDropdown) i.initDropdownNav();else if (s) {
            let i = s.buttons.length - 1;
            e.highlightRangeSelectorButton(t > 0 ? 0 : i);
          }
        },
        terminate: function () {
          i.removeDropdownKeydownHandler && (i.removeDropdownKeydownHandler(), delete i.removeDropdownKeydownHandler);
        }
      });
    }
    getRangeSelectorInputNavigation() {
      let e = this.chart,
        t = this;
      return new Q(e, {
        keyCodeMap: [],
        validate: function () {
          return !!(e.rangeSelector && e.rangeSelector.inputGroup && "hidden" !== e.rangeSelector.inputGroup.element.style.visibility && !1 !== e.options.rangeSelector.inputEnabled && e.rangeSelector.minInput && e.rangeSelector.maxInput);
        },
        init: function (e) {
          t.onInputNavInit(e);
        },
        terminate: function () {
          t.onInputNavTerminate();
        }
      });
    }
    getKeyboardNavigation() {
      return [this.getRangeSelectorButtonNavigation(), this.getRangeSelectorInputNavigation()];
    }
    destroy() {
      this.removeDropdownKeydownHandler && this.removeDropdownKeydownHandler(), this.removeInputKeydownHandler && this.removeInputKeydownHandler(), this.announcer && this.announcer.destroy();
    }
  }
  !function (e) {
    function t(e) {
      let t = this.rangeSelector && this.rangeSelector.buttons || [],
        i = this.highlightedRangeSelectorItemIx,
        s = this.rangeSelector && this.rangeSelector.selected;
      return void 0 !== i && t[i] && i !== s && t[i].setState(this.oldRangeSelectorItemState || 0), this.highlightedRangeSelectorItemIx = e, !!t[e] && (this.setFocusToElement(t[e].box, t[e].element), e !== s && (this.oldRangeSelectorItemState = t[e].state, t[e].setState(1)), !0);
    }
    function i() {
      let e = this.chart.accessibility;
      if (e && e.components.rangeSelector) return e.components.rangeSelector.onAfterBtnClick();
    }
    e.compose = function (e, s) {
      let n = e.prototype;
      n.highlightRangeSelectorButton || (n.highlightRangeSelectorButton = t, sk(s, "afterBtnClick", i));
    };
  }(sP || (sP = {}));
  let sD = sP,
    {
      composed: sN
    } = w(),
    {
      addEvent: sB,
      merge: sI,
      pushUnique: sF
    } = w();
  !function (e) {
    function t(e) {
      sI(!0, e, {
        marker: {
          enabled: !0,
          states: {
            normal: {
              opacity: 0
            }
          }
        }
      });
    }
    function i(e) {
      return e.marker.states && e.marker.states.normal && e.marker.states.normal.opacity;
    }
    function s(e) {
      return !!(e._hasPointMarkers && e.points && e.points.length);
    }
    function n() {
      this.chart.styledMode && (this.markerGroup && this.markerGroup[this.a11yMarkersForced ? "addClass" : "removeClass"]("highcharts-a11y-markers-hidden"), s(this) && this.points.forEach(e => {
        e.graphic && (e.graphic[e.hasForcedA11yMarker ? "addClass" : "removeClass"]("highcharts-a11y-marker-hidden"), e.graphic[!1 === e.hasForcedA11yMarker ? "addClass" : "removeClass"]("highcharts-a11y-marker-visible"));
      }));
    }
    function r(e) {
      this.resetA11yMarkerOptions = sI(e.options.marker || {}, this.userOptions.marker || {});
    }
    function o() {
      let e = this.options;
      !function (e) {
        let t = e.chart.options.accessibility.enabled,
          i = !1 !== (e.options.accessibility && e.options.accessibility.enabled);
        return t && i && function (e) {
          let t = e.chart.options.accessibility;
          return e.points.length < t.series.pointDescriptionEnabledThreshold || !1 === t.series.pointDescriptionEnabledThreshold;
        }(e);
      }(this) ? this.a11yMarkersForced && (delete this.a11yMarkersForced, function (e) {
        let t = e.resetA11yMarkerOptions;
        if (t) {
          let i = t.states && t.states.normal && t.states.normal.opacity;
          e.userOptions && e.userOptions.marker && (e.userOptions.marker.enabled = !0), e.update({
            marker: {
              enabled: t.enabled,
              states: {
                normal: {
                  opacity: i
                }
              }
            }
          });
        }
      }(this), delete this.resetA11yMarkerOptions) : (e.marker && !1 === e.marker.enabled && (this.a11yMarkersForced = !0, t(this.options)), s(this) && function (e) {
        let s = e.points.length;
        for (; s--;) {
          let n = e.points[s],
            r = n.options,
            o = n.hasForcedA11yMarker;
          if (delete n.hasForcedA11yMarker, r.marker) {
            let e = o && 0 === i(r);
            r.marker.enabled && !e ? (sI(!0, r.marker, {
              states: {
                normal: {
                  opacity: i(r) || 1
                }
              }
            }), n.hasForcedA11yMarker = !1) : !1 === r.marker.enabled && (t(r), n.hasForcedA11yMarker = !0);
          }
        }
      }(this));
    }
    function a() {
      this.boosted && this.a11yMarkersForced && (sI(!0, this.options, {
        marker: {
          enabled: !1
        }
      }), delete this.a11yMarkersForced);
    }
    e.compose = function (e) {
      sF(sN, "A11y.FM") && (sB(e, "afterSetOptions", r), sB(e, "render", o), sB(e, "afterRender", n), sB(e, "renderCanvas", a));
    };
  }(m || (m = {}));
  let sO = m;
  var sR = x(260),
    sL = x.n(sR),
    sH = x(820),
    sz = x.n(sH);
  let {
      seriesTypes: sq
    } = tF(),
    {
      doc: sG
    } = w(),
    {
      defined: sK,
      fireEvent: sU
    } = w(),
    {
      getPointFromXY: sV,
      getSeriesFromName: sW,
      scrollAxisToPoint: sX
    } = K;
  function sY(e) {
    let t = e.index,
      i = e.series.points,
      s = i.length;
    if (i[t] === e) return t;
    for (; s--;) if (i[s] === e) return s;
  }
  function sj(e) {
    let t = e.chart.options.accessibility.keyboardNavigation.seriesNavigation,
      i = e.options.accessibility || {},
      s = i.keyboardNavigation;
    return s && !1 === s.enabled || !1 === i.enabled || !1 === e.options.enableMouseTracking || !e.visible || t.pointNavigationEnabledThreshold && +t.pointNavigationEnabledThreshold <= e.points.length;
  }
  function s_(e) {
    let t = e.series.chart.options.accessibility,
      i = e.options.accessibility && !1 === e.options.accessibility.enabled;
    return e.isNull && t.keyboardNavigation.seriesNavigation.skipNullPoints || !1 === e.visible || !1 === e.isInside || i || sj(e.series);
  }
  function sZ(e) {
    let t = e.series || [],
      i = t.length;
    for (let e = 0; e < i; ++e) if (!sj(t[e])) {
      let i = function (e) {
        let t = e.points || [],
          i = t.length;
        for (let e = 0; e < i; ++e) if (!s_(t[e])) return t[e];
        return null;
      }(t[e]);
      if (i) return i;
    }
    return null;
  }
  function s$(e) {
    let t = e.series.length,
      i = !1;
    for (; t-- && (e.highlightedPoint = e.series[t].points[e.series[t].points.length - 1], !(i = e.series[t].highlightNextValidPoint())););
    return i;
  }
  function sQ(e) {
    delete e.highlightedPoint;
    let t = sZ(e);
    return !!t && t.highlight();
  }
  class sJ {
    constructor(e, t) {
      this.keyCodes = t, this.chart = e;
    }
    init() {
      let e = this,
        t = this.chart,
        i = this.eventProvider = new Y();
      i.addEvent(sz(), "destroy", function () {
        return e.onSeriesDestroy(this);
      }), i.addEvent(t, "afterApplyDrilldown", function () {
        !function (e) {
          let t = sZ(e);
          t && t.highlight(!1);
        }(this);
      }), i.addEvent(t, "drilldown", function (t) {
        let i = t.point,
          s = i.series;
        e.lastDrilledDownPoint = {
          x: i.x,
          y: i.y,
          seriesName: s ? s.name : ""
        };
      }), i.addEvent(t, "drillupall", function () {
        setTimeout(function () {
          e.onDrillupAll();
        }, 10);
      }), i.addEvent(sL(), "afterSetState", function () {
        let e = this.graphic && this.graphic.element,
          i = sG.activeElement,
          s = i && i.getAttribute("class"),
          n = s && s.indexOf("highcharts-a11y-proxy-element") > -1;
        t.highlightedPoint === this && i !== e && !n && e && e.focus && e.focus();
      });
    }
    onDrillupAll() {
      let e;
      let t = this.lastDrilledDownPoint,
        i = this.chart,
        s = t && sW(i, t.seriesName);
      t && s && sK(t.x) && sK(t.y) && (e = sV(s, t.x, t.y)), e = e || sZ(i), i.container && i.container.focus(), e && e.highlight && e.highlight(!1);
    }
    getKeyboardNavigationHandler() {
      let e = this,
        t = this.keyCodes,
        i = this.chart,
        s = i.inverted;
      return new Q(i, {
        keyCodeMap: [[s ? [t.up, t.down] : [t.left, t.right], function (t) {
          return e.onKbdSideways(this, t);
        }], [s ? [t.left, t.right] : [t.up, t.down], function (t) {
          return e.onKbdVertical(this, t);
        }], [[t.enter, t.space], function (e, t) {
          let s = i.highlightedPoint;
          if (s) {
            let {
                plotLeft: e,
                plotTop: i
              } = this.chart,
              {
                plotX: n = 0,
                plotY: r = 0
              } = s;
            t = {
              ...t,
              chartX: e + n,
              chartY: i + r,
              point: s,
              target: s.graphic?.element || t.target
            }, sU(s.series, "click", t), s.firePointEvent("click", t);
          }
          return this.response.success;
        }], [[t.home], function () {
          return sQ(i), this.response.success;
        }], [[t.end], function () {
          return s$(i), this.response.success;
        }], [[t.pageDown, t.pageUp], function (e) {
          return i.highlightAdjacentSeries(e === t.pageDown), this.response.success;
        }]],
        init: function () {
          return e.onHandlerInit(this);
        },
        validate: function () {
          return !!sZ(i);
        },
        terminate: function () {
          return e.onHandlerTerminate();
        }
      });
    }
    onKbdSideways(e, t) {
      let i = this.keyCodes,
        s = t === i.right || t === i.down;
      return this.attemptHighlightAdjacentPoint(e, s);
    }
    onHandlerInit(e) {
      let t = this.chart;
      return t.options.accessibility.keyboardNavigation.seriesNavigation.rememberPointFocus && t.highlightedPoint ? t.highlightedPoint.highlight() : sQ(t), e.response.success;
    }
    onKbdVertical(e, t) {
      let i = this.chart,
        s = this.keyCodes,
        n = t === s.down || t === s.right,
        r = i.options.accessibility.keyboardNavigation.seriesNavigation;
      if (r.mode && "serialize" === r.mode) return this.attemptHighlightAdjacentPoint(e, n);
      let o = i.highlightedPoint && i.highlightedPoint.series.keyboardMoveVertical ? "highlightAdjacentPointVertical" : "highlightAdjacentSeries";
      return i[o](n), e.response.success;
    }
    onHandlerTerminate() {
      let e = this.chart,
        t = e.options.accessibility.keyboardNavigation;
      e.tooltip && e.tooltip.hide(0);
      let i = e.highlightedPoint && e.highlightedPoint.series;
      i && i.onMouseOut && i.onMouseOut(), e.highlightedPoint && e.highlightedPoint.onMouseOut && e.highlightedPoint.onMouseOut(), t.seriesNavigation.rememberPointFocus || delete e.highlightedPoint;
    }
    attemptHighlightAdjacentPoint(e, t) {
      let i = this.chart,
        s = i.options.accessibility.keyboardNavigation.wrapAround;
      return i.highlightAdjacentPoint(t) ? e.response.success : s && (t ? sQ(i) : s$(i)) ? e.response.success : e.response[t ? "next" : "prev"];
    }
    onSeriesDestroy(e) {
      let t = this.chart;
      t.highlightedPoint && t.highlightedPoint.series === e && (delete t.highlightedPoint, t.focusElement && t.focusElement.removeFocusBorder());
    }
    destroy() {
      this.eventProvider.removeAddedEvents();
    }
  }
  !function (e) {
    function t(e) {
      let t, i;
      let s = this.series,
        n = this.highlightedPoint,
        r = n && sY(n) || 0,
        o = n && n.series.points || [],
        a = this.series && this.series[this.series.length - 1],
        l = a && a.points && a.points[a.points.length - 1];
      if (!s[0] || !s[0].points) return !1;
      if (n) {
        if (t = s[n.series.index + (e ? 1 : -1)], (i = o[r + (e ? 1 : -1)]) || !t || (i = t.points[e ? 0 : t.points.length - 1]), !i) return !1;
      } else i = e ? s[0].points[0] : l;
      return s_(i) ? (sj(t = i.series) ? this.highlightedPoint = e ? t.points[t.points.length - 1] : t.points[0] : this.highlightedPoint = i, this.highlightAdjacentPoint(e)) : i.highlight();
    }
    function i(e) {
      let t = this.highlightedPoint,
        i = 1 / 0,
        s;
      return !!(sK(t.plotX) && sK(t.plotY)) && (this.series.forEach(n => {
        sj(n) || n.points.forEach(r => {
          if (!sK(r.plotY) || !sK(r.plotX) || r === t) return;
          let o = r.plotY - t.plotY,
            a = Math.abs(r.plotX - t.plotX),
            l = Math.abs(o) * Math.abs(o) + a * a * 4;
          n.yAxis && n.yAxis.reversed && (o *= -1), !(o <= 0 && e || o >= 0 && !e || l < 5 || s_(r)) && l < i && (i = l, s = r);
        });
      }), !!s && s.highlight());
    }
    function s(e) {
      let t, i, s;
      let n = this.highlightedPoint,
        r = this.series && this.series[this.series.length - 1],
        o = r && r.points && r.points[r.points.length - 1];
      return this.highlightedPoint ? !!((t = this.series[n.series.index + (e ? -1 : 1)]) && (i = function (e, t, i, s) {
        let n = 1 / 0,
          r,
          o,
          a,
          l = t.points.length,
          h = e => !(sK(e.plotX) && sK(e.plotY));
        if (!h(e)) {
          for (; l--;) !h(r = t.points[l]) && (a = (e.plotX - r.plotX) * (e.plotX - r.plotX) * 4 + (e.plotY - r.plotY) * (e.plotY - r.plotY) * 1) < n && (n = a, o = l);
          return sK(o) ? t.points[o] : void 0;
        }
      }(n, t, 0))) && (sj(t) ? (i.highlight(), s = this.highlightAdjacentSeries(e)) ? s : (n.highlight(), !1) : (i.highlight(), i.series.highlightNextValidPoint())) : (t = e ? this.series && this.series[0] : r, !!(i = e ? t && t.points && t.points[0] : o) && i.highlight());
    }
    function n(e = !0) {
      let t = this.series.chart,
        i = t.tooltip?.label?.element;
      !this.isNull && e ? this.onMouseOver() : t.tooltip && t.tooltip.hide(0), sX(this), this.graphic && (t.setFocusToElement(this.graphic), !e && t.focusElement && t.focusElement.removeFocusBorder()), t.highlightedPoint = this;
      let s = i?.getBoundingClientRect().top;
      if (i && s && s < 0) {
        let e = window.scrollY;
        window.scrollTo({
          behavior: "smooth",
          top: e + s
        });
      }
      return this;
    }
    function r() {
      let e = this.chart.highlightedPoint,
        t = (e && e.series) === this ? sY(e) : 0,
        i = this.points,
        s = i.length;
      if (i && s) {
        for (let e = t; e < s; ++e) if (!s_(i[e])) return i[e].highlight();
        for (let e = t; e >= 0; --e) if (!s_(i[e])) return i[e].highlight();
      }
      return !1;
    }
    e.compose = function (e, o, a) {
      let l = e.prototype,
        h = o.prototype,
        c = a.prototype;
      l.highlightAdjacentPoint || (l.highlightAdjacentPoint = t, l.highlightAdjacentPointVertical = i, l.highlightAdjacentSeries = s, h.highlight = n, c.keyboardMoveVertical = !0, ["column", "gantt", "pie"].forEach(e => {
        sq[e] && (sq[e].prototype.keyboardMoveVertical = !1);
      }), c.highlightNextValidPoint = r);
    };
  }(sJ || (sJ = {}));
  let s0 = sJ,
    {
      hideSeriesFromAT: s1
    } = K,
    {
      describeSeries: s2
    } = i8,
    s3 = class extends Z {
      static compose(e, t, i) {
        sl.compose(i), sO.compose(i), s0.compose(e, t, i);
      }
      init() {
        this.newDataAnnouncer = new sl(this.chart), this.newDataAnnouncer.init(), this.keyboardNavigation = new s0(this.chart, this.keyCodes), this.keyboardNavigation.init(), this.hideTooltipFromATWhenShown(), this.hideSeriesLabelsFromATWhenShown();
      }
      hideTooltipFromATWhenShown() {
        let e = this;
        this.chart.tooltip && this.addEvent(this.chart.tooltip.constructor, "refresh", function () {
          this.chart === e.chart && this.label && this.label.element && this.label.element.setAttribute("aria-hidden", !0);
        });
      }
      hideSeriesLabelsFromATWhenShown() {
        this.addEvent(this.chart, "afterDrawSeriesLabels", function () {
          this.series.forEach(function (e) {
            e.labelBySeries && e.labelBySeries.attr("aria-hidden", !0);
          });
        });
      }
      onChartRender() {
        this.chart.series.forEach(function (e) {
          !1 !== (e.options.accessibility && e.options.accessibility.enabled) && e.visible && 0 !== e.getPointsCollection().length ? s2(e) : s1(e);
        });
      }
      getKeyboardNavigation() {
        return this.keyboardNavigation.getKeyboardNavigationHandler();
      }
      destroy() {
        this.newDataAnnouncer.destroy(), this.keyboardNavigation.destroy();
      }
    },
    {
      unhideChartElementFromAT: s5
    } = K,
    {
      getFakeMouseEvent: s4
    } = S,
    {
      attr: s6,
      pick: s9
    } = w(),
    s8 = class extends Z {
      constructor() {
        super(...arguments), this.focusedMapNavButtonIx = -1;
      }
      init() {
        let e = this,
          t = this.chart;
        this.proxyProvider.addGroup("zoom", "div"), ["afterShowResetZoom", "afterApplyDrilldown", "drillupall"].forEach(i => {
          e.addEvent(t, i, function () {
            e.updateProxyOverlays();
          });
        });
      }
      onChartUpdate() {
        let e = this.chart,
          t = this;
        e.mapNavigation && e.mapNavigation.navButtons.forEach((i, s) => {
          s5(e, i.element), t.setMapNavButtonAttrs(i.element, "accessibility.zoom.mapZoom" + (s ? "Out" : "In"));
        });
      }
      setMapNavButtonAttrs(e, t) {
        let i = this.chart;
        s6(e, {
          tabindex: -1,
          role: "button",
          "aria-label": i.langFormat(t, {
            chart: i
          })
        });
      }
      onChartRender() {
        this.updateProxyOverlays();
      }
      updateProxyOverlays() {
        let e = this.chart;
        if (this.proxyProvider.clearGroup("zoom"), e.resetZoomButton && this.createZoomProxyButton(e.resetZoomButton, "resetZoomProxyButton", e.langFormat("accessibility.zoom.resetZoomButton", {
          chart: e
        })), e.drillUpButton && e.breadcrumbs && e.breadcrumbs.list) {
          let t = e.breadcrumbs.list[e.breadcrumbs.list.length - 1];
          this.createZoomProxyButton(e.drillUpButton, "drillUpProxyButton", e.langFormat("accessibility.drillUpButton", {
            chart: e,
            buttonText: e.breadcrumbs.getButtonText(t)
          }));
        }
      }
      createZoomProxyButton(e, t, i) {
        this[t] = this.proxyProvider.addProxyElement("zoom", {
          click: e
        }, "button", {
          "aria-label": i,
          tabindex: -1
        });
      }
      getMapZoomNavigation() {
        let e = this.keyCodes,
          t = this.chart,
          i = this;
        return new Q(t, {
          keyCodeMap: [[[e.up, e.down, e.left, e.right], function (e) {
            return i.onMapKbdArrow(this, e);
          }], [[e.tab], function (e, t) {
            return i.onMapKbdTab(this, t);
          }], [[e.space, e.enter], function () {
            return i.onMapKbdClick(this);
          }]],
          validate: function () {
            return !!(t.mapView && t.mapNavigation && t.mapNavigation.navButtons.length);
          },
          init: function (e) {
            return i.onMapNavInit(e);
          }
        });
      }
      onMapKbdArrow(e, t) {
        let i = this.chart,
          s = this.keyCodes,
          n = i.container,
          r = t === s.up || t === s.down,
          o = t === s.left || t === s.up ? 1 : -1,
          a = (r ? i.plotHeight : i.plotWidth) / 10 * o,
          l = 10 * Math.random(),
          h = {
            x: n.offsetLeft + i.plotLeft + i.plotWidth / 2 + l,
            y: n.offsetTop + i.plotTop + i.plotHeight / 2 + l
          },
          c = r ? {
            x: h.x,
            y: h.y + a
          } : {
            x: h.x + a,
            y: h.y
          };
        return [s4("mousedown", h), s4("mousemove", c), s4("mouseup", c)].forEach(e => n.dispatchEvent(e)), e.response.success;
      }
      onMapKbdTab(e, t) {
        let i = this.chart,
          s = e.response,
          n = t.shiftKey,
          r = n && !this.focusedMapNavButtonIx || !n && this.focusedMapNavButtonIx;
        if (i.mapNavigation.navButtons[this.focusedMapNavButtonIx].setState(0), r) return i.mapView && i.mapView.zoomBy(), s[n ? "prev" : "next"];
        this.focusedMapNavButtonIx += n ? -1 : 1;
        let o = i.mapNavigation.navButtons[this.focusedMapNavButtonIx];
        return i.setFocusToElement(o.box, o.element), o.setState(2), s.success;
      }
      onMapKbdClick(e) {
        let t = this.chart.mapNavigation.navButtons[this.focusedMapNavButtonIx].element;
        return this.fakeClickEvent(t), e.response.success;
      }
      onMapNavInit(e) {
        let t = this.chart,
          i = t.mapNavigation.navButtons[0],
          s = t.mapNavigation.navButtons[1],
          n = e > 0 ? i : s;
        t.setFocusToElement(n.box, n.element), n.setState(2), this.focusedMapNavButtonIx = e > 0 ? 0 : 1;
      }
      simpleButtonNavigation(e, t, i) {
        let s = this.keyCodes,
          n = this,
          r = this.chart;
        return new Q(r, {
          keyCodeMap: [[[s.tab, s.up, s.down, s.left, s.right], function (e, t) {
            let i = e === s.tab && t.shiftKey || e === s.left || e === s.up;
            return this.response[i ? "prev" : "next"];
          }], [[s.space, s.enter], function () {
            return s9(i(this, r), this.response.success);
          }]],
          validate: function () {
            return r[e] && r[e].box && n[t].innerElement;
          },
          init: function () {
            r.setFocusToElement(r[e].box, n[t].innerElement);
          }
        });
      }
      getKeyboardNavigation() {
        return [this.simpleButtonNavigation("resetZoomButton", "resetZoomProxyButton", function (e, t) {
          t.zoomOut();
        }), this.simpleButtonNavigation("drillUpButton", "drillUpProxyButton", function (e, t) {
          return t.drillUp(), e.response.prev;
        }), this.getMapZoomNavigation()];
      }
    },
    {
      doc: s7,
      isMS: ne,
      win: nt
    } = w(),
    ni = {
      isHighContrastModeActive: function () {
        let e = /(Edg)/.test(nt.navigator.userAgent);
        if (nt.matchMedia && e) return nt.matchMedia("(-ms-high-contrast: active)").matches;
        if (ne && nt.getComputedStyle) {
          let e = s7.createElement("div");
          e.style.backgroundImage = "url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==)", s7.body.appendChild(e);
          let t = (e.currentStyle || nt.getComputedStyle(e)).backgroundImage;
          return s7.body.removeChild(e), "none" === t;
        }
        return nt.matchMedia && nt.matchMedia("(forced-colors: active)").matches;
      },
      setHighContrastTheme: function (e) {
        e.highContrastModeActive = !0;
        let t = e.options.accessibility.highContrastTheme;
        e.update(t, !1);
        let i = t.colors?.length > 1;
        e.series.forEach(function (e) {
          let s = t.plotOptions[e.type] || {},
            n = i && void 0 !== e.colorIndex ? t.colors[e.colorIndex] : s.color || "window",
            r = {
              color: s.color || "windowText",
              colors: i ? t.colors : [s.color || "windowText"],
              borderColor: s.borderColor || "window",
              fillColor: n
            };
          e.update(r, !1), e.points && e.points.forEach(function (e) {
            e.options && e.options.color && e.update({
              color: s.color || "windowText",
              borderColor: s.borderColor || "window"
            }, !1);
          });
        }), e.redraw();
      }
    },
    {
      error: ns,
      pick: nn
    } = w();
  function nr(e, t, i) {
    let s = e,
      n,
      r = 0;
    for (; r < t.length - 1; ++r) s = s[n = t[r]] = nn(s[n], {});
    s[t[t.length - 1]] = i;
  }
  function no(e, t, i, s) {
    function n(e, t) {
      return t.reduce(function (e, t) {
        return e[t];
      }, e);
    }
    let r = n(e.options, t),
      o = n(e.options, i);
    Object.keys(s).forEach(function (n) {
      let a = r[n];
      void 0 !== a && (nr(o, s[n], a), ns(32, !1, e, {
        [t.join(".") + "." + n]: i.join(".") + "." + s[n].join(".")
      }));
    });
  }
  let na = function (e) {
      !function (e) {
        let t = e.options.chart,
          i = e.options.accessibility || {};
        ["description", "typeDescription"].forEach(function (s) {
          t[s] && (i[s] = t[s], ns(32, !1, e, {
            [`chart.${s}`]: `use accessibility.${s}`
          }));
        });
      }(e), function (e) {
        e.axes.forEach(function (t) {
          let i = t.options;
          i && i.description && (i.accessibility = i.accessibility || {}, i.accessibility.description = i.description, ns(32, !1, e, {
            "axis.description": "use axis.accessibility.description"
          }));
        });
      }(e), e.series && function (e) {
        let t = {
          description: ["accessibility", "description"],
          exposeElementToA11y: ["accessibility", "exposeAsGroupOnly"],
          pointDescriptionFormatter: ["accessibility", "point", "descriptionFormatter"],
          skipKeyboardNavigation: ["accessibility", "keyboardNavigation", "enabled"],
          "accessibility.pointDescriptionFormatter": ["accessibility", "point", "descriptionFormatter"]
        };
        e.series.forEach(function (i) {
          Object.keys(t).forEach(function (s) {
            let n = i.options[s];
            "accessibility.pointDescriptionFormatter" === s && (n = i.options.accessibility && i.options.accessibility.pointDescriptionFormatter), void 0 !== n && (nr(i.options, t[s], "skipKeyboardNavigation" === s ? !n : n), ns(32, !1, e, {
              [`series.${s}`]: "series." + t[s].join(".")
            }));
          });
        });
      }(e), no(e, ["accessibility"], ["accessibility"], {
        pointDateFormat: ["point", "dateFormat"],
        pointDateFormatter: ["point", "dateFormatter"],
        pointDescriptionFormatter: ["point", "descriptionFormatter"],
        pointDescriptionThreshold: ["series", "pointDescriptionEnabledThreshold"],
        pointNavigationThreshold: ["keyboardNavigation", "seriesNavigation", "pointNavigationEnabledThreshold"],
        pointValueDecimals: ["point", "valueDecimals"],
        pointValuePrefix: ["point", "valuePrefix"],
        pointValueSuffix: ["point", "valueSuffix"],
        screenReaderSectionFormatter: ["screenReaderSection", "beforeChartFormatter"],
        describeSingleSeries: ["series", "describeSingleSeries"],
        seriesDescriptionFormatter: ["series", "descriptionFormatter"],
        onTableAnchorClick: ["screenReaderSection", "onViewDataTableClick"],
        axisRangeDateFormat: ["screenReaderSection", "axisRangeDateFormat"]
      }), no(e, ["accessibility", "keyboardNavigation"], ["accessibility", "keyboardNavigation", "seriesNavigation"], {
        skipNullPoints: ["skipNullPoints"],
        mode: ["mode"]
      }), no(e, ["lang", "accessibility"], ["lang", "accessibility"], {
        legendItem: ["legend", "legendItem"],
        legendLabel: ["legend", "legendLabel"],
        mapZoomIn: ["zoom", "mapZoomIn"],
        mapZoomOut: ["zoom", "mapZoomOut"],
        resetZoomButton: ["zoom", "resetZoomButton"],
        screenReaderRegionLabel: ["screenReaderSection", "beforeRegionLabel"],
        rangeSelectorButton: ["rangeSelector", "buttonText"],
        rangeSelectorMaxInput: ["rangeSelector", "maxInputLabel"],
        rangeSelectorMinInput: ["rangeSelector", "minInputLabel"],
        svgContainerEnd: ["screenReaderSection", "endOfChartMarker"],
        viewAsDataTable: ["table", "viewAsDataTableButtonText"],
        tableSummary: ["table", "tableSummary"]
      });
    },
    {
      defaultOptions: nl
    } = w(),
    {
      doc: nh
    } = w(),
    {
      addEvent: nc,
      extend: nd,
      fireEvent: nu,
      merge: np
    } = w(),
    {
      removeElement: ng
    } = S;
  class nm {
    constructor(e) {
      this.init(e);
    }
    init(e) {
      if (this.chart = e, !nh.addEventListener) {
        this.zombie = !0, this.components = {}, e.renderTo.setAttribute("aria-hidden", !0);
        return;
      }
      na(e), this.proxyProvider = new sT(this.chart), this.initComponents(), this.keyboardNavigation = new eJ(e, this.components);
    }
    initComponents() {
      let e = this.chart,
        t = this.proxyProvider,
        i = e.options.accessibility;
      this.components = {
        container: new es(),
        infoRegions: new eL(),
        legend: new to(),
        chartMenu: new eV(),
        rangeSelector: new sD(),
        series: new s3(),
        zoom: new s8(),
        navigator: new iK()
      }, i.customComponents && nd(this.components, i.customComponents);
      let s = this.components;
      this.getComponentOrder().forEach(function (i) {
        s[i].initBase(e, t), s[i].init();
      });
    }
    getComponentOrder() {
      return this.components ? this.components.series ? ["series"].concat(Object.keys(this.components).filter(e => "series" !== e)) : Object.keys(this.components) : [];
    }
    update() {
      let e = this.components,
        t = this.chart,
        i = t.options.accessibility;
      nu(t, "beforeA11yUpdate"), t.types = this.getChartTypes();
      let s = i.keyboardNavigation.order;
      this.proxyProvider.updateGroupOrder(s), this.getComponentOrder().forEach(function (i) {
        e[i].onChartUpdate(), nu(t, "afterA11yComponentUpdate", {
          name: i,
          component: e[i]
        });
      }), this.keyboardNavigation.update(s), !t.highContrastModeActive && !1 !== i.highContrastMode && (ni.isHighContrastModeActive() || !0 === i.highContrastMode) && ni.setHighContrastTheme(t), nu(t, "afterA11yUpdate", {
        accessibility: this
      });
    }
    destroy() {
      let e = this.chart || {},
        t = this.components;
      Object.keys(t).forEach(function (e) {
        t[e].destroy(), t[e].destroyBase();
      }), this.proxyProvider && this.proxyProvider.destroy(), e.announcerContainer && ng(e.announcerContainer), this.keyboardNavigation && this.keyboardNavigation.destroy(), e.renderTo && e.renderTo.setAttribute("aria-hidden", !0), e.focusElement && e.focusElement.removeFocusBorder();
    }
    getChartTypes() {
      let e = {};
      return this.chart.series.forEach(function (t) {
        e[t.type] = 1;
      }), Object.keys(e);
    }
  }
  !function (e) {
    function t() {
      this.accessibility && this.accessibility.destroy();
    }
    function i() {
      this.a11yDirty && this.renderTo && (delete this.a11yDirty, this.updateA11yEnabled());
      let e = this.accessibility;
      e && !e.zombie && (e.proxyProvider.updateProxyElementPositions(), e.getComponentOrder().forEach(function (t) {
        e.components[t].onChartRender();
      }));
    }
    function s(e) {
      let t = e.options.accessibility;
      t && (t.customComponents && (this.options.accessibility.customComponents = t.customComponents, delete t.customComponents), np(!0, this.options.accessibility, t), this.accessibility && this.accessibility.destroy && (this.accessibility.destroy(), delete this.accessibility)), this.a11yDirty = !0;
    }
    function n() {
      let t = this.accessibility,
        i = this.options.accessibility,
        s = this.renderer.boxWrapper.element,
        n = this.title;
      if (i && i.enabled) t && !t.zombie ? t.update() : (this.accessibility = t = new e(this), t && !t.zombie && t.update(), "img" === s.getAttribute("role") && s.removeAttribute("role"));else if (t) t.destroy && t.destroy(), delete this.accessibility;else {
        this.renderTo.setAttribute("role", "img"), this.renderTo.setAttribute("aria-hidden", !1), this.renderTo.setAttribute("aria-label", (n && n.element.textContent || "").replace(/</g, "&lt;")), s.setAttribute("aria-hidden", !0);
        let e = document.getElementsByClassName("highcharts-description")[0];
        e && (e.setAttribute("aria-hidden", !1), e.classList.remove("highcharts-linked-description"));
      }
    }
    function r() {
      this.series.chart.accessibility && (this.series.chart.a11yDirty = !0);
    }
    e.i18nFormat = F.i18nFormat, e.compose = function (e, o, a, l, h, c) {
      eJ.compose(e), sl.compose(l), to.compose(e, o), eV.compose(e), s3.compose(e, a, l), F.compose(e), eo.compose(e, h), c && sD.compose(e, c);
      let d = e.prototype;
      d.updateA11yEnabled || (d.updateA11yEnabled = n, nc(e, "destroy", t), nc(e, "render", i), nc(e, "update", s), ["addSeries", "init"].forEach(t => {
        nc(e, t, function () {
          this.a11yDirty = !0;
        });
      }), ["afterApplyDrilldown", "drillupall"].forEach(t => {
        nc(e, t, function () {
          let e = this.accessibility;
          e && !e.zombie && e.update();
        });
      }), nc(a, "update", r), ["update", "updatedData", "remove"].forEach(e => {
        nc(l, e, function () {
          this.chart.accessibility && (this.chart.a11yDirty = !0);
        });
      }));
    };
  }(nm || (nm = {})), np(!0, nl, {
    accessibility: {
      enabled: !0,
      screenReaderSection: {
        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{playAsSoundButton}</div><div>{viewTableButton}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div><div>{annotationsTitle}{annotationsList}</div>",
        afterChartFormat: "{endOfChartMarker}",
        axisRangeDateFormat: "%Y-%m-%d %H:%M:%S"
      },
      series: {
        descriptionFormat: "{seriesDescription}{authorDescription}{axisDescription}",
        describeSingleSeries: !1,
        pointDescriptionEnabledThreshold: 200
      },
      point: {
        valueDescriptionFormat: "{xDescription}{separator}{value}.",
        describeNull: !0
      },
      landmarkVerbosity: "all",
      linkedDescription: '*[data-highcharts-chart="{index}"] + .highcharts-description',
      highContrastMode: "auto",
      keyboardNavigation: {
        enabled: !0,
        focusBorder: {
          enabled: !0,
          hideBrowserFocusOutline: !0,
          style: {
            color: "#334eff",
            lineWidth: 2,
            borderRadius: 3
          },
          margin: 2
        },
        order: ["series", "zoom", "rangeSelector", "navigator", "legend", "chartMenu"],
        wrapAround: !0,
        seriesNavigation: {
          skipNullPoints: !0,
          pointNavigationEnabledThreshold: !1,
          rememberPointFocus: !1
        }
      },
      announceNewData: {
        enabled: !1,
        minAnnounceInterval: 5e3,
        interruptUser: !1
      }
    },
    legend: {
      accessibility: {
        enabled: !0,
        keyboardNavigation: {
          enabled: !0
        }
      }
    },
    exporting: {
      accessibility: {
        enabled: !0
      }
    },
    navigator: {
      accessibility: {
        enabled: !0
      }
    }
  }, {
    accessibility: {
      highContrastTheme: {
        chart: {
          backgroundColor: "window"
        },
        title: {
          style: {
            color: "windowText"
          }
        },
        subtitle: {
          style: {
            color: "windowText"
          }
        },
        colorAxis: {
          minColor: "windowText",
          maxColor: "windowText",
          stops: [],
          dataClasses: []
        },
        colors: ["windowText"],
        xAxis: {
          gridLineColor: "windowText",
          labels: {
            style: {
              color: "windowText"
            }
          },
          lineColor: "windowText",
          minorGridLineColor: "windowText",
          tickColor: "windowText",
          title: {
            style: {
              color: "windowText"
            }
          }
        },
        yAxis: {
          gridLineColor: "windowText",
          labels: {
            style: {
              color: "windowText"
            }
          },
          lineColor: "windowText",
          minorGridLineColor: "windowText",
          tickColor: "windowText",
          title: {
            style: {
              color: "windowText"
            }
          }
        },
        tooltip: {
          backgroundColor: "window",
          borderColor: "windowText",
          style: {
            color: "windowText"
          }
        },
        plotOptions: {
          series: {
            lineColor: "windowText",
            fillColor: "window",
            borderColor: "windowText",
            edgeColor: "windowText",
            borderWidth: 1,
            dataLabels: {
              connectorColor: "windowText",
              color: "windowText",
              style: {
                color: "windowText",
                textOutline: "none"
              }
            },
            marker: {
              lineColor: "windowText",
              fillColor: "windowText"
            }
          },
          pie: {
            color: "window",
            colors: ["window"],
            borderColor: "windowText",
            borderWidth: 1
          },
          boxplot: {
            fillColor: "window"
          },
          candlestick: {
            lineColor: "windowText",
            fillColor: "window"
          },
          errorbar: {
            fillColor: "window"
          }
        },
        legend: {
          backgroundColor: "window",
          itemStyle: {
            color: "windowText"
          },
          itemHoverStyle: {
            color: "windowText"
          },
          itemHiddenStyle: {
            color: "#555"
          },
          title: {
            style: {
              color: "windowText"
            }
          }
        },
        credits: {
          style: {
            color: "windowText"
          }
        },
        drilldown: {
          activeAxisLabelStyle: {
            color: "windowText"
          },
          activeDataLabelStyle: {
            color: "windowText"
          }
        },
        navigation: {
          buttonOptions: {
            symbolStroke: "windowText",
            theme: {
              fill: "window"
            }
          }
        },
        rangeSelector: {
          buttonTheme: {
            fill: "window",
            stroke: "windowText",
            style: {
              color: "windowText"
            },
            states: {
              hover: {
                fill: "window",
                stroke: "windowText",
                style: {
                  color: "windowText"
                }
              },
              select: {
                fill: "#444",
                stroke: "windowText",
                style: {
                  color: "windowText"
                }
              }
            }
          },
          inputBoxBorderColor: "windowText",
          inputStyle: {
            backgroundColor: "window",
            color: "windowText"
          },
          labelStyle: {
            color: "windowText"
          }
        },
        navigator: {
          handles: {
            backgroundColor: "window",
            borderColor: "windowText"
          },
          outlineColor: "windowText",
          maskFill: "transparent",
          series: {
            color: "windowText",
            lineColor: "windowText"
          },
          xAxis: {
            gridLineColor: "windowText"
          }
        },
        scrollbar: {
          barBackgroundColor: "#444",
          barBorderColor: "windowText",
          buttonArrowColor: "windowText",
          buttonBackgroundColor: "window",
          buttonBorderColor: "windowText",
          rifleColor: "windowText",
          trackBackgroundColor: "window",
          trackBorderColor: "windowText"
        }
      }
    },
    lang: {
      accessibility: {
        defaultChartTitle: "Chart",
        chartContainerLabel: "{title}. Highcharts interactive chart.",
        svgContainerLabel: "Interactive chart",
        drillUpButton: "{buttonText}",
        credits: "Chart credits: {creditsStr}",
        thousandsSep: ",",
        svgContainerTitle: "",
        graphicContainerLabel: "",
        screenReaderSection: {
          beforeRegionLabel: "",
          afterRegionLabel: "",
          annotations: {
            heading: "Chart annotations summary",
            descriptionSinglePoint: "{annotationText}. Related to {annotationPoint}",
            descriptionMultiplePoints: "{annotationText}. Related to {annotationPoint}{#each additionalAnnotationPoints}, also related to {this}{/each}",
            descriptionNoPoints: "{annotationText}"
          },
          endOfChartMarker: "End of interactive chart."
        },
        sonification: {
          playAsSoundButtonText: "Play as sound, {chartTitle}",
          playAsSoundClickAnnouncement: "Play"
        },
        legend: {
          legendLabelNoTitle: "Toggle series visibility, {chartTitle}",
          legendLabel: "Chart legend: {legendTitle}",
          legendItem: "Show {itemName}"
        },
        zoom: {
          mapZoomIn: "Zoom chart",
          mapZoomOut: "Zoom out chart",
          resetZoomButton: "Reset zoom"
        },
        rangeSelector: {
          dropdownLabel: "{rangeTitle}",
          minInputLabel: "Select start date.",
          maxInputLabel: "Select end date.",
          clickButtonAnnouncement: "Viewing {axisRangeDescription}"
        },
        navigator: {
          handleLabel: "{#eq handleIx 0}Start, percent{else}End, percent{/eq}",
          groupLabel: "Axis zoom",
          changeAnnouncement: "{axisRangeDescription}"
        },
        table: {
          viewAsDataTableButtonText: "View as data table, {chartTitle}",
          tableSummary: "Table representation of chart."
        },
        announceNewData: {
          newDataAnnounce: "Updated data for chart {chartTitle}",
          newSeriesAnnounceSingle: "New data series: {seriesDesc}",
          newPointAnnounceSingle: "New data point: {pointDesc}",
          newSeriesAnnounceMultiple: "New data series in chart {chartTitle}: {seriesDesc}",
          newPointAnnounceMultiple: "New data point in chart {chartTitle}: {pointDesc}"
        },
        seriesTypeDescriptions: {
          boxplot: "Box plot charts are typically used to display groups of statistical data. Each data point in the chart can have up to 5 values: minimum, lower quartile, median, upper quartile, and maximum.",
          arearange: "Arearange charts are line charts displaying a range between a lower and higher value for each point.",
          areasplinerange: "These charts are line charts displaying a range between a lower and higher value for each point.",
          bubble: "Bubble charts are scatter charts where each data point also has a size value.",
          columnrange: "Columnrange charts are column charts displaying a range between a lower and higher value for each point.",
          errorbar: "Errorbar series are used to display the variability of the data.",
          funnel: "Funnel charts are used to display reduction of data in stages.",
          pyramid: "Pyramid charts consist of a single pyramid with item heights corresponding to each point value.",
          waterfall: "A waterfall chart is a column chart where each column contributes towards a total end value."
        },
        chartTypes: {
          emptyChart: "Empty chart",
          mapTypeDescription: "Map of {mapTitle} with {numSeries} data series.",
          unknownMap: "Map of unspecified region with {numSeries} data series.",
          combinationChart: "Combination chart with {numSeries} data series.",
          defaultSingle: "Chart with {numPoints} data {#eq numPoints 1}point{else}points{/eq}.",
          defaultMultiple: "Chart with {numSeries} data series.",
          splineSingle: "Line chart with {numPoints} data {#eq numPoints 1}point{else}points{/eq}.",
          splineMultiple: "Line chart with {numSeries} lines.",
          lineSingle: "Line chart with {numPoints} data {#eq numPoints 1}point{else}points{/eq}.",
          lineMultiple: "Line chart with {numSeries} lines.",
          columnSingle: "Bar chart with {numPoints} {#eq numPoints 1}bar{else}bars{/eq}.",
          columnMultiple: "Bar chart with {numSeries} data series.",
          barSingle: "Bar chart with {numPoints} {#eq numPoints 1}bar{else}bars{/eq}.",
          barMultiple: "Bar chart with {numSeries} data series.",
          pieSingle: "Pie chart with {numPoints} {#eq numPoints 1}slice{else}slices{/eq}.",
          pieMultiple: "Pie chart with {numSeries} pies.",
          scatterSingle: "Scatter chart with {numPoints} {#eq numPoints 1}point{else}points{/eq}.",
          scatterMultiple: "Scatter chart with {numSeries} data series.",
          boxplotSingle: "Boxplot with {numPoints} {#eq numPoints 1}box{else}boxes{/eq}.",
          boxplotMultiple: "Boxplot with {numSeries} data series.",
          bubbleSingle: "Bubble chart with {numPoints} {#eq numPoints 1}bubbles{else}bubble{/eq}.",
          bubbleMultiple: "Bubble chart with {numSeries} data series."
        },
        axis: {
          xAxisDescriptionSingular: "The chart has 1 X axis displaying {names[0]}. {ranges[0]}",
          xAxisDescriptionPlural: "The chart has {numAxes} X axes displaying {#each names}{#unless @first},{/unless}{#if @last} and{/if} {this}{/each}.",
          yAxisDescriptionSingular: "The chart has 1 Y axis displaying {names[0]}. {ranges[0]}",
          yAxisDescriptionPlural: "The chart has {numAxes} Y axes displaying {#each names}{#unless @first},{/unless}{#if @last} and{/if} {this}{/each}.",
          timeRangeDays: "Data range: {range} days.",
          timeRangeHours: "Data range: {range} hours.",
          timeRangeMinutes: "Data range: {range} minutes.",
          timeRangeSeconds: "Data range: {range} seconds.",
          rangeFromTo: "Data ranges from {rangeFrom} to {rangeTo}.",
          rangeCategories: "Data range: {numCategories} categories."
        },
        exporting: {
          chartMenuLabel: "Chart menu",
          menuButtonLabel: "View chart menu, {chartTitle}"
        },
        series: {
          summary: {
            default: "{series.name}, series {seriesNumber} of {chart.series.length} with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            defaultCombination: "{series.name}, series {seriesNumber} of {chart.series.length} with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            line: "{series.name}, line {seriesNumber} of {chart.series.length} with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            lineCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Line with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            spline: "{series.name}, line {seriesNumber} of {chart.series.length} with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            splineCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Line with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            column: "{series.name}, bar series {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}bar{else}bars{/eq}.",
            columnCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Bar series with {series.points.length} {#eq series.points.length 1}bar{else}bars{/eq}.",
            bar: "{series.name}, bar series {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}bar{else}bars{/eq}.",
            barCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Bar series with {series.points.length} {#eq series.points.length 1}bar{else}bars{/eq}.",
            pie: "{series.name}, pie {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}slice{else}slices{/eq}.",
            pieCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Pie with {series.points.length} {#eq series.points.length 1}slice{else}slices{/eq}.",
            scatter: "{series.name}, scatter plot {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}point{else}points{/eq}.",
            scatterCombination: "{series.name}, series {seriesNumber} of {chart.series.length}, scatter plot with {series.points.length} {#eq series.points.length 1}point{else}points{/eq}.",
            boxplot: "{series.name}, boxplot {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}box{else}boxes{/eq}.",
            boxplotCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Boxplot with {series.points.length} {#eq series.points.length 1}box{else}boxes{/eq}.",
            bubble: "{series.name}, bubble series {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}bubble{else}bubbles{/eq}.",
            bubbleCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Bubble series with {series.points.length} {#eq series.points.length 1}bubble{else}bubbles{/eq}.",
            map: "{series.name}, map {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}area{else}areas{/eq}.",
            mapCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Map with {series.points.length} {#eq series.points.length 1}area{else}areas{/eq}.",
            mapline: "{series.name}, line {seriesNumber} of {chart.series.length} with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            maplineCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Line with {series.points.length} data {#eq series.points.length 1}point{else}points{/eq}.",
            mapbubble: "{series.name}, bubble series {seriesNumber} of {chart.series.length} with {series.points.length} {#eq series.points.length 1}bubble{else}bubbles{/eq}.",
            mapbubbleCombination: "{series.name}, series {seriesNumber} of {chart.series.length}. Bubble series with {series.points.length} {#eq series.points.length 1}bubble{else}bubbles{/eq}."
          },
          description: "{description}",
          xAxisDescription: "X axis, {name}",
          yAxisDescription: "Y axis, {name}",
          nullPointValue: "No value",
          pointAnnotationsDescription: "{#each annotations}Annotation: {this}{/each}"
        }
      }
    }
  });
  let nb = nm,
    nf = w();
  nf.i18nFormat = nb.i18nFormat, nf.A11yChartUtilities = K, nf.A11yHTMLUtilities = S, nf.AccessibilityComponent = Z, nf.KeyboardNavigationHandler = Q, nf.SeriesAccessibilityDescriber = i8, nb.compose(nf.Chart, nf.Legend, nf.Point, nf.Series, nf.SVGElement, nf.RangeSelector);
  let nx = w();
  return y.default;
})());