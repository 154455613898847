import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  userProfile: any;
  userInitials: string = '';
  isEditing: boolean = false;
  editForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.editForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.pattern(/^\+?[1-9]\d{1,14}$/)],
      password: ['', Validators.minLength(8)]
    });
  }

  ngOnInit() {
    // In a real application, you would get this data from a service
    this.userProfile = {
      name: 'Abdelhak SIFI',
      email: 'abdelhak.sifi@corepulse.fr',
      given_name: 'Abdelhak',
      family_name: 'SIFI',
      preferred_username: 'abdelhak',
      email_verified: true,
      phone: '+1234567890',
      roles: [
        'ADMIN',
        'default-roles-orkestria',
        'offline_access',
        'uma_authorization'
      ]
    };

    this.userInitials = this.getInitials(this.userProfile.name);
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editForm.patchValue({
        name: this.userProfile.name,
        phone: this.userProfile.phone || '',
        password: ''
      });
    } else {
      this.editForm.reset();
    }
  }

  onSubmit() {
    if (this.editForm.valid && !this.editForm.pristine) {
      // In a real application, you would call a service to update the user profile
      const formValue = this.editForm.value;

      // Update only changed values
      this.userProfile = {
        ...this.userProfile,
        name: formValue.name,
        phone: formValue.phone
      };

      // Handle password change if provided
      if (formValue.password) {
        console.log('Password change requested');
        // Implement password change logic
      }

      this.userInitials = this.getInitials(this.userProfile.name);
      this.toggleEdit();
    }
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase();
  }
}
