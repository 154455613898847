import {  Component,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ZonesService } from '../../services/zone/zones.service';
import { AddzoneComponent } from '../../components/add-zone/add-zone.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-zones',
  standalone: true,
  imports: [RouterLink,MatIcon, CommonModule,MatButtonModule,FilterDataPipe,FormsModule],
  templateUrl: './zones.component.html',
  styleUrl: './zones.component.scss'
})
export class ZonesComponent {

  projectName:string | null = "";
  projectId: any;
  breakpoint:number = 4;
  IsDarkMode:boolean = false;
  zones: any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);
  searchText  = '';

  constructor(private readonly darkMode:ThemeService,private readonly zonesService:ZonesService,private readonly _snackBar: MatSnackBar,private readonly route: ActivatedRoute){

  }

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  ngOnInit() {

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('project_id');
      this.projectName = params.get('project_name');

      setTimeout(() => {
        this.getZones();
      }, 500);

    });

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;


  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getZones() {

    this.zonesService.getAllZones(this.projectId).subscribe(
      (response) => {

        if (response) {
          this.zones = response;
        }else{
          this.zones = [];
        }
      }
    );
  }

  openCreateDialog(): void {
     this.dialog.open(AddzoneComponent, {
      width: '75vw',
      data : {projectId:this.projectId},
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getZones();
    });
  }

  openUpdateDialog(zone:any): void {

    this.dialog.open(AddzoneComponent, {
     width: '75vw',
     data:zone,
     panelClass: this.IsDarkMode ? 'dark' : ''
   }).afterClosed().subscribe((result) => {
       this.getZones();
   });
 }


  openDeleteDialog(zone_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      enterAnimationDuration,
      exitAnimationDuration,
      data: { zone_id: zone_id,
        title: "Delete Zone",
        message: "Are you sure you want to delete this zone?",
       },
      panelClass: this.IsDarkMode ? 'dark responsive-dialog' : 'responsive-dialog'
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.deleteZone(zone_id);
      }

    });

  }
  deleteZone(zone_id:number){
    this.zonesService.deleteZone(zone_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.getZones();
        }
      }
    );
  }

}

