<div class=" inline-flex m-auto mt-3 items-center ">
  <span> <mat-icon class="    mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16 ">add_a_photo</mat-icon></span>
  <h1   *ngIf="iscreate "  class="font-bold  text-2xl  text-primary-500 dark:text-white ">New Camera</h1>
  <h1   *ngIf="!iscreate "  class="font-bold  text-2xl   text-primary-500 dark:text-white ">Update Camera {{form.get('name')?.value}}</h1>
</div>

<div mat-dialog-content >
  <form [formGroup]="form">

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full" *ngIf="iscreate && !hasZone">
      <mat-label>Site</mat-label>
      <mat-select  (selectionChange)="getZones($event)">
        <mat-option  [value]="project.id" *ngFor="let project of projects">{{project.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full" *ngIf="iscreate && !hasZone">
      <mat-label>Zone</mat-label>
      <mat-select formControlName="zoneId" [disabled]="true">
        <mat-option  [value]="zone.id" *ngFor="let zone of zones">{{zone.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>URL</mat-label>
        <input matInput formControlName="thumbnailUrl">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>LAT</mat-label>
        <input matInput formControlName="location_lat">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>LNG</mat-label>
        <input matInput formControlName="location_lng">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>Model</mat-label>
        <input matInput formControlName="model">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option  [value]="item" *ngFor="let item of cameraStatus">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Ex. cover 10 meters" formControlName="description"></textarea>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>
