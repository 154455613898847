import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup,ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { AuthService,  } from '../../services/auth/auth-service.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLine } from '@angular/material/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatCard,MatCardTitle,MatCardContent, CommonModule,MatGridList,MatGridTile,RouterLink,MatLine,ReactiveFormsModule,MatFormField,MatLabel,MatError,MatInput,MatButton,MatProgressSpinnerModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers:[]
})
export class LoginComponent {

  LoginFrom: FormGroup;
  submitted:boolean= false;

  constructor(private router:Router,private _snackBar: MatSnackBar,private formBuilder: FormBuilder,private authService:AuthService){
    this.LoginFrom = this.formBuilder.group({
      username: ['',Validators.required],
      password: ['',Validators.required]
    });
  }

  login(){

    if (!this.LoginFrom.valid) {
      this.openSnackBar('You have to provide username/e-mail and password','close')
    }
    else{
      this.submitted = true;

      this.authService.login(this.LoginFrom.value).subscribe({
        next: (_response) => {

          if (_response.access_token) {

            this.submitted = false;
            localStorage.setItem('CamAppToken',_response.access_token)
            this.authService.Authenticated();
            this.router.navigate(['/'])
          }else{
            this.submitted = false;
            this.openSnackBar('There was an error','close')
          }

        },
        error: (error) => {
          this.submitted = false;
          this.openSnackBar('There was an error !','close')
        }
      })
    }


  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


}
