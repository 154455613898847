import { ApplicationModule, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';
import { MatBadge, MatBadgeModule } from '@angular/material/badge';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { AlertsService } from './services/alerts/alerts.service';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { ZonesService } from './services/zone/zones.service';
import { ProjectService } from './services/project/project.service';
import { AuthService } from './services/auth/auth-service.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SidemenuService } from './services/sidemenu/sidemenu.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { SidenavComponent } from './shared/components/side-nav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ThemeService } from './services/theme.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, MatSidenavModule, SidenavComponent, ApplicationModule, CommonModule, MatIconModule, MatMiniFabButton, MatMenu, MatMenuTrigger, MatIconButton, MatMenuItem, MatDivider, MatBadgeModule, MatBadge],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'camapp_w';
  isConnected = false;
  notificationCount = 0;
  active = true;
  isDarkMode$: Observable<boolean>;
  sidenavMode: 'side' | 'over' = 'side';
  private alertsSubscription: Subscription | null = null;

  constructor(private sideMenuservice: SidemenuService, private responsive: BreakpointObserver, private authService: AuthService,
    private themeService: ThemeService, private zoneService: ZonesService, private projectService: ProjectService, private dialog: MatDialog, private alertsService: AlertsService,private router: Router) {
    this.isDarkMode$ = this.themeService.isDarkMode$;
  }


  ngOnInit(): void {


    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.themeService.setDarkMode(true);
    }

    this.responsive.observe([
      Breakpoints.HandsetPortrait
    ])
      .subscribe(result => {

        if (result.matches) {
          this.sidenavMode = result.matches ? 'over' : 'side';

        }
      });

      this.authService.isAuthenticated$.subscribe(
        (isAuthenticated: boolean) => {
          this.isConnected = isAuthenticated;
          if (this.isConnected) {
            this.checkForAlert();
          } else if (this.alertsSubscription) {
            this.alertsSubscription.unsubscribe();
          }
        }
      );

  }


  checkForAlert() {
    let dialogRef: any = this.dialog.getDialogById('alertsDialog');

    setTimeout(() => {
      this.alertsSubscription = this.alertsService.checkForAlerts().subscribe(
        (notifications: any) => {
          if (notifications && notifications.length) {
            // Check if the dialog is already open
            if (!dialogRef) {
              dialogRef = this.dialog.open(AlertsDialogComponent, {
                id: 'alertsDialog', // Assign a unique id to the dialog
                data: notifications
              });

              // When the dialog is closed, reset dialogRef to null
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
              });
            }
          }
        },
        (error) => {
          if (error.status === 401) {
            localStorage.clear();
            this.router.navigate(['/login']);
            if (this.alertsSubscription) {
              this.alertsSubscription.unsubscribe();
            }
          }
        }
      );
    }, 500);
  }

}

@Component({
  selector: 'alerts-dialog',
  templateUrl: './shared/templates/alerts-dialog.html',
  styleUrl: './shared/templates/style/alerts-dialog.scss',
  standalone: true,
  imports: [MatCardModule, MatListModule, MatButtonModule, MatIconModule, CommonModule, MatButtonModule, MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) { }

  close(): void {
    this.dialogRef.close();
  }

  opencamera(url: any) {
    window.open(url, '_blank');
  }

}
