import { Component, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { displayedColumns } from './table-config';
import { RedmineService } from '../../services/redmine-tickets/redmine-ticket.service';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';


@Component({
  selector: 'app-redmine-tickets',
  standalone: true,
  imports: [
    MatIconModule, CommonModule,
     MatButtonModule,MatMenuModule,SharedTableComponent,FormsModule,FilterDataPipe],
  templateUrl: './redmine-tickets.component.html',
  styleUrl: './redmine-tickets.component.scss'
})
export class RedmineTicketsComponent {
  searchText: string = '';
  dataSource :any
  displayedColumns=displayedColumns;
  totalItems = 0;
  loadingTable = signal(false);

  constructor(private readonly redmineService:RedmineService){

  }
  ngOnInit() {

    this.loadingTable.set(true);
    this.getRecordings();

  }

  getRecordings() {

    this.redmineService.getAllRedmineTickets().subscribe(
      (response) => {
        this.dataSource = response.map((item:any) => {item.url='https://redmine.corepulse.fr/issues/'+item.id; return item});
        this.totalItems = response.length;
        this.loadingTable.set(false);
      }
    );
  }



  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

