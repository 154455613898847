import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginUrl= "/token";

  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  public isAuthenticated$: Observable<boolean>;
  private readonly AUTH_KEY = 'CamAppToken';

  constructor(private http: HttpClient) {

      const isAuthenticated = (localStorage.getItem(this.AUTH_KEY))?true:false ;

      // Initialize the BehaviorSubject with the initial authentication state
      this.isAuthenticatedSubject = new BehaviorSubject<boolean>(isAuthenticated);
      // Expose the BehaviorSubject as an Observable
      this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  }

  setAuthenticationState(isAuthenticated: boolean): void {
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  login(data: any): Observable<any> {
    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('client_id', 'micro-project');
  
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body = body.set(key, data[key]);
      }
    }
  
    return this.http.post<any>(`${environment.ApiAuthUrl + this.loginUrl}`, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  logout() :void{
    localStorage.clear();
    this.setAuthenticationState(false);
  }

  Authenticated(): void {
    this.setAuthenticationState(true);
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  Disconnected(): void {

    localStorage.clear();
    this.setAuthenticationState(false);

  }

}
