
<div class="mt-4">

  <div class=" mb-3">
    <div class="flex items-center  px-4 py-2   font-bold mb-5 align-middle m-auto">
      <mat-icon class="mat-18 text-5xl w-10 h-8 text-primary-500 dark:text-white" svgIcon="project_svg"></mat-icon>
      <p class="ml-3 text-2xl text-primary-500 dark:text-white mt-2"> Tickets List</p>
    </div>

  </div>

  <div class="flex flex-col sm:flex-row justify-between items-center mb-6 mt-10 space-y-4 sm:space-y-0 sm:space-x-4">

    <div class="flex flex-row gap-2 items-center sm:w-2/3 w-full">

      <!-- Search Bar -->
      <div class="flex items-center border rounded bg-white pl-2 sm:w-2/3 w-full">
        <input
          type="text"
          placeholder="Search for a ticket"
          class="focus:outline-none text-sm flex-1"
          [(ngModel)]="searchText"
        />
        <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10">
          <mat-icon class="text-white m-auto align-middle">search</mat-icon>
        </button>
      </div>
      <!-- <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10">
        <mat-icon class="text-white m-auto align-middle">filter_alt</mat-icon>
      </button> -->
    </div>


  </div>

  <div class="overflow-x-auto">
    <div class="flex flex-col items-center h-fit w-full relative" >
      <app-shared-table class="w-full" [tableData]="dataSource| filterData: searchText:'subject'" [tableColumns]="displayedColumns" [totalItems]="totalItems"
        (sortEvent)="changedSort($event)" (actionRowEvent)="rowSelect($event)" (pageChangedEvent)="changedPage($event)"
        (filtersChangedEvent)="onChangeFilter($event)" (actionEmittedEvent)="actionOnTable($event)">
      </app-shared-table>
    </div>
    @if(!(totalItems > 0)){
      @if(loadingTable()){
        <div *ngFor="let _ of [].constructor(10)" class="flex space-x-4 py-1">
          <div class="h-8 w-full bg-gray-400 rounded animate-pulse"></div>
        </div>
        }
    }
  </div>
</div>
