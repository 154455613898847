import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, effect, inject, signal } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddCameraComponent } from '../../../components/add-camera/add-camera.component';
import { AuthService } from '../../../services/auth/auth-service.service';

interface NavItem {
  icon?: string;
  label: string;
  route?: string;
  fctn?: any;
  addedClass?: string;
  children?: NavItem[];
  collapsed?: boolean;

}

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [CommonModule, MatToolbarModule,RouterModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'


})
export class SidenavComponent {
  isMenuOpen = false;
  @Input() dataMenu = [];

  readonly dialog = inject(MatDialog);

  darkMode = signal<boolean>(
    JSON.parse(window.localStorage.getItem('darkMode') ?? 'false')
  );

  @HostBinding('class.dark') get mode() {
    return this.darkMode();
  }

  constructor(private authService:AuthService,private router:Router) {
    effect(() => {
      window.localStorage.setItem('darkMode', JSON.stringify(this.darkMode()));
    });
  }


  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navItems: NavItem[] = [
    { icon: 'grid_view', label: 'Dashboard', route: '/dashboard' },
    { icon: 'grain', label: 'Sites', route: '/sites' },
    { icon: 'add_a_photo', label: 'New Camera', route: '' ,fctn :'openCreateCameraDialog' },
    { icon: 'subscriptions', label: 'Recording', route: '/recordings'},
    { icon: 'camera_enhance', label: 'Cameras KPI', route: '/cameras-kpi'},
    { icon: 'notifications_on', label: 'Alerts', route: '/alerts' },
    { icon: 'business', label: 'Redmine', route: '/redmine-tickets' },
    { icon: 'settings', label: 'Settings', route: '/dashboard',children: [
      { icon: 'manage_accounts', label: 'Profile', route: '/profile' },
      { icon: 'broadcast_on_home', label: 'Sensor Types', route: '/sensortypes' },


    ]},
    // { icon: 'mail', label: 'Email', route: '/email' },
    // { icon: 'business', label: 'Company', route: '/company' },
    { icon: 'power_settings_new', label: 'Logout', route: '',fctn :'logout', addedClass: 'mt-16' },
  ];

  openCreateCameraDialog(){
    this.dialog.open(AddCameraComponent, {
      width: '50vw',
    })

  }
  onNavItemClicked(item: NavItem) {
    if (item.children) {
      this.toggleCollapse(item);
    } else{
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.fctn && typeof this[item.fctn as keyof this] === 'function') {
      // Dynamically call the function if it exists
      (this[item.fctn as keyof this] as Function)();
    } else {
      console.error('Function not found or not a function:', item.fctn);
    }
    }
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login'])
  }

  toggleCollapse(item: NavItem) {
    item.collapsed = !item.collapsed;
  }

}
