<div class="py-4">
  <div class="px-4 mb-6">
    <img src="assets/images/logo_sidebar.svg" alt="Logo" class="h-32  m-auto mt-3">
  </div>
  <mat-nav-list>
    <ng-container *ngFor="let item of navItems">
      <a mat-list-item
         (click)="onNavItemClicked(item)"
         [routerLinkActive]="item.route ? 'bg-primary-300' : ''"
         class="text-gray-600 hover:bg-primary-300 mt-1"
         [ngClass]="item.addedClass ?? ''">
        <mat-icon *ngIf="item.icon" matListItemIcon class="mat-18 text-white">{{ item.icon }}</mat-icon>
        <span matListItemTitle class="text-white">{{ item.label }}</span>
        <mat-icon *ngIf="item.children" (click)="toggleCollapse(item)">
          {{ item.collapsed ? 'expand_more' : 'expand_less' }}
        </mat-icon>
      </a>
      <div *ngIf="item.children && !item.collapsed" class="ml-4">
        <a mat-list-item
           *ngFor="let child of item.children"
           (click)="onNavItemClicked(child)"
           [routerLinkActive]="child.route ? 'bg-primary-300' : ''"
           class="text-gray-600 hover:bg-primary-300 mt-1"
           [ngClass]="child.addedClass ?? ''">
          <mat-icon matListItemIcon class="mat-18 text-white">{{ child.icon }}</mat-icon>
          <span matListItemTitle class="text-white">{{ child.label }}</span>
        </a>
      </div>
    </ng-container>
  </mat-nav-list>
</div>

